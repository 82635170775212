import React from 'react';
import { Button, Form, Input } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

export default function PasswordForm({ login }) {
  return (
    <Form className="login-form" onFinish={login}>
      <Form.Item name="username">
        <Input
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="账号"
        />
      </Form.Item>
      <Form.Item name="password">
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="密码"
        />
      </Form.Item>
      <Form.Item style={{ textAlign: 'center' }}>
        <Button htmlType="submit" className="login-btn">
          登 录
        </Button>
      </Form.Item>
    </Form>
  );
}
