import React from 'react';
import {Col, Row, Result,} from "antd";

function Case() {

    return (
        <Row>
            <Col span={24}>
                <Result
                    status="403"
                    title="页面开发中......"
                    subTitle="Sorry, you are not authorized to access this page."
                    style={{marginTop:40}}
                />
            </Col>
        </Row>
    );
}

export default Case;
