import axios from 'axios';
import { message } from 'antd';
import { createBrowserHistory } from 'history'; // 如果是history路由
const codeMessage = {
    200: '服务器成功返回请求的数据。',
    201: '新建或修改数据成功。',
    202: '一个请求已经进入后台排队（异步任务）。',
    204: '删除数据成功。',
    400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
    401: '用户没有权限（令牌、用户名、密码错误）。',
    403: '用户得到授权，但是访问是被禁止的。',
    404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
    406: '请求的格式不可得。',
    410: '请求的资源被永久删除，且不会再得到的。',
    422: '当创建一个对象时，发生一个验证错误。',
    500: '服务器发生错误，请检查服务器。',
    502: '网关错误。',
    503: '服务不可用，服务器暂时过载或维护。',
    504: '网关超时。',
};
export const request = axios.create({
    timeout: 3500,
    headers: {
        Accept: 'application/json',
    }

});
//请求拦截器
request.interceptors.request.use(function(config) {

    let {headers}=config;
    config.headers.Authorization=localStorage.getItem('Authorization');
    return config
})
// 添加响应拦截器
request.interceptors.response.use(function (response) {
    // let {msg,data,code}=response.data
    return response.data

}, function (error) {

    if (localStorage.getItem('Authorization') != "null") {
        if(true){
            consoleError(error)
        }

        if(error.request){
            const {status}=error.request;
            let errortext=codeMessage[status] ;
            if(status){
                //token失效的跳转
                if(status==500&&error.response){
                    console.log("error---",error.response.data);
                    if(error.response.data.message.indexOf("invalid_token")>0){
                        const history = createBrowserHistory();
                        window.location.href="/login";
                        errortext="身份认证失效，请重新登录"
                    }
                }else {
                    errortext = codeMessage[status] ;
                }
            }
            message.info("请求失败:"+errortext);
            return Promise.reject("请求失败:"+errortext)
        }
    }
    // 对响应错误做点什么
    return Promise.reject(error);
});
//打印错误信息
function consoleError(error) {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
    }
    console.log(error.config);
}

/**
 * //对象转formdata格式
 * @param params
 * @returns {FormData}
 */
export function jsToFormData(params) {
    const formData = new FormData();
    Object.keys(params).forEach(key => {
        formData.append(key, params[key]);
    });
    return formData;
}

export default request;
