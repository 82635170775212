import React, {useState} from 'react';
import {
    CalendarOutlined,
    MessageOutlined,
    MailOutlined,
    SettingOutlined,
} from '@ant-design/icons';
import {
    ManOutlined,
    PhoneOutlined,
    PoweroffOutlined,
    UserOutlined,
    WomanOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { Popover, Input, Calendar, Avatar, Card, Row, Col, List,message,Drawer,Form,Button,Modal, Tooltip } from 'antd';
import portrait1 from '../../assets/material/portrait1.png';
import portrait0 from '../../assets/material/portrait0.png';
import api from '../../services/api';
import './index.css';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import { setAuthority } from '../../utils/authority';

const { confirm } = Modal;

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('您只能上传JPG/PNG文件!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('图像必须小于2MB!');
    }
    return isJpgOrPng && isLt2M;
}

export function GlobalHeader({ showDrawer }) {
    const history = useHistory();
    //获取当前星期，日期和时间
    const today = new Date();
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const weekDay = ['星期日','星期一','星期二','星期三','星期四','星期五','星期六'];
    const week = weekDay[today.getDay()];
    const account = sessionStorage.getItem('account') != 'null' ? sessionStorage.getItem('account') : ''; // 登录账号
    const username = sessionStorage.getItem('username') != 'null' ? sessionStorage.getItem('username') : ''; // 账号姓名
    const sex = sessionStorage.getItem('sex') != 'null' ? sessionStorage.getItem('sex') : ''; // 性别
    const phone = sessionStorage.getItem('phone') != 'null' ? sessionStorage.getItem('phone') : ''; // 电话
    const headimgurl = sessionStorage.getItem('headimgurl') !== 'null' ? sessionStorage.getItem('headimgurl') : null; // 获取头像

    const [visible, setVisible] = useState(false);

    //退出登录
    const logins = () => {
        confirm({
            title: '您确定要退出登录吗？',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                history.replace('/login');
                setAuthority('');
            },
            onCancel() {
                console.log('onCancel');
            },
        });
    };

    const getData = () => [
        {
            title:"基本设置",
            description:"更新基本信息",
            actions: [
                <a>修改</a>,
            ],
        },
        {
            title:"账户密码",
            description:"安全设置",
            actions: [
                <a onClick={showDrawers}>修改</a>,
            ],
        },
    ];

    const showDrawers = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    const checkPassword = (rule, value, callback) => {
        if (value.length < 6) {
            callback("请至少输入 6 个字符");
        } else {
            callback();
        }
    };

    const checkPhone = (rule, value, callback) => {
        const form = this.props.form;
        var regex = /^[1][3,4,5,7,8][0-9]{9}$/;
        if (value) {
            //react使用正则表达式变量的test方法进行校验，直接使用value.match(regex)显示match未定义
            if (regex.test(value)) {
                callback();
            } else {
                callback('请输入正确的手机号码！');
            }
        } else {
            //这里的callback函数会报错
        }
    };

    //修改密码
    const editPassword = async values => {
        confirm({
            title: '您确定要修改密码吗？',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                modifyPassword(values.oldPassword,values.newPassword);
            },
            onCancel() {
                onClose();
            },
        });
    };

    // 修改密码
    const modifyPassword = async (oldPassword, newPassword) => {
        try {
            const res = await api.ResetPassword({
                oldPassword: oldPassword,
                newPassword: newPassword,
            });
            if (res != null) {
                message.success('操作成功');
                onClose();
            }
        } catch (error) {
            message.error('操作失败请重试！');
            onClose();
            return false;
        }
    };

    const calendar = (
        <Calendar locale={locale} fullscreen={false} style={{width:'300px',border:'1px solid #f0f0f0',borderRadius:'2px'}}/>
    );

    const content = (
        <div>
            <Card bordered={false} style={{width:'300px',textAlign: 'center'}}>
                <Row>
                    <Col span={24}>
                        {headimgurl !== null && <Avatar className="icon" size={120} src={headimgurl} />}
                        {sex === "1" && headimgurl === null && (
                            <Avatar className="icon" size={120} src={portrait1} />
                        )}
                        {sex === "2" && headimgurl === null && (
                            <Avatar className="icon" size={120} src={portrait0} />
                        )}
                    </Col>
                    <Col span={24}>
                        <p style={{ fontSize:'25px',textAlign:'center'}}>{username}</p>
                    </Col>
                    <Col>
                        <Col span={24}>
                            <p style={{fontSize:'15px',textAlign: 'left'}}>
                                <UserOutlined />
                                &nbsp;<span>账&nbsp;&nbsp;&nbsp;&nbsp;号&nbsp;&nbsp;:</span>&nbsp;{account}
                            </p>
                        </Col>
                        <Col span={24}>
                            {sex === "1" && (
                                <p style={{fontSize:'15px',textAlign: 'left'}}>
                                    <ManOutlined />
                                    &nbsp;<span>性&nbsp;&nbsp;&nbsp;&nbsp;别&nbsp;&nbsp;:</span>&nbsp;男
                                </p>
                            )}
                            {sex === "2" && (
                                <p style={{fontSize:'13px',textAlign: 'left'}}>
                                    <WomanOutlined />
                                    &nbsp;<span>性&nbsp;&nbsp;&nbsp;&nbsp;别&nbsp;&nbsp;:</span>&nbsp;女
                                </p>
                            )}
                        </Col>
                        <Col span={24}>
                            <p style={{fontSize:'13px',textAlign: 'left'}}>
                                <PhoneOutlined />
                                &nbsp;<span>手&nbsp;&nbsp;&nbsp;&nbsp;机&nbsp;&nbsp;:</span>&nbsp;{phone}
                            </p>
                        </Col>
                    </Col>
                </Row>
            </Card>
            <div>&nbsp;</div>
            <List
                itemLayout="horizontal"
                dataSource={getData()}
                renderItem={item => (
                    <List.Item actions={item.actions}>
                        <List.Item.Meta title={item.title} description={item.description} />
                    </List.Item>
                )}
            />

            <Drawer
                title="账户密码"
                placement="right"
                onClose={onClose}
                visible={visible}
                width={'100%'}
                closable={true}
                getContainer={false}
                destroyOnClose
                style={{ position: 'absolute' }}
            >
                <Form name="nest-messages" onFinish={editPassword} scrollToFirstError={true}>
                    <Form.Item label="旧密码" name="oldPassword" rules={[{ required: true, message: '请输入旧密码'}]}>
                        <Input.Password placeholder="请输入旧密码" />
                    </Form.Item>
                    <Form.Item label="新密码" name="newPassword" rules={[{ required: true, message: '请输入新密码'},{ validator: checkPassword }]}>
                        <Input.Password placeholder="至少6位密码，区分大小写" />
                    </Form.Item>
                    <hr/>
                    <Form.Item>
                        <Row>
                            <Col span={8} offset={6}>
                                <Button type="primary" htmlType="submit">
                                    提交
                                </Button>
                            </Col>
                            <Col span={2}>
                                <Button onClick={()=>onClose()}>
                                    取消
                                </Button>
                            </Col>
                        </Row>
                    </Form.Item>
                </Form>
            </Drawer>
        </div>
    );

    const usernames = sessionStorage.getItem('username') != 'null' ? sessionStorage.getItem('username') : ''; // 账号姓名

    return (
        <div className="nav">
            <div className="left">
                <span>你好，{usernames}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <Popover content={calendar} trigger="click">
                    <CalendarOutlined className="icon"/>
                    <span className="date" style={{cursor:'pointer'}}>{week} / {date}</span>
                    <a style={{color:"#999999"}}>查看更多日期</a>
                </Popover>
            </div>
            <div className="right">
                <Tooltip title="通知"><MessageOutlined className="icon"/></Tooltip>
                <Tooltip title="消息"><MailOutlined className="icon"/></Tooltip>
                <Popover destroyTooltipOnHide content={content} trigger="click">
                    <Tooltip title="设置"><SettingOutlined className="icon"/></Tooltip>
                </Popover>
                <Tooltip title="退出登录"><PoweroffOutlined className="icon" onClick={() => logins()}/></Tooltip>
            </div>
        </div>
    )
}
