import React from 'react';
import './index.less';
import {Col, Row, Menu} from "antd";
import { useHistory } from 'react-router-dom';
import background from "../../assets/material/background.png";
import map1 from "../../assets/material/Professionals/map1.png";
import map2 from "../../assets/material/Professionals/map2.png";

function Detection() {
    const history = useHistory();

    //菜单栏点击
    const itemClick = (e) => {
        let k = parseInt(e.key);
        switch (k) {
            case 1://涉电检测
                history.replace('/detection/electricity');
                break;
            case 2://低碳服务
                history.replace('/detection/carbon-service');
                break;
            case 3://碳纤维
                history.replace('/detection/carbons');
                break;
            case 4://新能源入网
                history.replace('/detection/energy');
                break;
            case 5://建筑结构
                history.replace('/detection/build');
                break;
            case 6://职业健康
                history.replace('/detection/healthy');
                break;
            default:
                history.replace('/detection/electricity');
                break;
        }
    };

    return (
        <Row>
            <Col span={24}>
                <img src={background} alt={'background'} style={{width:'100%'}} />
            </Col>
            <Col span={24}>
                <Menu style={styles.menu} onClick={itemClick}>
                    <Menu.Item style={styles.menuItem} key={1}>涉电检测</Menu.Item>
                    <Menu.Item style={styles.menuItem} key={2}>低碳服务</Menu.Item>
                    <Menu.Item style={styles.menuItem} key={3}>碳纤维</Menu.Item>
                    <Menu.Item style={styles.menuItem} key={4}>新能源入网</Menu.Item>
                    <Menu.Item style={styles.menuItem} key={5}>建筑结构</Menu.Item>
                    <Menu.Item style={styles.menuItem} key={6}>职业健康</Menu.Item>
                </Menu>
            </Col>

            <Col span={18} offset={3}>
                <p style={{
                    marginTop:149,
                    fontSize: 21,
                    fontDamily: 'Microsoft YaHei',
                    fontWeight: 400,
                    color: '#333333'
                }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;以大学为依托，由吉林东杰科技开发有限公司与广州恒力检测股份有限公司共同出资组建与广州恒力检测股份有限公司共同出资组建，是综合性第三方安全检测及配、售电一体化技术服务机构。东研检测专注于企业的用电安全及能效管理。业务涵盖电力高低压设备检测及维护、设备托管及智能运维、电力故障抢修、配电房安装改造、能效管理、智能配电房监测系统、电力安全工器具及劳保用品预防性试验、环境检测、职业健康检测、仪器仪表校准、售电等业务。
                    <br/><br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;国内职业健康检测领域提供专业化、标准化服务。
                </p>
            </Col>

            <Col span={22} offset={2} style={{marginTop:63}}>
                <p style={{marginLeft:'75px', fontSize: '42px', fontFamily: 'FZXiaoBiaoSong-B05S', fontWeight: '400', color: '#00529C'}}>
                    成果案例
                </p>
                <p style={{marginTop:-25,marginLeft:'77px', fontSize: '23px', fontFamily: 'Adobe Heiti Std', fontWeight: 'normal', color: '##292929'}}>
                    Results cases
                </p>
                <div style={{marginLeft:'77px', width:'89px',height:'9px',background:'#004B90'}}/>
            </Col>
            <Col span={18} offset={3}>
                <img src={map1} alt={'map1'} style={{width:'100%',marginTop:34}} />
            </Col>

            <Col span={22} offset={2} style={{marginTop:44}}>
                <p style={{marginLeft:'75px', fontSize: '42px', fontFamily: 'FZXiaoBiaoSong-B05S', fontWeight: '400', color: '#00529C'}}>
                    政策与需求
                </p>
                <p style={{marginTop:-25,marginLeft:'77px', fontSize: '23px', fontFamily: 'Adobe Heiti Std', fontWeight: 'normal', color: '##292929'}}>
                    Policy and demand
                </p>
                <div style={{marginLeft:'77px', width:'89px',height:'9px',background:'#004B90'}}/>
            </Col>
            <Col span={18} offset={3}>
                <p style={{
                    marginTop:52,
                    fontSize: 21,
                    fontFamily: 'Microsoft YaHei',
                    fontWeight: 400,
                    color: '#333333'
                }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;《健康企业建设规范(试行）》<br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;为贯彻党的十九大及全国卫生与健康大会精神，落实《“健康中国2030”规划纲要》《关于实施健康中国行动的意见》等要求，七部门在2019年底发布关于推进健康企业建设的通知，制订看《健康企业建设规范（试行)》.
                    <br/><br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;企业医疗健康方面的投入与员工在健康方面的需求形成较大差距。
                    <br/><br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2016年中共中央、国务院印发并实施了《“健康中国2030”规划纲要》;2017年，习近平总书记在十九大报告中指出，实施健康中国战略﹔同年，习近平总书记又提出实施国家大数据战略，加快建设数字中国。从此，“健康中国”、“数字中国”上升为国家战略。2019年，卫健委组织制定并发布了《健康中国行动(2019-2030)》·提出围绕疾病预防和健康促进两大核心，开展15个重大专项行动，“职业健康保护行动”包含其中。

                </p>
            </Col>

            <Col span={22} offset={2} style={{marginTop:63}}>
                <p style={{marginLeft:'75px', fontSize: '42px', fontFamily: 'FZXiaoBiaoSong-B05S', fontWeight: '400', color: '#00529C'}}>
                    应用转换
                </p>
                <p style={{marginTop:-25,marginLeft:'77px', fontSize: '23px', fontFamily: 'Adobe Heiti Std', fontWeight: 'normal', color: '##292929'}}>
                    Application conversion
                </p>
                <div style={{marginLeft:'77px', width:'89px',height:'9px',background:'#004B90'}}/>
            </Col>
            <Col span={18} offset={3}>
                <img src={map2} alt={'map2'} style={{width:'100%',marginTop:43}} />
            </Col>
        </Row>
    );
}
const styles = {
    menuItem: {border: 0, fontSize: 45, color: '#FFFFFF',display: 'inline',fontFamily:'FZXiaoBiaoSong-B05S',fontWeight: '400'},
    menu: {background: 'transparent',marginTop:-160,textAlign:'center'},
};

export default Detection;
