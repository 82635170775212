import React from 'react';
import './index.less';
import {Col, Row, Menu} from "antd";
import { useHistory } from 'react-router-dom';
import background from "../../assets/material/background.png";
import map1 from "../../assets/material/Carbon/map1.png";
import map2 from "../../assets/material/Carbon/map2.png";
import map3 from "../../assets/material/Carbon/map3.png";

function Carbon() {
    const history = useHistory();

    //菜单栏点击
    const itemClick = (e) => {
        let k = parseInt(e.key);
        switch (k) {
            case 1://涉电检测
                history.replace('/detection/electricity');
                break;
            case 2://低碳服务
                history.replace('/detection/carbon-service');
                break;
            case 3://碳纤维
                history.replace('/detection/carbons');
                break;
            case 4://新能源入网
                history.replace('/detection/energy');
                break;
            case 5://建筑结构
                history.replace('/detection/build');
                break;
            case 6://职业健康
                history.replace('/detection/healthy');
                break;
            default:
                history.replace('/detection/electricity');
                break;
        }
    };

    return (
        <Row>
            <Col span={24}>
                <img src={background} alt={'background'} style={{width:'100%'}} />
            </Col>
            <Col span={24}>
                <Menu style={styles.menu} onClick={itemClick}>
                    <Menu.Item style={styles.menuItem} key={1}>涉电检测</Menu.Item>
                    <Menu.Item style={styles.menuItem} key={2}>低碳服务</Menu.Item>
                    <Menu.Item style={styles.menuItem} key={3}>碳纤维</Menu.Item>
                    <Menu.Item style={styles.menuItem} key={4}>新能源入网</Menu.Item>
                    <Menu.Item style={styles.menuItem} key={5}>建筑结构</Menu.Item>
                    <Menu.Item style={styles.menuItem} key={6}>职业健康</Menu.Item>
                </Menu>
            </Col>

            <Col span={18} offset={3}>
                <p style={{
                    marginTop:149,
                    fontSize: 21,
                    fontDamily: 'Microsoft YaHei',
                    fontWeight: 400,
                    color: '#333333'
                }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;吉林东研检测技术有限公司是以大学为依托，由吉林东杰科技开发有限公司与广州恒力检测股份有限公司共同出资组建，是综合性第三方安全检测及配、售电一体化技术服务机构。东研检测专注于企业的用电安全及能效管理。业务涵盖电力高低压设备检测及维护、设备托管及智能运维、电力故障抢修、配电房安装改造、能效管理、智能配电房监测系统、电力安全工器具及劳保用品预防性试验、环境检测、仪器仪表校准、售电等业务。
                    <br/><br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;东研检测与化学工程学院、国家碳纤维计量测试联盟、吉林碳谷、吉化集团深度合作，成为吉林碳纤维产业链提供科研技术、应用成果转换贡献的有力保障。
                </p>
            </Col>

            <Col span={22} offset={2} style={{marginTop:63}}>
                <p style={{marginLeft:'75px', fontSize: '42px', fontFamily: 'FZXiaoBiaoSong-B05S', fontWeight: '400', color: '#00529C'}}>
                    应用案例
                </p>
                <p style={{marginTop:-25,marginLeft:'77px', fontSize: '23px', fontFamily: 'Adobe Heiti Std', fontWeight: 'normal', color: '##292929'}}>
                    Application case
                </p>
                <div style={{marginLeft:'77px', width:'89px',height:'9px',background:'#004B90'}}/>
            </Col>
            <Col span={18} offset={3}>
                <Row gutter={[32,32]} style={{marginTop:62}}>
                    <Col span={12}>
                        <img src={map1} alt={'map1'} style={{width:'100%'}} />
                    </Col>
                    <Col span={12}>
                        <p style={{
                            fontSize: 21,
                            fontFamily: 'Microsoft YaHei',
                            fontWeight: 400,
                            color: '#333333'
                        }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;就“聚丙烯腈基碳纤维原丝残留二甲基亚矾非水提取及测试技术”项目及“二甲基亚矾水溶液浓度场在线测量技术研究及设备研制”项目研究的目的、技术路线、研究过程、研究成果及应用进行了讨论汇报，并于吉林实地进行了解题试验。
                        </p>
                    </Col>
                </Row>
            </Col>

            <Col span={22} offset={2} style={{marginTop:63}}>
                <p style={{marginLeft:'75px', fontSize: '42px', fontFamily: 'FZXiaoBiaoSong-B05S', fontWeight: '400', color: '#00529C'}}>
                    科研成果
                </p>
                <p style={{marginTop:-25,marginLeft:'77px', fontSize: '23px', fontFamily: 'Adobe Heiti Std', fontWeight: 'normal', color: '##292929'}}>
                    Scientific achievements
                </p>
                <div style={{marginLeft:'77px', width:'89px',height:'9px',background:'#004B90'}}/>
            </Col>
            <Col span={18} offset={3}>
                <p style={{
                    marginTop:25,
                    fontSize: 21,
                    fontFamily: 'Microsoft YaHei',
                    fontWeight: 400,
                    color: '#333333'
                }}>
                    细菌纤维素复合纳米吸附材料的制备及其应用研究吉林省科技进步奖&nbsp;&nbsp;&nbsp;&nbsp;鲁敏<br/>
                    纤维素基复合纳米水处理材料的制备及其应用研究吉林省电力科学科技进步奖&nbsp;&nbsp;&nbsp;&nbsp;鲁敏<br/>
                    基于3D鸟巢结构Sb2O3/RGO复合材料的高性能锂离子电池研究吉林省自然科学学术成果奖&nbsp;&nbsp;&nbsp;&nbsp;周静<br/>
                    光基于过渡金属取代的Keggin型多酸d电子结构调控反应活性吉林省科学技术奖(自然科学奖)&nbsp;&nbsp;&nbsp;&nbsp;刘春<br/>
                    铝、铁金属玻璃非晶形成能力的热力学解析吉林省科学技术奖(自然科学奖)&nbsp;&nbsp;&nbsp;&nbsp;王嵬<br/>
                    电力系统生产脱硝及纳米水处理材料的制备及应用研究吉林省电力科学技术进步奖&nbsp;&nbsp;&nbsp;&nbsp;杨春华<br/>
                    农业废弃生物质基碳材料的制备及应用吉林省科技进步奖&nbsp;&nbsp;&nbsp;&nbsp;张晓君<br/>
                    水溶性壳聚糖衍生物类造纸增强剂的作用机理吉林省科学技术奖(自然科学奖)&nbsp;&nbsp;&nbsp;&nbsp;陈子成<br/>
                </p>
            </Col>

            <Col span={22} offset={2} style={{marginTop:63}}>
                <p style={{marginLeft:'75px', fontSize: '42px', fontFamily: 'FZXiaoBiaoSong-B05S', fontWeight: '400', color: '#00529C'}}>
                    应用转换
                </p>
                <p style={{marginTop:-25,marginLeft:'77px', fontSize: '23px', fontFamily: 'Adobe Heiti Std', fontWeight: 'normal', color: '##292929'}}>
                    Application conversion
                </p>
                <div style={{marginLeft:'77px', width:'89px',height:'9px',background:'#004B90'}}/>
            </Col>
            <Col span={18} offset={3}>
                <p style={{
                    marginTop:40,
                    fontSize: 21,
                    fontFamily: 'Microsoft YaHei',
                    fontWeight: 400,
                    color: '#333333'
                }}>
                    大唐淮南洛河电厂循环水阻垢缓蚀动静态试验委托合同&nbsp;&nbsp;&nbsp;&nbsp;陈雪梅<br/>
                    复合绝缘了金属附件应力腐蚀检测&nbsp;&nbsp;&nbsp;&nbsp;陈雪梅<br/>
                    金属有机骨架(MOF)改性硅藻土在造纸中的应用&nbsp;&nbsp;&nbsp;&nbsp;陈子成<br/>
                    硅藻士的表面疏水化改性及其在造纸中的应用研究厅局级自科类&nbsp;&nbsp;&nbsp;&nbsp;陈子成<br/>
                    基于可见光催化的通用型微污染水处理技术厅局级自科类&nbsp;&nbsp;&nbsp;&nbsp;贾若琨<br/>
                    给水全挥发处理对锅炉水冷壁氢脆的影响研究技术服务&nbsp;&nbsp;&nbsp;&nbsp;鲁敏<br/>
                    孙墨杰基于阵列纳微传感器的SF6分解产物检测技术研究及应用&nbsp;&nbsp;&nbsp;&nbsp;鲁敏<br/>
                    +E双相结构钴基高嫡超合金的TRIP效应及常温/高温腐蚀行为研究&nbsp;&nbsp;&nbsp;&nbsp;王嵬<br/>
                    有机硅动态防污表面的设计与构筑研究&nbsp;&nbsp;&nbsp;&nbsp;杨淼森<br/>
                    吉林省生物质清洁转化与高值化利用科技创新中心&nbsp;&nbsp;&nbsp;&nbsp;于大禹<br/>
                    原位氧化清洗诱导膜生物反应器生物膜污染机制及调控策略研究国家级一般项目&nbsp;&nbsp;&nbsp;&nbsp;张海丰<br/>
                    基于绝缘油中金属赋存形态检测技术研究&nbsp;&nbsp;&nbsp;&nbsp;张海丰<br/>
                    电絮凝-动态膜生物反应器处理难降解废水集成化装置&nbsp;&nbsp;&nbsp;&nbsp;张海丰<br/>
                    新型聚乙烯吡咯烷酮基水性防腐涂料的制备&nbsp;&nbsp;&nbsp;&nbsp;张兰河<br/>
                    输电线路钢绞线锈蚀状态评估与防护技术研究-防腐&nbsp;&nbsp;&nbsp;&nbsp;张兰河<br/>
                    红霉素在其菌渣肥土壤利用过程中降解机理研究&nbsp;&nbsp;&nbsp;&nbsp;张兰河<br/>
                    纳米颗粒物的细胞毒理学实验研究&nbsp;&nbsp;&nbsp;&nbsp;张兰河<br/>
                    多孔MOPs材料在工业催化领域的应用研究&nbsp;&nbsp;&nbsp;&nbsp;张誉腾<br/>
                </p>
                <Row gutter={[32, 32]} style={{marginTop:52}}>
                    <Col span={12}>
                        <img src={map2} alt={'map2'} style={{width:'96%'}} />
                        <p style={{
                            marginTop:33,
                            fontSize: 21,
                            fontFamily: 'Microsoft YaHei',
                            fontWeight: 400,
                            color: '#333333'
                        }}>
                            吉林省电站水处理技术工程研究中心、<br/>
                            吉林省电力储能与环保材料重点实验室、<br/>
                            水质分析与水处理技术省级实验教学示范中心、<br/>
                            吉林省生物质清洁转化与高值化利用科技创新中心。<br/>
                        </p>
                    </Col>
                    <Col span={12}>
                        <img src={map3} alt={'map3'} style={{width:'100%'}} />
                        <p style={{
                            marginTop:33,
                            fontSize: 21,
                            fontFamily: 'Microsoft YaHei',
                            fontWeight: 400,
                            color: '#333333'
                        }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;签订国家碳纤维产业联盟入盟协议双方在国内电力行业碳纤维应用领域测量测试技术、科研协作、测量测试服务、专业技术人员培养、信息共享合作等领域展开合作。
                        </p>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

const styles = {
    menuItem: {border: 0, fontSize: 45, color: '#FFFFFF',display: 'inline',fontFamily:'FZXiaoBiaoSong-B05S',fontWeight: '400'},
    menu: {background: 'transparent',marginTop:-160,textAlign:'center'},
};

export default Carbon;
