import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import Home from './pages/Home';
import 'antd/dist/antd.css';
import { BackTop } from 'antd';
import Login from "./pages/Login";

function App() {
    return (
        <>
            <BackTop/>
            <Router>
                <Switch>
                    <Route path='/login' exact component={Login} title='login'/>
                    <Route component={Home} title='home'/>
                </Switch>
            </Router>
        </>
    );
}

export default App;