import request, { jsToFormData } from '../utils/request';
import { baseUrl } from '../utils/config';

//查询高校信息
export async function list(payload) {
    return await request(baseUrl + '/api-ene/xxxx/list', {
        method: 'POST',
        data: jsToFormData(payload),
    });
}

//保存高校信息
export async function save(payload) {
    return await request(baseUrl + '/api-ene/xxxx/save', {
        method: 'POST',
        data: payload.fields,
    });
}

//更新高校信息
export async function update(payload) {
    return await request(baseUrl + '/api-ene/xxxx/update', {
        method: 'POST',
        data: payload.fields,
    });
}

//更新高校信息
export async function del(payload) {
    return await request(baseUrl + '/api-ene/xxxx/del', {
        method: 'POST',
        data:jsToFormData(payload),
    });
}

//更新国家信息
export async function getAllGj() {
    return await request(baseUrl + '/api-ene/xxxx/getAllGj', {
        method: 'POST',
    });
}

//查询省
export async function findAllProvince() {
    return await request(baseUrl + '/api-ene/xxxx/findAllProvince', {
        method: 'POST',
    });
}

//查询市
export async function findByCountry(payload) {
    return await request(baseUrl + '/api-ene/xxxx/findByCountry', {
        method: 'POST',
        data: jsToFormData({pid:payload})
    });
}


export default {
    list,
    save,
    update,
    del,
    getAllGj,
    findAllProvince,
    findByCountry,
};
