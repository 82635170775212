import React, { useEffect, useState } from 'react';
import { Col, Form, Input, Modal, Row, Select } from 'antd';
import UpdateModify from './UpdateModify';

const FormItem = Form.Item;
const { Option } = Select;
const {TextArea} = Input;




const UpdateForm = props => {

  const [form] = Form.useForm();
  const {
    onSubmit: handleUpdate,
    onCancel: handleUpdateModalVisible,
    updateModalVisible,
    values,
    sfdwFlXx,
  } = props;

  const [fwbxx, setFwbxx] = useState(values.sm);

  useEffect(async() => {
  }, []);


  const okHandle = async () => {
    const fieldsValue = await form.validateFields();
    form.resetFields();
    handleUpdate({...fieldsValue, sm:fwbxx});
  };

  const validateMessages = {
    required: '${label} 这是必须的填写的!',
    types: {
      email: '${label} 这不是一个有效邮箱!',
      number: '${label} 这不是一个有效数字!',
    },
    number: {
      range: '${label} 必须是 ${min}cm 和 ${max}cm之间',
    },
  };

  const getFwb = async (val) => {
    setFwbxx(val);
  };

  return (
    <div>
      <Modal
        destroyOnClose
        title="修改GIS检测及110kV收费标准"
        visible={updateModalVisible}
        onCancel={() => handleUpdateModalVisible(false, values)}
        afterClose={() => handleUpdateModalVisible()}
        onOk={okHandle}
        width={"60%"}
        okText="确认"
        cancelText="取消"
      >
        <Form
          form={form}
          initialValues={values}
          validateMessages={validateMessages} preserve={false}
        >

          <FormItem
            hidden={true}
            name="id"
          >
            <Input  />
          </FormItem>
          <Row  gutter={16}>
            <Col  span={8}>
              <FormItem
                label="名称"
                name="mc"
              >
                <Input placeholder="请输入"/>
              </FormItem>
            </Col>
            <Col  span={8}>
              <FormItem
                label="定额编号"
                name="debm"
              >
                <Input placeholder="请输入"/>
              </FormItem>
            </Col>
            <Col  span={8}>
              <FormItem
                label="基价"
                name="jj"
              >
                <Input  placeholder="请输入" />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col  span={8}>
              <FormItem
                label="人工"
                name="rg"
              >
                <Input placeholder="请输入"/>
              </FormItem>
            </Col>
            <Col  span={8}>
              <FormItem
                label="材料"
                name="cl"
              >
                <Input placeholder="请输入"/>
              </FormItem>
            </Col>
            <Col  span={8}>
              <FormItem
                label="单位名称"
                name="dwid"
              >
                <Select
                  allowClear={true}
                  showSearch
                  optionFilterProp="label"
                  placeholder="单位名称"
                  filterOption={(input, option) =>
                    option.children.toString().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    sfdwFlXx.map(element =>
                      <Option key={element.id} value={element.id}> {element.mc}</Option>)
                  }
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row  gutter={16}>
            <Col  span={8}>
              <FormItem
                label="机械"
                name="jx"
              >
                <Input  placeholder="请输入" />
              </FormItem>
            </Col>
            <Col  span={8}>
              <FormItem
                label="总计"
                name="zj"
              >
                <Input  placeholder="请输入" />
              </FormItem>
            </Col>
          </Row>
          <Row  gutter={16}>
            <Col span={24}>
              <Form.Item label='说明' >
                <UpdateModify getFwb={getFwb} editorContent={values.sm} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col  span={24}>
              <FormItem
                label="备注"
                name="bz"
              >
                <TextArea style={{ height: 80 }} placeholder="请输入"/>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default UpdateForm;
