import {DownOutlined, LeftOutlined, PlusOutlined, RightOutlined} from '@ant-design/icons';
import {Affix, Button, Carousel, Col, Divider, Dropdown, Menu, message, Modal, Row} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import {PageHeaderWrapper} from '@ant-design/pro-layout';
import CreateForm from './components/CreateForm';
import UpdateForm from './components/UpdateForm';
import api from '../../services/ProductPublicity';
import moment from 'moment';
import CustomProTable from '../../components/CustomProTable';
import Ellipsis from '../../components/Ellipsis';
import ViewForm from './components/ViewForm';


/**
 * 添加节点
 * @param fields
 */
const handleAdd = async fields => {
  const hide = message.loading('正在新建');
  try {
    await api.save({fields});
    hide();
    message.success('新建成功');
    return true;
  } catch (error) {
    hide();
    message.error('新建失败请重试！');
    return false;
  }
};

/**
 * 更新节点
 * @param fields
 */
const handleUpdate = async fields => {
  const hide = message.loading('正在修改');
  try {
    await api.update({fields});
    hide();
    message.success('修改成功');
    return true;
  } catch (error) {
    hide();
    message.error('修改失败请重试！');
    return false;
  }
};

/**
 *  删除节点
 * @param selectedRows
 */
const handleRemove = async selectedRows => {
  const hide = message.loading('正在删除');
  if (!selectedRows) return true;

  try {
    await api.del({
      ids: selectedRows.map(row => row.id),
    });

    hide();
    message.success('删除成功，即将刷新');
    return true;
  } catch (error) {
    hide();
    message.error('删除失败，请重试');
    return false;
  }
};

const TableList = () => {
  const [createModalVisible, handleModalVisible] = useState(false);
  const [updateModalVisible, handleUpdateModalVisible] = useState(false);
  const [stepFormValues, setStepFormValues] = useState({});
  const [stepViewFormValues, setStepViewFormValues] = useState({});

  const [viewModalVisible, handleViewModalVisible] = useState(false);

  const [previewVisible, setViewVisible] = useState(false);
  const [preAllViewVisible, setAllViewVisible] = useState(false);

  const [allData, setAllData] = useState([]);

  const[total,setTotal] =  useState([]);
  const actionRef = useRef();

  const columns = [
    {
        key: 'surl',
        title: '缩略图',
        dataIndex: 'surl',
        hideInSearch: true,
        render: (_, record) => (
            <img src={record.surl} style={{width:100,height:100}}  ></img>
        ),
    },
    {
      key: 'name',
      title: '名称',
      dataIndex: 'name',
      render: val =>  <Ellipsis length={20} tooltip>{val}</Ellipsis>,
    },
    {
      key: 'sort',
      title: '排序',
      dataIndex: 'sort',
      hideInSearch: true,
    },
    {
      key: 'xgsj',
      title: '更新时间',
      dataIndex: 'xgsj',
      hideInSearch: true,
      render: val => <span>{moment(val).format('YYYY-MM-DD HH:mm:ss')}</span>,
    },
    {
      title: '操作',
      valueType: 'option',
      render: (_, record) => (
        <>
          <a onClick={async () => {
            setStepFormValues(record);
            handleUpdateModalVisible(true);
          }}>修改</a>
          <Divider type="vertical" />
          <a onClick={async () => {
            setStepViewFormValues(record);
            handleViewModalVisible(true);
          }}>预览</a>
        </>
      ),
    },
  ];
  const countRef = useRef();

  useEffect(async() => {
  }, [countRef]);

    //取消
    const handleCancel = () => {
        setViewVisible(false)
    };

    //预览
    const handleOk = () => {
        setViewVisible(true)
    };

    //取消
    const handleAllCancel = () => {
        setAllViewVisible(false);
    };

    //预览
    const handleAllOk = () => {
        setAllViewVisible(true);
    };

    //预览全部
    const findAll = async () => {
        handleAllOk();
        let res = await api.findAll();
        setAllData(res.data);
    };


  return (
    <PageHeaderWrapper>
      <CustomProTable
        headerTitle={"查询列表（总共 "+total+" 条）"}
        actionRef={actionRef}
        search={{labelWidth: 'auto',}}
        options={true}
        rowKey="id"
        toolBarRender={(action, { selectedRows }) => [
          <Button icon={<PlusOutlined />} type="primary" onClick={() => handleModalVisible(true)}>
            新建
          </Button>,
            <Button icon={<PlusOutlined />} type="primary" onClick={() => findAll()}>
                预览全部
            </Button>,
          selectedRows && selectedRows.length > 0 && (
            <Dropdown
              overlay={
                <Menu
                  onClick={async e => {
                    if (e.key === 'remove') {
                      await handleRemove(selectedRows);
                      action.reload();
                    }
                  }}
                  selectedKeys={[]}
                >
                  <Menu.Item key="remove">批量删除</Menu.Item>
                </Menu>
              }
            >
              <Button>
                批量操作 <DownOutlined />
              </Button>
            </Dropdown>
          ),
        ]}
        request={async params => {
          const res = await api.list(params);
          setTotal(res.total);
          return res;
        }}
        rowSelection={{}}
        columns={columns}
      />
      <CreateForm
        onSubmit={async value => {
          const success = await handleAdd(value);

          if (success) {
            handleModalVisible(false);

            if (actionRef.current) {
              actionRef.current.reload();
            }
          }
        }}
        onCancel={() => handleModalVisible(false)}
        modalVisible={createModalVisible}
      />
      {stepFormValues && Object.keys(stepFormValues).length ? (
        <UpdateForm
          onSubmit={async value => {
            const success = await handleUpdate(value);

            if (success) {
              handleModalVisible(false);
              setStepFormValues({});

              if (actionRef.current) {
                actionRef.current.reload();
              }
            }
          }}
          onCancel={() => {
            handleUpdateModalVisible(false);
            setStepFormValues({});
          }}
          updateModalVisible={updateModalVisible}
          values={stepFormValues}

        />
      ) : null}
      {stepViewFormValues && Object.keys(stepViewFormValues).length ? (
        <ViewForm
          onSubmit={async value => {
            const success = await handleUpdate(value);

            if (success) {
              handleModalVisible(false);
              setStepViewFormValues({});

              if (actionRef.current) {
                actionRef.current.reload();
              }
            }
          }}
          onCancel={() => {
            handleUpdateModalVisible(false);
            setStepViewFormValues({});
          }}
          updateModalVisible={viewModalVisible}
          values={stepViewFormValues}

        />
      ) : null}
      <Modal
        maskClosable={false}
        destroyOnClose
        visible={preAllViewVisible}
        footer={null}
        onCancel={handleAllCancel}
        width={1000}
      >
        <Row gutter={16}>
            <Col span={2}>
                <Affix offsetTop={300}>
                    <Button style={{opacity:0.7, marginLeft:-100}} size={'large'} onClick={() => {countRef.current.prev();}}><LeftOutlined/></Button>
                </Affix>
            </Col>
            <Col span={20}>
                <Carousel
                    ref={countRef}
                    {...{
                        dots:true,
                        arrows:false,
                        draggable:false,
                        autoplay:true,
                        speed: 1000,
                        autoplaySpeed: 5000,
                    }}>

                    {
                        allData.map(element =>
                            <div>
                                <h3><img alt="example" loading style={{ width: '100%'}} alt="scroll1" src={element.rurl}  /></h3>
                            </div>
                        )
                    }
                </Carousel>
            </Col>
            <Col span={2}>
                <Affix offsetTop={300}>
                    <Button style={{opacity:0.7 , marginLeft:100}}  size={'large'} onClick={() => {countRef.current.next();}}><RightOutlined/></Button>
                </Affix>
            </Col>
        </Row>
      </Modal>
    </PageHeaderWrapper>
  );
};

export default TableList;
