import request, { jsToFormData } from '../utils/request';
import { baseUrl, baseHTTPSUrl } from '../utils/config';

// 登录 Post请求接口地址
export async function login(params) {
    return request(baseUrl + '/sys/login', {
        method: 'POST',
        data: params,
    });
}

//当前登录用户的 用户基本信息，角色，权限
export async function getCurrentUser() {
    return request(baseUrl + '/api-u/users/current', {
        method: 'POST',
    });
}

//获取当前用户对应角色下的菜单
export async function getCurrentMenu(payload) {
    return request(baseUrl + '/api-ene/menus/me', {
        method: 'POST',
        data: jsToFormData(payload),
    });
}

//新闻动态 功能查询
export async function getNews(payload) {
    return await request(baseUrl + '/api-web/webinfo-anon/selectAllNews', {
        method: 'POST',
        data:jsToFormData(payload),
    });
}

//组织信息无分页 查询
export async function selectpagOrganize() {
    return request(baseUrl + '/api-ene/organize/pagSelectAll', {
        method: 'POST',
    });
}

//检测中心项目 查询
export async function getCenterProject(payload) {
    return request(baseUrl + '/api-ene/centerProject/selectAll', {
        method: 'POST',
        data: jsToFormData(payload),
    });
}

//检测中心类别分类 查询
export async function getClassiSelectAll() {
    return request(baseUrl + '/api-ene/centerProject/classiSelectAll', {
        method: 'POST',
    });
}

//能源新闻 查询
export async function selectNews(payload) {
    return request(baseUrl + '/api-ene/news/selectAll', {
        method: 'POST',
        data: jsToFormData(payload),
    });
}

//能源新闻 创建
export async function setNews(payload) {
    return request(baseUrl + '/api-ene/news/insertteam', {
        method: 'POST',
        data: payload.fields,
    });
}

//能源新闻 修改
export async function getNewss(payload) {
    return request(baseUrl + '/api-ene/news/update', {
        method: 'POST',
        data: payload.fields,
    });
}

//能源新闻 删除
export async function deleteNews(payload) {
    return request(baseUrl + '/api-ene/news/deleteByPrimaryKey', {
        method: 'POST',
        data: jsToFormData(payload),
    });
}

//能源新闻-外网 查询
export async function selectNetworkNews(payload) {
    return request(baseHTTPSUrl + '/webinfo-anon/newsSelect?pageSize='+payload, {
        method: 'GET',
    });
}

//能源新闻-外网 查询
export async function energyAuditSelect(classify,pageSize) {
    return request(baseHTTPSUrl + '/webinfo-anon/energyAuditSelect?classify='+classify+'&pageSize='+pageSize, {
        method: 'GET',
    });
}

// //能源新闻-外网 查询
// export async function selectNetworkNews(payload) {
//     return request(baseUrl + '/api-ene/webinfo-anon/newsSelect?pageSize='+payload, {
//         method: 'GET',
//     });
// }
//
// //能源新闻-外网 查询
// export async function energyAuditSelect(classify,pageSize) {
//     return request(baseUrl + '/api-ene/webinfo-anon/energyAuditSelect?classify='+classify+'&pageSize='+pageSize, {
//         method: 'GET',
//     });
// }

//能源新闻审核关联 查询
export async function selectEnergyAudit(payload) {
    return request(baseUrl + '/api-ene/energyAudit/selectByPrimaryKey', {
        method: 'POST',
        data: jsToFormData(payload),
    });
}

//能源新闻审核关联 创建
export async function setEnergyAudit(payload) {
    return request(baseUrl + '/api-ene/energyAudit/insert', {
        method: 'POST',
        data: jsToFormData(payload),
    });
}

//能源新闻审核关联 修改
export async function getEnergyAudit(payload) {
    return request(baseUrl + '/api-ene/energyAudit/update', {
        method: 'POST',
        data: jsToFormData(payload),
    });
}

//能源新闻审核关联 删除
export async function deleteEnergyAudit(payload) {
    return request(baseUrl + '/api-ene/energyAudit/deleteByPrimaryKey', {
        method: 'POST',
        data: jsToFormData(payload),
    });
}


//能源新闻 查询
export async function selectProductServiceNews(payload) {
    return request(baseUrl + '/api-ene/productServiceNews/selectAll', {
        method: 'POST',
        data: jsToFormData(payload),
    });
}

//能源产品服务 创建
export async function setProductServiceNews(payload) {
    return request(baseUrl + '/api-ene/productServiceNews/insertteam', {
        method: 'POST',
        data: payload.fields,
    });
}

//能源产品服务 修改
export async function getProductServiceNewss(payload) {
    return request(baseUrl + '/api-ene/productServiceNews/update', {
        method: 'POST',
        data: payload.fields,
    });
}

//能源产品服务 删除
export async function deleteProductServiceNews(payload) {
    return request(baseUrl + '/api-ene/productServiceNews/deleteByPrimaryKey', {
        method: 'POST',
        data: jsToFormData(payload),
    });
}

export default {
    login,
    getCurrentUser,
    getNews,
    getCenterProject,
    getClassiSelectAll,
    selectpagOrganize,
    selectNews,setNews,getNewss,deleteNews,
    selectNetworkNews,energyAuditSelect,
    selectEnergyAudit,setEnergyAudit,getEnergyAudit,deleteEnergyAudit,
    selectProductServiceNews,setProductServiceNews,getProductServiceNewss,deleteProductServiceNews,

};
