import React from 'react';
import './index.css';
import {Anchor, Card, Col, Row} from "antd";
import background from "../../assets/material/background.png";
import introduce1 from "../../assets/material/LowCarbon/introduce1.png";
import introduce2 from "../../assets/material/LowCarbon/introduce2.png";
import map1 from "../../assets/material/LowCarbon/map1.png";

function LowCarbon() {

    return (
        <Row>
            <Col span={24}>
                <img src={background} alt={'background'} style={{width:'100%'}} />
            </Col>
            <Col span={24}>
                <Anchor
                    affix={false}
                    style={{
                        marginTop:-160,
                        textAlign:'center',
                        backgroundColor: 'transparent'
                    }}
                >
                    <a href="#先导区">
                        <span style={{fontSize: 40, fontFamily: 'FZXiaoBiaoSong-B05S', fontWeight: 400, color:'#FFFFFF'}}>先导区</span>
                    </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a href="#互联网平台">
                        <span style={{fontSize: 40, fontFamily: 'FZXiaoBiaoSong-B05S', fontWeight: 400, color:'#FFFFFF'}}>互联网平台</span>
                    </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a href="#产业园">
                        <span style={{fontSize: 40, fontFamily: 'FZXiaoBiaoSong-B05S', fontWeight: 400, color:'#FFFFFF'}}>产业园</span>
                    </a>
                </Anchor>
            </Col>

            <Col span={6} offset={9}>
                <div style={{
                    marginTop:85,
                    textAlign:'center',
                    background: 'linear-gradient(to left, #043add, #004B90) left bottom no-repeat,linear-gradient(to bottom, #004B90, #004B90) left bottom no-repeat,linear-gradient(to left, #004B90, #004B90) right top no-repeat,linear-gradient(to left, #004B90, #004B90) right top no-repeat',
                    backgroundSize: '0.25rem 2rem, 2rem 0.25rem'
                }} id='先导区'>
                    <p className={'titles'}>
                        绿色低碳产业先导区
                    </p>
                    <p className={'titles-English'}>
                        Green low-carbon industry pilot area
                    </p>
                </div>
            </Col>
            <Col span={18} offset={3}>
                <Card style={{ width:'100%',backgroundColor:'#EFEFEF',marginTop:255}}>
                    <Row>
                        <Col span={14}/>
                        <Col span={10}>
                            <p style={{
                                fontSize: 21,
                                fontFamily: 'Microsoft YaHei',
                                fontWeight: 400,
                                color: '#333333'
                            }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;包括前端物联网（冷、热、水、电、气），数据中心，运营控制中心，应用服务，展示中心和能源地图。利用这个能源互联网平台我们将在用能侧建立一个多能互补的源网荷储城市能源互联网，利用物联网、大数据和人工智能技术为吉林市的企业提供能源替代、节能减排、能源托管、能源交易、碳交易等服务，实现绿色低碳技术与产业应用场景精准对接，目前我们现在正在推进的是 200 家的试点，包括工业、商业、学校建筑等，能够针对企业需求，提供定制化开发。
                                <br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;能源互联网平台成为吉林市绿色低碳产业发展的基础信息平台，也是整个城市绿色低碳产业发展的抓手。
                            </p>
                        </Col>
                    </Row>
                </Card>
                <Row style={{marginTop:-600}}>
                    <Col span={13} offset={1}>
                        <img src={introduce2} alt={'introduce1'} style={{width:'95%'}} />
                    </Col>
                    <Col span={10}/>
                </Row>
            </Col>

            <Col span={6} offset={9}>
                <div style={{
                    marginTop:74,
                    textAlign:'center',
                    background: 'linear-gradient(to left, #043add, #004B90) left bottom no-repeat,linear-gradient(to bottom, #004B90, #004B90) left bottom no-repeat,linear-gradient(to left, #004B90, #004B90) right top no-repeat,linear-gradient(to left, #004B90, #004B90) right top no-repeat',
                    backgroundSize: '0.25rem 2rem, 2rem 0.25rem'
                }} id='互联网平台'>
                    <p className={'titles'}>
                        能源互联网平台
                    </p>
                    <p className={'titles-English'}>
                        Energy Internet platform
                    </p>
                </div>
            </Col>
            <Col span={18} offset={3}>
                <Row gutter={32} style={{marginTop:37}}>
                    <Col span={8}>
                        <p style={{
                            marginTop:110,
                            fontSize: 21,
                            fontFamily: 'Microsoft YaHei',
                            fontWeight: 400,
                            color: '#333333'
                        }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;包括前端物联网（冷、热、水、电、气），数据中心，运营控制中心，应用服务，展示中心和能源地图。利用这个能源互联网平台我们将在用能侧建立一个多能互补的源网荷储城市能源互联网，利用物联网、大数据和人工智能技术为吉林市的企业提供能源替代、节能减排、能源托管、能源交易、碳交易等服务，实现绿色低碳技术与产业应用场景精准对接，目前我们现在正在推进的是 200 家的试点，包括工业、商业、学校建筑等，能够针对企业需求，提供定制化开发。
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;能源互联网平台成为吉林市绿色低碳产业发展的基础信息平台，也是整个城市绿色低碳产业发展的抓手。
                        </p>
                    </Col>
                    <Col span={16}>
                        <img src={introduce1} alt={'introduce1'} style={{width:'100%'}} />
                    </Col>
                </Row>
            </Col>

            <Col span={8} offset={8}>
                <div style={{
                    marginTop:85,
                    textAlign:'center',
                    background: 'linear-gradient(to left, #043add, #004B90) left bottom no-repeat,linear-gradient(to bottom, #004B90, #004B90) left bottom no-repeat,linear-gradient(to left, #004B90, #004B90) right top no-repeat,linear-gradient(to left, #004B90, #004B90) right top no-repeat',
                    backgroundSize: '0.25rem 2rem, 2rem 0.25rem'
                }} id='产业园'>
                    <p className={'titles'}>
                        吉林市昌邑区都市工业产业园
                    </p>
                    <p className={'titles-English'}>
                        Changyi District City Industrial Park, Jilin City
                    </p>
                </div>
            </Col>
            <Col span={18} offset={3}>
                <Row>
                    <Col span={22} offset={2} style={{marginTop:72}}>
                        <img src={map1} alt={'map1'} style={{width:'90%'}} />
                    </Col>
                    <Col span={17} offset={3}>
                        <p style={{
                            marginTop:42,
                            fontSize: 21,
                            fontFamily: 'Microsoft YaHei',
                            fontWeight: 400,
                            color: '#333333'
                        }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            以上为产业园的全景俯视图，二期是正在建设的能源信息高铁，引进的是中科院计算所孙凝辉院士团队的我国自主知识产权的新一代高通量大数据技术，主要包括能源信息高铁站、北方能源大数据研究院和能源大数据配套产业，用以提供支撑 “吉林市能源互联网公共服务平台”的算力和算法，形成城市能源大数据，进而推动吉林市绿色低碳产业向数字化、智能化和服务化升级。
                            <br/><br/>
                        </p>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default LowCarbon;
