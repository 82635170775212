import React from 'react';
import './index.less';
import {Col, Row, Card, Menu} from "antd";
import { useHistory } from 'react-router-dom';
import background from "../../assets/material/background.png";
import map1 from "../../assets/material/Electricity/map1.png";
import map2 from "../../assets/material/Electricity/map2.png";
import map3 from "../../assets/material/Electricity/map3.png";
import map4 from "../../assets/material/Electricity/map4.png";
import map5 from "../../assets/material/Electricity/map5.png";
import map6 from "../../assets/material/Electricity/map6.png";
import map7 from "../../assets/material/Electricity/map7.png";
import map8 from "../../assets/material/Electricity/map8.png";
import introduce1 from "../../assets/material/Electricity/introduce1.png";
import introduce2 from "../../assets/material/Electricity/introduce2.png";
import introduce3 from "../../assets/material/Electricity/introduce3.png";

function Electricity() {
    const history = useHistory();

    //菜单栏点击
    const itemClick = (e) => {
        let k = parseInt(e.key);
        switch (k) {
            case 1://涉电检测
                history.replace('/detection/electricity');
                break;
            case 2://低碳服务
                history.replace('/detection/carbon-service');
                break;
            case 3://碳纤维
                history.replace('/detection/carbons');
                break;
            case 4://新能源入网
                history.replace('/detection/energy');
                break;
            case 5://建筑结构
                history.replace('/detection/build');
                break;
            case 6://职业健康
                history.replace('/detection/healthy');
                break;
            default:
                history.replace('/detection/electricity');
                break;
        }
    };

    return (
        <Row>
            <Col span={24}>
                <img src={background} alt={'background'} style={{width:'100%'}} />
            </Col>
            <Col span={24}>
                <Menu style={styles.menu} onClick={itemClick}>
                    <Menu.Item style={styles.menuItem} key={1}>涉电检测</Menu.Item>
                    <Menu.Item style={styles.menuItem} key={2}>低碳服务</Menu.Item>
                    <Menu.Item style={styles.menuItem} key={3}>碳纤维</Menu.Item>
                    <Menu.Item style={styles.menuItem} key={4}>新能源入网</Menu.Item>
                    <Menu.Item style={styles.menuItem} key={5}>建筑结构</Menu.Item>
                    <Menu.Item style={styles.menuItem} key={6}>职业健康</Menu.Item>
                </Menu>
            </Col>

            <Col span={18} offset={3}>
                <p style={{
                    marginTop:149,
                    fontSize: 21,
                    fontDamily: 'Microsoft YaHei',
                    fontWeight: 400,
                    color: '#333333'
                }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;吉林东研检测技术有限公司是以大学为依托，由吉林东杰科技开发有限公司综合性第三方安全检测及配、售电一体化技术服务机构。东研检测专注于企业的用电安全及能效管理。业务涵盖电力高低压设备检测及维护、设备托管及智能运维、电力故障抢修、配电房安装改造、能家效管理、智能配电房监测系统、电力安全工器具及劳保用品预防性试验、环境检测、仪器仪表校准、售电等业务。
                    <br/><br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;东研检测与大学“电力系统安全运行与节能技术国家地方联合工程实验室”、“现代电力系统仿真控制与绿色电能新技术教育部重点实验室”、国家发改委，教育部实验室深度合作，测试设备及环境按国家级实验室标准配置，现阶段以电气设备检验检测业务为主。
                </p>
            </Col>

            <Col span={22} offset={2} style={{marginTop:63}}>
                <p style={{marginLeft:'75px', fontSize: '42px', fontFamily: 'FZXiaoBiaoSong-B05S', fontWeight: '400', color: '#00529C'}}>
                    科研设备
                </p>
                <p style={{marginTop:-25,marginLeft:'77px', fontSize: '23px', fontFamily: 'Adobe Heiti Std', fontWeight: 'normal', color: '##292929'}}>
                    Scientific equipment
                </p>
                <div style={{marginLeft:'77px', width:'89px',height:'9px',background:'#004B90'}}/>
            </Col>
            <Col span={18} offset={3} >
                <Row gutter={[32, 32]} style={{marginTop:33}}>
                    <Col span={6}>
                        <img src={map1} alt={'map1'} style={{width:'100%'}} />
                        <p style={{
                            marginTop:20,
                            fontSize: 21,
                            fontDamily: 'Microsoft YaHei',
                            fontWeight: 400,
                            color: '#333333'
                        }}>电力电子化电力系统数字物理<br/>混合仿真平台</p>
                    </Col>
                    <Col span={6}>
                        <img src={map2} alt={'map2'} style={{width:'100%'}} />
                        <p style={{
                            marginTop:20,
                            fontSize: 21,
                            fontDamily: 'Microsoft YaHei',
                            fontWeight: 400,
                            color: '#333333'
                        }}>1600KV直流高电压发生器</p>
                    </Col>
                    <Col span={6}>
                        <img src={map3} alt={'map3'} style={{width:'100%'}} />
                        <p style={{
                            marginTop:20,
                            fontSize: 21,
                            fontDamily: 'Microsoft YaHei',
                            fontWeight: 400,
                            color: '#333333'
                        }}>49电平MMC柔直输电仿真平台</p>
                    </Col>
                    <Col span={6}>
                        <img src={map4} alt={'map4'} style={{width:'100%'}} />
                        <p style={{
                            marginTop:20,
                            fontSize: 21,
                            fontDamily: 'Microsoft YaHei',
                            fontWeight: 400,
                            color: '#333333'
                        }}>220KV/66kV输变电运行系统</p>
                    </Col>

                    <Col span={6}>
                        <img src={map5} alt={'map5'} style={{width:'100%'}} />
                        <p style={{
                            marginTop:20,
                            fontSize: 21,
                            fontDamily: 'Microsoft YaHei',
                            fontWeight: 400,
                            color: '#333333'
                        }}>新形态电力系统动态仿真平台</p>
                    </Col>
                    <Col span={6}>
                        <img src={map6} alt={'map6'} style={{width:'100%'}} />
                        <p style={{
                            marginTop:20,
                            fontSize: 21,
                            fontDamily: 'Microsoft YaHei',
                            fontWeight: 400,
                            color: '#333333'
                        }}>大电网广域态势感知与运行控制平台</p>
                    </Col>
                    <Col span={6}>
                        <img src={map7} alt={'map7'} style={{width:'100%'}} />
                        <p style={{
                            marginTop:20,
                            fontSize: 21,
                            fontDamily: 'Microsoft YaHei',
                            fontWeight: 400,
                            color: '#333333'
                        }}>输变电设备运行状态模拟与监测平台</p>
                    </Col>
                    <Col span={6}>
                        <img src={map8} alt={'map8'} style={{width:'100%'}} />
                        <p style={{
                            marginTop:20,
                            fontSize: 21,
                            fontDamily: 'Microsoft YaHei',
                            fontWeight: 400,
                            color: '#333333'
                        }}>柔性直流输电数字物理混合仿真平台</p>
                    </Col>

                    <Col span={24}>
                        <Card style={{backgroundColor:'#EFEFEF'}}>
                            <Row gutter={[32, 32]} style={{marginTop:36}}>
                                <Col span={10} offset={2}>
                                    <p style={{
                                        fontSize: 21,
                                        fontFamily: 'Microsoft YaHei',
                                        fontWeight: 400,
                                        color: '#333333'
                                    }}>
                                        20kW-MMC四端柔性直流输电装置<br/>
                                        30KW风力发电设备冲击电压发生器<br/>
                                        大电网广域态势感知与运行控制仿真系统<br/>
                                        大电网广域态势感知与运行控制数据采集系统<br/>
                                        电力电子变换技术基础研发平台<br/>
                                        傅立叶光谱仪<br/>
                                        高频高压大功率开关直流电源<br/>
                                        工频高压试验变压器<br/>
                                        红相接地装置<br/>
                                    </p>
                                </Col>
                                <Col span={12}>
                                    <p style={{
                                        fontSize: 21,
                                        fontFamily: 'Microsoft YaHei',
                                        fontWeight: 400,
                                        color: '#333333'
                                    }}>
                                        极端环境下超高压输电外绝缘研究平台<br/>
                                        基于PMU/RTU一体化的广域动态新能源监控系<br/>
                                        统绿色交直流低压配电网实验平台<br/>
                                        柔性电网模拟系统<br/>
                                        实时数字仿真系统<br/>
                                        双馈模拟风力发电机<br/>
                                        特高压直流输电系统教学模拟实验台<br/>
                                        网络仿真系统<br/>
                                        永磁直驱模拟风力发电机<br/>
                                        阻抗分析测试系统<br/>
                                    </p>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Col>

            <Col span={22} offset={2} style={{marginTop:63}}>
                <p style={{marginLeft:'75px', fontSize: '42px', fontFamily: 'FZXiaoBiaoSong-B05S', fontWeight: '400', color: '#00529C'}}>
                    科研成果转换项目
                </p>
                <p style={{marginTop:-25,marginLeft:'77px', fontSize: '23px', fontFamily: 'Adobe Heiti Std', fontWeight: 'normal', color: '##292929'}}>
                    Scientific Research Achievement conversion project
                </p>
                <div style={{marginLeft:'77px', width:'89px',height:'9px',background:'#004B90'}}/>
            </Col>
            <Col span={18} offset={3} >
                <Row gutter={[48, 48]} style={{marginTop:56,textAlign:'center'}}>
                    <Col span={12}>
                        <img src={introduce1} alt={'introduce1'} style={{width:'95%'}} />
                        <p style={{
                            marginTop:20,
                            fontSize: 21,
                            fontDamily: 'Microsoft YaHei',
                            fontWeight: 400,
                            color: '#333333'
                        }}>贵州贞丰光伏电站预防性试验</p>
                    </Col>
                    <Col span={12}>
                        <img src={introduce2} alt={'introduce2'} style={{width:'95%'}} />
                        <p style={{
                            marginTop:20,
                            fontSize: 21,
                            fontDamily: 'Microsoft YaHei',
                            fontWeight: 400,
                            color: '#333333'
                        }}>美的试验项目</p>
                    </Col>
                    <Col span={12}>
                        <img src={introduce3} alt={'introduce3'} style={{width:'95%'}} />
                        <p style={{
                            marginTop:20,
                            fontSize: 21,
                            fontDamily: 'Microsoft YaHei',
                            fontWeight: 400,
                            color: '#333333'
                        }}>华为南方工厂低压柜变压器设备年度检修项目</p>
                    </Col>
                </Row>
            </Col>

            <Col span={22} offset={2} style={{marginTop:63}}>
                <p style={{marginLeft:'75px', fontSize: '42px', fontFamily: 'FZXiaoBiaoSong-B05S', fontWeight: '400', color: '#00529C'}}>
                    科研成果
                </p>
                <p style={{marginTop:-25,marginLeft:'77px', fontSize: '23px', fontFamily: 'Adobe Heiti Std', fontWeight: 'normal', color: '##292929'}}>
                    Scientific achievements
                </p>
                <div style={{marginLeft:'77px', width:'89px',height:'9px',background:'#004B90'}}/>
            </Col>
            <Col span={18} offset={3} >
                <p style={{
                    marginTop:52,
                    fontSize: 21,
                    fontDamily: 'Microsoft YaHei',
                    fontWeight: 400,
                    color: '#333333'
                }}>
                    吉林省新能源电网智能化运行与控制工程实验室&nbsp;&nbsp;&nbsp;&nbsp;蔡国伟<br/>
                    新能源发电利用重大需求协同创新中心&nbsp;&nbsp;&nbsp;&nbsp;严干贵<br/>
                    能源高效洁净开发利用重大需求协同创新中心&nbsp;&nbsp;&nbsp;&nbsp;穆钢<br/>
                    电力系统安全运行与节能技术国家地方联合工程实验室&nbsp;&nbsp;&nbsp;&nbsp;李国庆<br/>
                    吉林省电力电子产业公共技术研发中心&nbsp;&nbsp;&nbsp;&nbsp;严干贵<br/>
                    吉林省现代电力系统仿真控制与绿色电能新技术重点实验室&nbsp;&nbsp;&nbsp;&nbsp;穆钢<br/>
                    吉林省风力发电联网运行与控制技术工程研究中心&nbsp;&nbsp;&nbsp;&nbsp;严干贵<br/>
                    现代电力系统仿真控制与绿色电能新技术教育部重点实验室&nbsp;&nbsp;&nbsp;&nbsp;穆钢<br/>
                    吉林省现代电力系统仿真控制与绿色电能新技术重点实验室&nbsp;&nbsp;&nbsp;&nbsp;穆钢<br/>
                </p>
            </Col>
        </Row>
    );
}

const styles = {
    menuItem: {border: 0, fontSize: 45, color: '#FFFFFF',display: 'inline',fontFamily:'FZXiaoBiaoSong-B05S',fontWeight: '400'},
    menu: {background: 'transparent',marginTop:-160,textAlign:'center'},
}

export default Electricity;
