import React, {useEffect, useState} from 'react';
import {Col, Row, List, Card} from "antd";
import background from '../../assets/material/Case/background.png';
import map1 from '../../assets/material/Case/map1.png';
import map2 from '../../assets/material/Case/map2.png';
import map3 from '../../assets/material/Case/map3.png';
import map4 from '../../assets/material/Case/map4.png';
import map5 from '../../assets/material/Case/map5.png';
import map6 from '../../assets/material/Case/map6.png';
import map7 from '../../assets/material/Case/map7.png';
import map8 from '../../assets/material/Case/map8.png';
import map9 from '../../assets/material/Case/map9.png';
import map10 from '../../assets/material/Case/map10.png';
import map11 from '../../assets/material/Case/map11.png';
import map12 from '../../assets/material/Case/map12.png';
import map13 from '../../assets/material/Case/map13.png';
import api from "../../services/api";
import { useHistory } from 'react-router-dom';
import moment from "moment";

function Case() {
    const history = useHistory();
    const[listData,setListData] =  useState([]);
    const [listData1, setListData1] = useState([]);

    useEffect(async() => {
        if (window.matchMedia("(max-width: 1600px)").matches){
            const res = await api.energyAuditSelect(2,3);
            await setListData(res.data);

            const res1 = await api.energyAuditSelect(3,3);
            setListData1(res1.data);
        }else {
            const res = await api.energyAuditSelect(2,4);
            await setListData(res.data);

            const res1 = await api.energyAuditSelect(3,4);
            await setListData1(res1.data);
        }
    }, []);

    //详细页面
    const detailed = (selectData) =>{
        //父页面传参
        history.replace({pathname:"/NewsDetail",state:selectData[0]});
    };

    return (
        <Row>
            <Col span={24}>
                <img src={background} alt={'background'} style={{width:'100%',pointerEvents: 'none'}} />
            </Col>

            <Col span={18} offset={3} style={{marginTop:74}}>
                <Row gutter={18}>
                    <Col span={14}>
                        <img src={map1} alt={'map1'} style={{width:'100%'}} />
                    </Col>
                    <Col span={10}>
                        <Row>
                            <Col span={20}>
                              <span style={{
                                  marginTop:7,
                                  fontSize: 29,
                                  fontFamily: 'zihun59hao-chuangcuhei',
                                  fontWeight: 400,
                                  color: '#333333'
                              }}>
                                  企业动态
                              </span>&nbsp;&nbsp;&nbsp;
                                <span style={{
                                    marginTop:18,
                                    fontSize: 19,
                                    fontFamily: 'zihun59hao-chuangcuhei',
                                    fontWeight: 400,
                                    color: '#00529C'
                                }}>
                                  Business Dynamics
                              </span>
                            </Col>
                            <Col span={4}>
                                <a href='/NewsDetail'>
                                    <p style={{
                                        marginTop:18,
                                        fontSize: 21,
                                        fontFamily: 'zihun59hao-chuangcuhei',
                                        fontWeight: 400,
                                        color: '#00529C'
                                    }}>
                                        更多
                                    </p>
                                </a>
                            </Col>
                            <Col span={24}>
                                <List
                                    itemLayout="vertical"
                                    size="large"
                                    dataSource={listData}
                                    renderItem={item => (
                                        <List.Item>
                                            <Row>
                                                <Col span={4}>
                                                    <div style={{backgroundColor:'#b8c6e0',width:68,height:68,textAlign:'center'}}>
                                                        <p style={{
                                                            fontSize: 18,
                                                            fontFamily: 'zihun59hao-chuangcuhei',
                                                            fontWeight: 400,
                                                            color: '#FFFFFF'
                                                        }}>{moment(item.updatetime).format('YYYY')}</p>
                                                        <p style={{
                                                            marginTop:-14,
                                                            fontSize: 18,
                                                            fontFamily: 'zihun59hao-chuangcuhei',
                                                            fontWeight: 400,
                                                            color: '#FFFFFF'
                                                        }}>{moment(item.updatetime).format('MM-DD')}</p>
                                                    </div>
                                                </Col>
                                                <Col span={20}>
                                                    <Row>
                                                        <Col span={24}>
                                                            <a onClick={() =>detailed([item])}>
                                                                <p style={{
                                                                    marginTop:-15,
                                                                    fontSize: 21,
                                                                    fontFamily: 'zihun59hao-chuangcuhei',
                                                                    fontWeight: 400,
                                                                    color: '#555555'
                                                                }}>
                                                                    {item.title}
                                                                </p>
                                                            </a>
                                                        </Col>
                                                        {/*<Col span={24}>*/}
                                                            {/*<p style={{*/}
                                                                {/*marginTop:-10,*/}
                                                                {/*fontSize: 19,*/}
                                                                {/*fontFamily: 'zihun59hao-chuangcuhei',*/}
                                                                {/*fontWeight: 400,*/}
                                                                {/*color: '#999999'*/}
                                                            {/*}}>*/}
                                                                {/*<Ellipsis length={49} tooltip>{item.describes}</Ellipsis>*/}
                                                            {/*</p>*/}
                                                        {/*</Col>*/}
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </List.Item>
                                    )}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>

            <Col span={18} offset={3} style={{marginTop:77,textAlign:'center'}}>
                <p style={{
                    fontSize: 42,
                    fontFamily: 'FZXiaoBiaoSong-B05S',
                    fontWeight: 400,
                    color: '#00529C'
                }}>行业信息</p>
                <p style={{
                    fontSize: 23,
                    fontFamily: 'Adobe Heiti Std',
                    fontWeight: 'normal',
                    color: '#292929'
                }}>Industry News</p>
            </Col>
            <Col span={13} offset={11}>
                <div style={{
                    width: 89,
                    height: 9,
                    marginLeft:33,
                    background: '#004B90'
                }}/>
            </Col>
            <Col span={18} offset={3}>
                <Row gutter={32} style={{marginTop:47}}>
                    <Col span={6}>
                        <Card
                            style={{width: '100%' }}
                            cover={<img alt="map2" src={map2} />}
                        >
                            <p style={{
                                fontSize: 23,
                                fontFamily: 'zihun59hao-chuangcuhei',
                                fontWeight: 400,
                                color: '#555555'
                            }}>
                                2021中国民营企业500强榜单发布！
                            </p>
                            <p style={{
                                fontSize: 20,
                                fontFamily: 'zihun59hao-chuangcuhei',
                                fontWeight: 400,
                                color: '#999999'
                            }}>
                                9月25日，全国工商联在湖南长沙发布了2021中国民营企业500强、中国制造业民营企业500强、中国服务业民营企业100强三个榜单。
                            </p>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card
                            style={{width: '100%' }}
                            cover={<img alt="map3" src={map3} />}
                        >
                            <p style={{
                                fontSize: 23,
                                fontFamily: 'zihun59hao-chuangcuhei',
                                fontWeight: 400,
                                color: '#555555'
                            }}>
                                2021中国民营企业500强榜单发布！
                            </p>
                            <p style={{
                                fontSize: 20,
                                fontFamily: 'zihun59hao-chuangcuhei',
                                fontWeight: 400,
                                color: '#999999'
                            }}>
                                9月25日，全国工商联在湖南长沙发布了2021中国民营企业500强、中国制造业民营企业500强、中国服务业民营企业100强三个榜单。
                            </p>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card
                            style={{width: '100%' }}
                            cover={<img alt="map4" src={map4} />}
                        >
                            <p style={{
                                fontSize: 23,
                                fontFamily: 'zihun59hao-chuangcuhei',
                                fontWeight: 400,
                                color: '#555555'
                            }}>
                                2021中国民营企业500强榜单发布！
                            </p>
                            <p style={{
                                fontSize: 20,
                                fontFamily: 'zihun59hao-chuangcuhei',
                                fontWeight: 400,
                                color: '#999999'
                            }}>
                                9月25日，全国工商联在湖南长沙发布了2021中国民营企业500强、中国制造业民营企业500强、中国服务业民营企业100强三个榜单。
                            </p>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card
                            style={{width: '100%' }}
                            cover={<img alt="map5" src={map5} />}
                        >
                            <p style={{
                                fontSize: 23,
                                fontFamily: 'zihun59hao-chuangcuhei',
                                fontWeight: 400,
                                color: '#555555'
                            }}>
                                2021中国民营企业500强榜单发布！
                            </p>
                            <p style={{
                                fontSize: 20,
                                fontFamily: 'zihun59hao-chuangcuhei',
                                fontWeight: 400,
                                color: '#999999'
                            }}>
                                9月25日，全国工商联在湖南长沙发布了2021中国民营企业500强、中国制造业民营企业500强、中国服务业民营企业100强三个榜单。
                            </p>
                        </Card>
                    </Col>
                </Row>
            </Col>

            <Col span={24} style={{marginTop:27,backgroundColor:'#254D8F'}}>
                <Row style={{marginTop:79,marginBottom:83}}>
                    <Col span={17} offset={4}>
                        <Row gutter={36}>
                            <Col span={7}>
                                <img alt="map6" src={map6} style={{width:'100%'}}/>
                                <img alt="map7" src={map7} style={{marginTop:36,width:'100%'}}/>
                            </Col>
                            <Col span={10}>
                                <img alt="map8" src={map8} style={{width:'88%'}}/>
                            </Col>
                            <Col span={7} style={{marginLeft:-60,}}>
                                <img alt="map9" src={map9} style={{width:'100%'}}/>
                                <img alt="map10" src={map10} style={{marginTop:36,width:'100%'}}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>

            <Col span={18} offset={3} style={{marginTop:145}}>
                <Row gutter={20}>
                    <Col span={12}>
                        <Row>
                            <Col span={20}>
                              <span style={{
                                  marginTop:7,
                                  fontSize: 29,
                                  fontFamily: 'zihun59hao-chuangcuhei',
                                  fontWeight: 400,
                                  color: '#333333'
                              }}>
                                  国内外资讯
                              </span>&nbsp;&nbsp;&nbsp;
                                <span style={{
                                    marginTop:18,
                                    fontSize: 19,
                                    fontFamily: 'zihun59hao-chuangcuhei',
                                    fontWeight: 400,
                                    color: '#00529C'
                                }}>
                                  Business Dynamics
                              </span>
                            </Col>
                            <Col span={4}>
                                <a href='/NewsDetail'>
                                    <p style={{
                                        marginTop:18,
                                        fontSize: 21,
                                        fontFamily: 'zihun59hao-chuangcuhei',
                                        fontWeight: 400,
                                        color: '#00529C'
                                    }}>
                                        更多
                                    </p>
                                </a>
                            </Col>
                            <Col span={24}>
                                <List
                                    itemLayout="vertical"
                                    size="large"
                                    dataSource={listData1}
                                    renderItem={item => (
                                        <List.Item>
                                            <Row>
                                                <Col span={4}>
                                                    <div style={{backgroundColor:'#b8c6e0',width:68,height:68,textAlign:'center'}}>
                                                        <p style={{
                                                            fontSize: 18,
                                                            fontFamily: 'zihun59hao-chuangcuhei',
                                                            fontWeight: 400,
                                                            color: '#FFFFFF'
                                                        }}>{moment(item.updatetime).format('YYYY')}</p>
                                                        <p style={{
                                                            marginTop:-14,
                                                            fontSize: 18,
                                                            fontFamily: 'zihun59hao-chuangcuhei',
                                                            fontWeight: 400,
                                                            color: '#FFFFFF'
                                                        }}>{moment(item.updatetime).format('MM-DD')}</p>
                                                    </div>
                                                </Col>
                                                <Col span={20}>
                                                    <Row>
                                                        <Col span={24}>
                                                            <a onClick={() =>detailed([item])}>
                                                                <p style={{
                                                                    marginTop:-15,
                                                                    fontSize: 21,
                                                                    fontFamily: 'zihun59hao-chuangcuhei',
                                                                    fontWeight: 400,
                                                                    color: '#555555'
                                                                }}>
                                                                    {item.title}
                                                                </p>
                                                            </a>
                                                        </Col>
                                                        {/*<Col span={24}>*/}
                                                            {/*<p style={{*/}
                                                                {/*marginTop:-10,*/}
                                                                {/*fontSize: 19,*/}
                                                                {/*fontFamily: 'zihun59hao-chuangcuhei',*/}
                                                                {/*fontWeight: 400,*/}
                                                                {/*color: '#999999'*/}
                                                            {/*}}>*/}
                                                                {/*<Ellipsis length={67} tooltip>{item.describes}</Ellipsis>*/}
                                                            {/*</p>*/}
                                                        {/*</Col>*/}
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </List.Item>
                                    )}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <img src={map11} alt={'map11'} style={{width:'95%'}} />
                        <img src={map12} alt={'map12'} style={{marginTop:25,width:'95%'}} />
                        <img src={map13} alt={'map13'} style={{marginTop:25,width:'95%'}} />
                    </Col>
                </Row><br/><br/>
            </Col>
        </Row>
    );
}

export default Case;
