import React, {useEffect, useState} from 'react';
import { Menu,Modal} from 'antd';
import { Link, useHistory } from 'react-router-dom';
import logo from '../../assets/material/imgs/logo1.png';
import routes from '../../config/routes';
import { LogoutOutlined } from '@ant-design/icons';
import { setAuthority } from '../../utils/authority';
import './index.css';

const { confirm } = Modal;
const { SubMenu } = Menu;

function Sidebar({setTabs, currentTabKey, setCurrentTabKey}) {
  const history = useHistory();
    const[menus,setMenus] =  useState([]);

    useEffect(async() => {
        await permissions(routes[9].children);
    }, []);

    //菜单权限设置
    const permissions = (data) =>{
        if (sessionStorage.getItem('account') !== 'jiangyu'&& sessionStorage.getItem('account') !== 'admin'){
            data.splice(1,1);
            setMenus(data);
        }else {
            setMenus(data);
        }
    };

  //退出登录
  const logins = () => {
    confirm({
      title: '您确定要退出登录吗？',
      okText: '确定',
      cancelText: '取消',
      onOk() {
        history.replace('/login');
        setAuthority('');
      },
      onCancel() {
        console.log('onCancel');
      },
    });
  };

  function handleClick(event) {
      history.replace(event.key);
      setCurrentTabKey(event.key);
  }

  const renderMenu = function(routes) {
    return (routes.map(({ name, path, icon, children }) => {
      if(children){
        return (
          <SubMenu key={path} icon={icon} title={name}>
            {
              renderMenu(children)
            }
          </SubMenu>
        )
      }
      return (
        <Menu.Item key={path} icon={icon}>
          <Link to={path}>{name}</Link>
        </Menu.Item>
      );
    }))
  };

  return (
    <div className={'menu-container'}>
      <div className="menu-header">
        <img src={logo} alt={'logo'} className={'logo'} />
      </div><br/>
      <Menu
        onClick={handleClick}
        selectedKeys={[currentTabKey]}
        defaultOpenKeys={[]}
        mode="inline"
        className="menu-headers"
      >
        {renderMenu(menus)}
      </Menu>
      <div className={'menu-footer'}>
        <div className="logout-btn" onClick={() => logins()}>
          <p>
            <LogoutOutlined className="logout-icon" />
            退出登录
          </p>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
