import React, {useEffect, useRef, useState} from 'react';
import { useHistory } from 'react-router-dom';
import './index.css';
import {Anchor, Col, Row} from "antd";
import background from "../../assets/material/background.png";
import map4 from "../../assets/material/Professional/map4.png";
import map5 from "../../assets/material/Professional/map5.png";
import map6 from "../../assets/material/Professional/map6.png";
import map7 from "../../assets/material/Professional/map7.png";
import map8 from "../../assets/material/Professional/map8.png";

import api from "../../services/ProfessionalDetail";


const Professional = () => {
    const history = useHistory();
    const [productNewss, setProductNewss] = useState([]);
    const [serviceNewss, setServiceNewss] = useState([]);

    useEffect(async() => {
        await selectProductNews("1",8);
        await selectServiceNews("2",8);
    }, []);

    //详细页面
    const detailed = (id) =>{
        //父页面传参
        history.replace({pathname:"/professionalDetail",state:{id:id}});
    };

    //产品
    const selectProductNews =async (type,count) =>{
       let res = await api.selectTypeNews({type:type,count:count});
        setProductNewss(res.data);
    };

    //服务
    const selectServiceNews =async (type,count) =>{
        let res = await api.selectTypeNews({type:type,count:count});
        setServiceNewss(res.data);
    };

    return (
        <Row>
            <Col span={24}>
                <img src={background} alt={'background'} style={{width:'100%'}} />
            </Col>
            <Col span={24}>
                <Anchor
                    affix={false}
                    style={{
                        marginTop:-160,
                        textAlign:'center',
                        backgroundColor: 'transparent'
                    }}
                >
                    <a href="#提供产品">
                        <span style={{fontSize: 40, fontFamily: 'FZXiaoBiaoSong-B05S', fontWeight: 400, color:'#FFFFFF'}}>提供产品</span>
                    </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a href="#提供服务">
                        <span style={{fontSize: 40, fontFamily: 'FZXiaoBiaoSong-B05S', fontWeight: 400, color:'#FFFFFF'}}>提供服务</span>
                    </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Anchor>
            </Col>
            <Col span={5} offset={10}>
                <div style={{
                    marginTop:85,
                    textAlign:'center',
                    background: 'linear-gradient(to left, #043add, #004B90) left bottom no-repeat,linear-gradient(to bottom, #004B90, #004B90) left bottom no-repeat,linear-gradient(to left, #004B90, #004B90) right top no-repeat,linear-gradient(to left, #004B90, #004B90) right top no-repeat',
                    backgroundSize: '0.25rem 2rem, 2rem 0.25rem'
                }} id='提供产品'>
                    <p className={'titles'}>
                        提供产品
                    </p>
                    <p className={'titles-English'}>
                        Beijing anti-enclave
                    </p>
                </div>
            </Col>
            <Col span={18} offset={3}>
                <Row gutter={[20, 56]} style={{marginTop:150}}>
                    {productNewss.map(({ id, title,  url,}) => (
                        <Col span={6}>
                            <a onClick={() =>detailed(id)}><img src={url} alt={'url'} style={{width:'100%'}} /></a>
                        </Col>
                    ))}
                    <Col span={6}>
                        <a ><img src={map4} alt={'map4'} style={{width:'100%'}} /></a>
                    </Col>

                    <Col span={6}>
                        <a  ><img src={map5} alt={'map5'} style={{width:'100%'}} /></a>
                    </Col>
                    <Col span={6}>
                        <a ><img src={map6} alt={'map6'} style={{width:'100%'}} /></a>
                    </Col>
                    <Col span={6}>
                        <a  ><img src={map7} alt={'map7'} style={{width:'100%'}} /></a>
                    </Col>
                    <Col span={6}>
                        <a  ><img src={map8} alt={'map8'} style={{width:'100%'}} /></a>
                    </Col>
                    <Col span={3}/>
                </Row><br/><br/><br/><br/>
            </Col>
            <Col span={5} offset={10}>
                <div style={{
                    marginTop:85,
                    textAlign:'center',
                    background: 'linear-gradient(to left, #043add, #004B90) left bottom no-repeat,linear-gradient(to bottom, #004B90, #004B90) left bottom no-repeat,linear-gradient(to left, #004B90, #004B90) right top no-repeat,linear-gradient(to left, #004B90, #004B90) right top no-repeat',
                    backgroundSize: '0.25rem 2rem, 2rem 0.25rem'
                }} id='提供服务'>
                    <p className={'titles'}>
                        提供服务
                    </p>
                    <p className={'titles-English'}>
                        Beijing anti-enclave
                    </p>
                </div>
            </Col>
            <Col span={18} offset={3}>
                <Row gutter={[20, 56]} style={{marginTop:150}}>
                    {serviceNewss.map(({ id, title,  url,}) => (
                        <Col span={24/serviceNewss.length}>
                            <a onClick={() =>detailed(id)}><img src={url} alt={'url'} style={{width:'100%'}} /></a>
                        </Col>
                    ))}
                </Row><br/><br/><br/><br/><br/><br/><br/>
            </Col>
        </Row>
    );
};
export default Professional;
