import { getFirstRoute, getPlainRoutes,getRouteRegexp } from './utils';
import routes from '../config/routes';

class MenuTabs {
  static key = 'menu-tabs';

  static getTabKeys() {
    const item = localStorage.getItem(this.key);
    return item ? item.split(',') : [];
  }

  static getTabs() {
    const routeList = getPlainRoutes(routes);
    const tabKeys = this.getTabKeys();
    const tabs = [];
    tabKeys.forEach((key) => {
      const tab = routeList.find((route) =>
        getRouteRegexp(route.path).test(key)
      );
      if (tab) {
        tabs.push({ ...tab, path: key });
      }
    });
    return tabs;
  }

  static active(tabKey) {
    const tabKeys = this.getTabKeys();
    if (tabKeys.includes(tabKey)) {
      return;
    }

    const routeList = getPlainRoutes(routes);
    const regexpList = routeList.map((route) => getRouteRegexp(route.path));
    const matchedRegexp = regexpList.find((regexp) => regexp.test(tabKey));
    if (!matchedRegexp) {
      return;
    }

    const matchedRouteIndex = tabKeys.findIndex((key) =>
      matchedRegexp.test(key)
    );
    if (matchedRouteIndex < 0) {
      tabKeys.unshift(tabKey);
    } else {
      tabKeys.splice(matchedRouteIndex, 1, tabKey);
    }

    localStorage.setItem(this.key, tabKeys.join());
  }

  static remove(removeTabKey, currentTabKey) {
    const tabKeys = this.getTabKeys();
    const removeIndex = tabKeys.indexOf(removeTabKey);
    tabKeys.splice(removeIndex, 1);
    localStorage.setItem(this.key, tabKeys.join());
    let newTabKey = '';
    if (removeTabKey === currentTabKey) {
      if (tabKeys.length > 0) {
        newTabKey = tabKeys[0]; // 简单一点 直接定位到第一个Tab
      } else {
        newTabKey = getFirstRoute(routes);
      }
    } else {
      newTabKey = currentTabKey;
    }
    const newTabs = this.getTabs();
    return { tabs: newTabs, tabKey: newTabKey };
  }

  static clear() {
    localStorage.setItem(this.key, '');
  }
}

export { MenuTabs };
