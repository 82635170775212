import About from "../pages/About";
import Technology from "../pages/Technology";
import LowCarbon from "../pages/LowCarbon";
import Professional from "../pages/Professional";
import ProfessionalDetail from "../pages/ProfessionalDetail";
import Electricity from "../pages/Electricity";
import LowCarbons from "../pages/LowCarbons";
import Carbon from "../pages/Carbon";
import Energys from "../pages/Energys";
import Build from "../pages/Build";
import Professionals from "../pages/Professionals";
import Case from "../pages/Case";
import Coopera from "../pages/Coopera";
import NewsDynamic from "../pages/NewsDynamic";
import NewsDetail from "../pages/NewsDetail";
import BackNews from '../pages/BackNews';
import NewsAudit from '../pages/NewsAudit';
import ProductServiceNews from '../pages/ProductServiceNews';


import AenterProject from '../pages/AenterProject';
import ToolRates from '../pages/ToolRates';
import CalibrationStandard from '../pages/CalibrationStandard';
import CalibrationStandarding from '../pages/CalibrationStandarding';
import DistributionTestStandard from '../pages/DistributionTestStandard';
import FaultChargeStandard from '../pages/FaultChargeStandard';
import GIS110KVStandard from '../pages/GIS110KVStandard';
import WaterOilStandard from '../pages/WaterOilStandard';
import MetalQualityStandard from '../pages/MetalQualityStandard';
import ChargingPileTestingStandard from '../pages/ChargingPileTestingStandard';
import EnvironmentalInspectionChargetandard from '../pages/EnvironmentalInspectionChargetandard';
import HengliTestingStandard from '../pages/HengliTestingStandard';
import ProductPublicity from '../pages/ProductPublicity';
import PublicizingProducts from '../pages/PublicizingProducts';

export default [
    {
        name: "首页",
        path: "/home",
        component: About,
    },
    {
        name: "技术创新",
        path: "/technology",
        component: Technology,
    },
    {
        name: "低碳产业园",
        path: "/carbon-industry",
        component: LowCarbon,
    },
    {
        name: "产品服务",
        path: "/professional",
        component: Professional,
    },
    {
        path: "/professionalDetail",
        component: ProfessionalDetail,
    },
    {
        name: "检测中心",
        path: "/detection",
        redirect: '/detection/electricity',
        children: [
            {
                name: "涉电检测",
                path: "/detection/electricity",
                component: Electricity,
            },
            {
                name: "低碳服务",
                path: "/detection/carbon-service",
                component: LowCarbons,
            },
            {
                name: "碳纤维",
                path: "/detection/carbons",
                component: Carbon,
            },
            {
                name: "新能源入网",
                path: "/detection/energy",
                component: Energys,
            },
            {
                name: "建筑结构",
                path: "/detection/build",
                component: Build,
            },
            {
                name: "职业健康",
                path: "/detection/healthy",
                component: Professionals,
            },
        ],
    },
    {
        name: "案例展示",
        path: "/case",
        component: Case,
    },
    {
        name: "合作伙伴",
        path: "/coopera",
        component: Coopera,
    },
    {
        path: "/newsDynamic",
        component: NewsDynamic,
    },
    {
        path: "/back-stage",
        children: [
            {
                name: "新闻管理",
                path: "/back-stage/news",
                component: BackNews,
            },
            {
                name: "新闻审核",
                path: "/back-stage/newsAudit",
                component: NewsAudit,
            },
            {
                name: '检测中心',
                path: '/back-stage/activity/activity1',
                children: [
                    {
                        name: '宣传展示',
                        path: '/back-stage/activity/publicizingProducts',
                        children: [
                            {
                                name: '电力检测',
                                path: '/back-stage/activity/publicizingProducts1',
                                component: PublicizingProducts,
                            },
                            {
                                name: '平展展示',
                                path: '/back-stage/activity/publicizingProducts2',
                            },
                            {
                                name: '低碳服务',
                                path: '/back-stage/activity/publicizingProducts3',
                            },
                            {
                                name: '建筑结构',
                                path: '/back-stage/activity/publicizingProducts4',
                            },
                            {
                                name: '碳纤维',
                                path: '/back-stage/activity/publicizingProducts5',
                            },
                            {
                                name: '职业健康',
                                path: '/back-stage/activity/publicizingProducts6',
                            },
                        ]
                    },
                    {
                        name: '产品展示',
                        path: '/back-stage/activity/productPublicity',
                        children: [
                            {
                                name: '企业资质',
                                path: '/back-stage/activity/productPublicity1',
                                component: ProductPublicity,
                            },
                            {
                                name: '资质附表',
                                path: '/back-stage/activity/hengliTestingStandard',
                                component: HengliTestingStandard,
                            },
                        ]
                    },
                    {
                        name: '项目库',
                        path: '/back-stage/activity/testingCenter',
                        component: AenterProject,
                    },
                    {
                        name: '报价单',
                        path: '/back-stage/activity/toolRates',
                        children: [
                            {
                                name: '普通工器具',
                                path: '/back-stage/activity/toolRates',
                                component: ToolRates,
                            },
                            {
                                name: '计量校准CNAS',
                                path: '/back-stage/activity/calibrationStandard',
                                component: CalibrationStandard,
                            },
                            {
                                name: '计量校准',
                                path: '/back-stage/activity/calibrationStandarding',
                                component: CalibrationStandarding,
                            },
                            {
                                name: '配电房预防性试验',
                                path: '/back-stage/activity/distributionTestStandard',
                                component: DistributionTestStandard,
                            },
                            {
                                name: '电缆震荡波故障',
                                path: '/back-stage/activity/faultChargeStandard',
                                component: FaultChargeStandard,
                            },
                            {
                                name: 'GIS检测及110kV',
                                path: '/back-stage/activity/gIS110KVStandard',
                                component: GIS110KVStandard,
                            },
                            {
                                name: '水质及绝缘油',
                                path: '/back-stage/activity/waterOilStandard',
                                component: WaterOilStandard,
                            },
                            {
                                name: '金属检测及质量抽检',
                                path: '/back-stage/activity/metalQualityStandard',
                                component: MetalQualityStandard,
                            },
                            {
                                name: '充电桩检测',
                                path: '/back-stage/activity/chargingPileTestingStandard',
                                component: ChargingPileTestingStandard,
                            },
                            {
                                name: '环境检测',
                                path: '/back-stage/activity/environmentalInspectionChargetandard',
                                component: EnvironmentalInspectionChargetandard,
                            },
                        ]
                    },
                ]
            },
            {
                name: "产品服务管理",
                path: "/back-stage/productServiceNews",
                component: ProductServiceNews,
            },

        ],
    },
    {
        path: "/NewsDetail",
        component: NewsDetail,
    },
];
