import request, { jsToFormData } from '../utils/request';
import { baseUrl } from '../utils/config';


//产品服务id
export async function selectTypeNews(payload) {
    return await request(baseUrl + '/api-ene/productServiceNews/'+payload.type+ '/news/'+payload.count, {
        method: 'GET',
    });
}

//产品服务id
export async function detail(payload) {
    return await request(baseUrl + '/api-ene/professionalDetail/detail', {
        method: 'POST',
        data:jsToFormData(payload),
    });
}

//产品服务id
export async function findById(payload) {
    return await request(baseUrl + '/api-ene/productServiceNews/news/'+payload.id, {
        method: 'GET',
    });
}

export default {
    detail,
    selectTypeNews,
    findById,
};
