import { PlusOutlined } from '@ant-design/icons';
import {Button,message, Modal, Radio} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import CreateForm from './components/CreateForm';
import api from '../../services/api';
import moment from 'moment';
import { DragSortTable } from '@ant-design/pro-table';
import Ellipsis from '../../components/Ellipsis';

const { confirm } = Modal;

/**
 * 添加节点
 * @param fields
 */
const handleAdd = async fields => {
    const hide = message.loading('正在新建');
    let data = "";
    fields.newsid.map((k, v) => {
        if (v === fields.newsid.length - 1){
            data = data + k
        } else {
            data = data + k+","
        }
    });
    try {
        await api.setEnergyAudit({classify:fields.classify,newsid:data});
        hide();
        message.success('新建成功');
        return true;
    } catch (error) {
        hide();
        message.error('新建失败请重试！');
        return false;
    }
};

const BackNews = () => {
    const [dataSource, setDatasource] = useState();
    const [newss, setNewss] = useState([]);
    const [createModalVisible, handleModalVisible] = useState(false);
    const[value,setValue] =  useState('1');
    const actionRef = useRef();
    const columns = [
        {
            key: 'sort',
            title: '排序',
            dataIndex: 'sort',
            hideInSearch: true
        },
        {
            key: 'title',
            title: '新闻标题',
            dataIndex: 'title',
            render: val =>  <Ellipsis length={20} tooltip>{val}</Ellipsis>,
        },
        {
            key: 'describes',
            title: '新闻描述',
            dataIndex: 'describes',
            hideInSearch: true,
            render: val =>  <Ellipsis length={35} tooltip>{val}</Ellipsis>,
        },
        {
            key: 'createtime',
            title: '创建时间',
            dataIndex: 'createtime',
            hideInSearch: true,
            render: val => <span>{moment(val).format('YYYY-MM-DD HH:mm:ss')}</span>,
        },
        {
            key: 'updatetime',
            title: '更新时间',
            dataIndex: 'updatetime',
            hideInSearch: true,
            render: val => <span>{moment(val).format('YYYY-MM-DD HH:mm:ss')}</span>,
        },
        {
            title: '操作',
            valueType: 'option',
            render: (_, record) => (
                <>
                    <a onClick={async () => {await handleRemove(record);}}>删除</a>
                </>
            ),
        },
    ];

    useEffect(async() => {
        await selectNews();
    }, []);

    //获取能源新闻
    const  selectNews  = async ()=>{
        let res = await api.selectNews({current:'1',pageSize:'100'});
        setNewss(res.data);
    };

    const optionsWithDisabled = [
        { label: '新闻排行', value: '1' },
        { label: '企业动态', value: '2' },
        { label: '国内外资讯', value: '3'},
    ];

    const onChange = e => {
        // console.log('radio checked', e.target.value);
        setValue(e.target.value);
    };

    // 列表排序
    const handleDragSortEnd = async(newDataSource) => {
        const hide = message.loading('正在修改列表排序');
        let data = "";
        newDataSource.map((k, v) => {
            if (v === newDataSource.length - 1){
                data = data + k.newsid
            } else {
                data = data + k.newsid+","
            }
        });
        try {
            await api.getEnergyAudit({newsid:data,classify:1});
            hide();
            setDatasource(newDataSource);
            message.success('修改列表排序成功');
            return true;
        } catch (error) {
            hide();
            message.error('修改列表排序失败！');
            return false;
        }
    };

    // 删除节点
    const handleRemove =  selectedRows => {
        confirm({
            title: '您确定要删除'+selectedRows.title+'吗？',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                deleteMethods(selectedRows);
            },
            onCancel() {
                console.log('onCancel');
            },
        });
    };

    // 删除节点方法
    const deleteMethods = async e => {
        const hide = message.loading('正在删除');
        try {
            await api.deleteEnergyAudit({newsid: e.newsid,classify:e.classify});
            hide();
            message.success('删除成功，即将刷新');
            actionRef.current.reload();
            return true;
        } catch (error) {
            hide();
            message.error('删除失败，请重试');
            return false;
        }
    };

    return (
        <PageHeaderWrapper>
            <DragSortTable
                actionRef={actionRef}
                search={true}
                options={true}
                pagination={false}
                rowKey="newsid"
                headerTitle={
                    <Radio.Group
                        options={optionsWithDisabled}
                        onChange={onChange}
                        value={value}
                        optionType="button"
                        buttonStyle="solid"
                    />
                }
                toolBarRender={() => [
                    <Button icon={<PlusOutlined />} type="primary" onClick={() => handleModalVisible(true)}>
                        新建
                    </Button>
                ]}
                dragSortKey="sort"
                onDragSortEnd={handleDragSortEnd}
                params={{classify:value,pageSize:6}}
                dataSource={dataSource}
                request={async params => {
                    const res = await api.selectEnergyAudit(params);
                    setDatasource(res.data);
                }}
                columns={columns}
            />
            <CreateForm
                onSubmit={async s => {
                    const success = await handleAdd({...s,classify:value});

                    if (success) {
                        handleModalVisible(false);

                        if (actionRef.current) {
                            actionRef.current.reload();
                        }
                    }
                }}
                onCancel={() => handleModalVisible(false)}
                modalVisible={createModalVisible}
                newss={newss}
            />
        </PageHeaderWrapper>
    );
};

export default BackNews;
