import React, {useEffect, useState} from 'react';
import {Col, Form, Input, message, Modal, Row, Select, Upload} from 'antd';
import 'moment/locale/zh-cn';
import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import {downloads} from '../../../utils/config';

const {Option} = Select;
const FormItem = Form.Item;
const {TextArea} = Input;


function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    // const isLt2M = file.size / 1024 / 1024 < 20;
    // if (!isLt2M) {
    //     message.error('Image must smaller than 2MB!');
    // }
    return isJpgOrPng;
    // return isJpgOrPng && isLt2M;
}


function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}


const CreateForm = props => {
  const [form] = Form.useForm();
  const {modalVisible, onSubmit: handleAdd, onCancel} = props;

  const [loading, setLoading] = useState(false);
  const [rImageUrl, setRImageUrl] = useState("");
  const [slImageUrl, setSlImageUrl] = useState("");

  useEffect(async() => {

  }, []);


  const okHandle = async () => {
    const fieldsValue = await form.validateFields();
    form.resetFields();
    handleAdd({...fieldsValue,rurl:rImageUrl,surl:slImageUrl});
  };


  const validateMessages = {
    required: '${label} 这是必须的填写的!',
    types: {
      email: '${label} 这不是一个有效邮箱!',
      number: '${label} 这不是一个有效数字!',
    },
    number: {
      range: '${label} 必须是 ${min}cm 和 ${max}cm之间',
    },
  };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    );

    const handleChangeR = info => {
        if (info.file.status === 'uploading') {
            setLoading(true)
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl =>{
                // console.log("imageUrl",info.file);
                setRImageUrl(info.file.response.url);
                setLoading(false);
              }
            );
        }
    };

    const handleChangeSl = info => {
        if (info.file.status === 'uploading') {
            setLoading(true)
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, imageUrl =>{
                    // console.log("imageUrl",info.file);
                    setSlImageUrl(info.file.response.url);
                    setLoading(false);
                }
            );
        }
    };



  return (
    <>
      <Modal
        destroyOnClose
        title="新建产品展示图片"
        visible={modalVisible}
        onOk={okHandle}
        onCancel={() => onCancel()}
        width={"60%"}
        okText="确认"
        cancelText="取消"
      >
        <Form form={form} validateMessages={validateMessages} preserve={false} initialValues={{ bz:"", }}>
            <Row  gutter={16}>
                <Col span={8}>
                    <FormItem
                        label="上传源图片"
                        name="rurl"
                    >
                        <Upload
                            action= {downloads}
                            listType="picture-card"
                            onChange={handleChangeR}
                            beforeUpload={beforeUpload}
                            showUploadList={false}
                            headers = {{
                                Authorization: localStorage.getItem('Authorization'),
                            }}
                        >
                            {rImageUrl ? <img src={rImageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                        </Upload>
                    </FormItem>
                </Col>
                <Col span={8}>
                    <FormItem
                        label="上传缩略图"
                        name="surl"
                    >
                        <Upload
                            action= {downloads}
                            listType="picture-card"
                            onChange={handleChangeSl}
                            beforeUpload={beforeUpload}
                            showUploadList={false}
                            headers = {{
                                Authorization: localStorage.getItem('Authorization'),
                            }}
                        >
                            {slImageUrl ? <img src={slImageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                        </Upload>
                    </FormItem>
                </Col>
            </Row>
            <Row  gutter={16}>
                <Col  span={8}>
                    <FormItem
                        label="图片名称名称"
                        name="name"
                    >
                        <Input placeholder="请输入"/>
                    </FormItem>
                </Col>
                <Col  span={8}>
                    <FormItem
                        label="排序"
                        name="sort"
                    >
                        <Input placeholder="请输入"/>
                    </FormItem>
                </Col>
            </Row>
          <Row>
            <Col  span={24}>
              <FormItem
                label="备&nbsp;&nbsp;&nbsp;注&nbsp;&nbsp;&nbsp;&nbsp;"
                name="bz"
              >
                <TextArea style={{ height: 80 }} placeholder="请输入"/>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CreateForm;
