import React from 'react';
import './index.less';
import {Col, Row, Menu} from "antd";
import { useHistory } from 'react-router-dom';
import background from "../../assets/material/background.png";
import map1 from "../../assets/material/Energys/map1.png";
import map2 from "../../assets/material/Energys/map2.png";
import map3 from "../../assets/material/Energys/map3.png";

function Detection() {
    const history = useHistory();

    //菜单栏点击
    const itemClick = (e) => {
        let k = parseInt(e.key);
        switch (k) {
            case 1://涉电检测
                history.replace('/detection/electricity');
                break;
            case 2://低碳服务
                history.replace('/detection/carbon-service');
                break;
            case 3://碳纤维
                history.replace('/detection/carbons');
                break;
            case 4://新能源入网
                history.replace('/detection/energy');
                break;
            case 5://建筑结构
                history.replace('/detection/build');
                break;
            case 6://职业健康
                history.replace('/detection/healthy');
                break;
            default:
                history.replace('/detection/electricity');
                break;
        }
    };

    return (
        <Row>
            <Col span={24}>
                <img src={background} alt={'background'} style={{width:'100%'}} />
            </Col>
            <Col span={24}>
                <Menu style={styles.menu} onClick={itemClick}>
                    <Menu.Item style={styles.menuItem} key={1}>涉电检测</Menu.Item>
                    <Menu.Item style={styles.menuItem} key={2}>低碳服务</Menu.Item>
                    <Menu.Item style={styles.menuItem} key={3}>碳纤维</Menu.Item>
                    <Menu.Item style={styles.menuItem} key={4}>新能源入网</Menu.Item>
                    <Menu.Item style={styles.menuItem} key={5}>建筑结构</Menu.Item>
                    <Menu.Item style={styles.menuItem} key={6}>职业健康</Menu.Item>
                </Menu>
            </Col>

            <Col span={18} offset={3}>
                <p style={{
                    marginTop:149,
                    fontSize: 21,
                    fontDamily: 'Microsoft YaHei',
                    fontWeight: 400,
                    color: '#333333',
                    textIndent:'2em'
                }}>
                    吉林东研检测技术有限公司是以大学为依托，由吉林东杰科技开发有限公司与广州恒力检测股份有限公司共同出资组建,是综合性第三方安全检测及配、售电一体化技术服务机构。东研检测专注于企业的用电安全及能效管理。业务涵盖电力高低压设备检测及维护、设备托管及智能运维、电力故障抢修、配电房安装改造、能效管理、智能配电房监测系统、电力安全工器具及劳保用品预防性试验、环境检测、仪器仪表校准、售电等业务。
                    <br/><br/>
                    东研检测与电力、新能源、输变电、智能电网等国家、省发改，教育部门实验室合作，对新能源入网检测标准制定、技术创新、人才培养等领域开展业务。
                </p>
            </Col>

            <Col span={22} offset={2} style={{marginTop:63}}>
                <p style={{marginLeft:'75px', fontSize: '42px', fontFamily: 'FZXiaoBiaoSong-B05S', fontWeight: '400', color: '#00529C'}}>
                    应用案例
                </p>
                <p style={{marginTop:-25,marginLeft:'77px', fontSize: '23px', fontFamily: 'Adobe Heiti Std', fontWeight: 'normal', color: '##292929'}}>
                    Application case
                </p>
                <div style={{marginLeft:'77px', width:'89px',height:'9px',background:'#004B90'}}/>
            </Col>
            <Col span={18} offset={3}>
                <Row gutter={[32,32]} style={{marginTop:46}}>
                    <Col span={12}>
                        <img src={map1} alt={'map1'} style={{width:'100%'}} />
                        <p style={{
                            marginTop:28,
                            fontSize: 21,
                            fontFamily: 'Microsoft YaHei',
                            fontWeight: 400,
                            color: '#333333'
                        }}>
                            上海电气10MW高低穿测试装置
                        </p>
                    </Col>
                    <Col span={12}>
                        <img src={map2} alt={'map2'} style={{width:'100%'}} />
                        <p style={{
                            marginTop:28,
                            fontSize: 21,
                            fontFamily: 'Microsoft YaHei',
                            fontWeight: 400,
                            color: '#333333'
                        }}>
                            广东明阳10MW高低穿测试装置
                        </p>
                    </Col>
                </Row>
            </Col>

            <Col span={22} offset={2} style={{marginTop:44}}>
                <p style={{marginLeft:'75px', fontSize: '42px', fontFamily: 'FZXiaoBiaoSong-B05S', fontWeight: '400', color: '#00529C'}}>
                    科研成果
                </p>
                <p style={{marginTop:-25,marginLeft:'77px', fontSize: '23px', fontFamily: 'Adobe Heiti Std', fontWeight: 'normal', color: '##292929'}}>
                    Scientific achievements
                </p>
                <div style={{marginLeft:'77px', width:'89px',height:'9px',background:'#004B90'}}/>
            </Col>
            <Col span={18} offset={3}>
                <p style={{
                    marginTop:25,
                    fontSize: 21,
                    fontFamily: 'Microsoft YaHei',
                    fontWeight: 400,
                    color: '#333333'
                }}>
                    电力系统安全运行与节能技术国家地方联合工程实验窣&nbsp;&nbsp;&nbsp;&nbsp;国家发改委<br/>
                    多能源互补高效供鲐管理技术工程实验室&nbsp;&nbsp;&nbsp;&nbsp;国家发改委<br/>
                    现代电力系统仿真控制与绿色电能新技术教育部重点文验室&nbsp;&nbsp;&nbsp;&nbsp;教育部<br/>
                    油页岩综合利用教育部工程研究中心&nbsp;&nbsp;&nbsp;&nbsp;教育部<br/>
                    能源高效洁净开发利用重大需求协同创新中心省教育厅&nbsp;&nbsp;&nbsp;&nbsp;省财政厅<br/>
                    新能源发电利用重大需求协同创新中心省教育厅&nbsp;&nbsp;&nbsp;&nbsp;省财政厅<br/>
                    热能利用系统节能重大需求协同创新中心〔立项培育）&nbsp;&nbsp;&nbsp;&nbsp;省帔育厅&nbsp;&nbsp;省财政厅<br/>
                    输变电工程安全技术重大需求协同创新中心(立项培育）&nbsp;&nbsp;&nbsp;&nbsp;省教育厅&nbsp;&nbsp;省财政厅<br/>
                    吉林省油页岩综合利用科技创新中心&nbsp;&nbsp;&nbsp;&nbsp;省科技厅<br/>
                    吉林省现代电力系统仿真控制与绿色电能新技术重点&nbsp;&nbsp;&nbsp;&nbsp;实验室&nbsp;&nbsp;省科技厅<br/>
                    “智能电网和新能源”成果转化平台&nbsp;&nbsp;&nbsp;&nbsp;省科技厅<br/>
                    吉林省火电机组节第减排工程技术研究中心&nbsp;&nbsp;&nbsp;&nbsp;省科技厅<br/>
                    吉林省电力大数据旨能处理工程技术研究中心&nbsp;&nbsp;&nbsp;&nbsp;省科技厅<br/>
                    吉林省生物质洁洁转化与高值化利用科技创新中心&nbsp;&nbsp;&nbsp;&nbsp;科技厅<br/>
                    靖密驱动智蜿控制国际联合研竞中心&nbsp;&nbsp;&nbsp;&nbsp;省科技厅<br/>
                    吉林省油页岩综合利用工程研充中心&nbsp;&nbsp;&nbsp;&nbsp;省发改委<br/>
                    吉林省新能源发电联网运行与控制技术工程研究中心&nbsp;&nbsp;&nbsp;&nbsp;省发改委<br/>
                    吉林省节能与测控技术工程实验空&nbsp;&nbsp;&nbsp;&nbsp;省发改委<br/>
                    吉林省输电工程安全技术工程实验室&nbsp;&nbsp;&nbsp;&nbsp;省发改委<br/>
                    吉林省智能电网信息技术工程实验室&nbsp;&nbsp;&nbsp;&nbsp;省发次委<br/>
                    吉林省新能瞭电网智能化运行与控制工程文验室&nbsp;&nbsp;&nbsp;&nbsp;省发改委<br/>
                    吉林省人工智能及舶源电力应用工程实验室&nbsp;&nbsp;&nbsp;&nbsp;省发改委<br/>
                    吉林省现代电力系统仿真控制与绿色电能新技术重点实验室&nbsp;&nbsp;&nbsp;&nbsp;省教育厅<br/>
                    吉林省油页岩综合利用工程研充中心&nbsp;&nbsp;&nbsp;&nbsp;省教育厅<br/>
                    吉林省电站水处理技术工程研究中心&nbsp;&nbsp;&nbsp;&nbsp;省教育厅<br/>
                    吉林省油页岩综合开发利用高姓科技创新平台省&nbsp;&nbsp;&nbsp;&nbsp;教育厅<br/>
                    吉林省电力储能与环保材料重点实验室&nbsp;&nbsp;&nbsp;&nbsp;省教育厅<br/>
                    能源互联网信息技术实验室&nbsp;&nbsp;&nbsp;&nbsp;省教育厅<br/>
                    能源大数据分析及智能计算重点实验室&nbsp;&nbsp;&nbsp;&nbsp;省教育厅<br/>
                    智慧能源先进控制技术实验室&nbsp;&nbsp;&nbsp;&nbsp;省教育厅<br/>
                    新能源产业吉林当校企联合技术创新实验室&nbsp;&nbsp;&nbsp;&nbsp;省教育厅&nbsp;&nbsp;省工信厅<br/>
                    吉林省电力电子产业公共技术研发中心&nbsp;&nbsp;&nbsp;&nbsp;省工信厅<br/>
                    吉林省金属成型模具产业公共技术研发中心&nbsp;&nbsp;&nbsp;&nbsp;省工信厅<br/>
                    吉林省能源经济发展战略研究中心(智库）&nbsp;&nbsp;&nbsp;&nbsp;省教育厅<br/>
                    能源经济研究中心(文科基地)&nbsp;&nbsp;&nbsp;&nbsp;省教育厅<br/>
                    吉林省能源发展研究基地〔文科基地）&nbsp;&nbsp;&nbsp;&nbsp;省哲社办<br/>
                </p>
            </Col>

            <Col span={22} offset={2} style={{marginTop:63}}>
                <p style={{marginLeft:'75px', fontSize: '42px', fontFamily: 'FZXiaoBiaoSong-B05S', fontWeight: '400', color: '#00529C'}}>
                    应用转换
                </p>
                <p style={{marginTop:-25,marginLeft:'77px', fontSize: '23px', fontFamily: 'Adobe Heiti Std', fontWeight: 'normal', color: '##292929'}}>
                    Application conversion
                </p>
                <div style={{marginLeft:'77px', width:'89px',height:'9px',background:'#004B90'}}/>
            </Col>
            <Col span={18} offset={3}>
                <Row gutter={[32, 32]} style={{marginTop:47}}>
                    <Col span={8}>
                        <img src={map3} alt={'map3'} style={{width:'100%'}} />
                        <p style={{
                            marginTop:33,
                            fontSize: 21,
                            fontFamily: 'Microsoft YaHei',
                            fontWeight: 400,
                            color: '#333333'
                        }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;中国南方电网有限责任公司与我校共建联合实验室签约仪式
                        </p>
                    </Col>
                    <Col span={16}>
                        <p style={{
                            fontSize: 21,
                            fontFamily: 'Microsoft YaHei',
                            fontWeight: 400,
                            color: '#333333'
                        }}>

                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;中国南方电网有限责任公司副总工程师、科技部主任余建国，海南电网有限责任公司副总经理殷健等一行20人光临我校，同我校就双方共建联合实验室举行签约仪式。学校党委书记李国庆，校长蔡国伟，副校长王建国，有关部门，学院负击人、师生代表参加了仪式，王建国副校长主持仪式。科技产业处秦力处长代表我校先后与海南电网公司科技部主任吴清、贵州电网公司科技部主任任勇、深圳供电局生产技术部到主任陈鹏签署合怍协议。
                            <br/><br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;拥有一大批高水平的专业人才队伍和科学研究平台，在电力以及相关的学科领域取得了丰硕的成果，赢得了各界和社会的普遍赞誉。
                            <br/><br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本次签约，不仅标志着我校与南方电网公司双方的合作开启了新的里程，也标志着我校校企合作又进入了一个新的发展阶段。协议的签署，必将为推动双方事业发展，携手并肩开创美好未来奠定更加坚实的基础和广园的平台。

                        </p>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
const styles = {
    menuItem: {border: 0, fontSize: 45, color: '#FFFFFF',display: 'inline',fontFamily:'FZXiaoBiaoSong-B05S',fontWeight: '400'},
    menu: {background: 'transparent',marginTop:-160,textAlign:'center'},
};

export default Detection;
