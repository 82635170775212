import request, { jsToFormData } from '../utils/request';
import { baseUrl } from '../utils/config';

//查询展示图片
export async function list(payload) {
    return await request(baseUrl + '/api-ene/txczstp/list', {
        method: 'POST',
        data: jsToFormData(payload),
    });
}

//保存展示图片
export async function save(payload) {
    return await request(baseUrl + '/api-ene/txczstp/save', {
        method: 'POST',
        data: payload.fields,
    });

}

//更新展示图片
export async function update(payload) {
    return await request(baseUrl + '/api-ene/txczstp/update', {
        method: 'POST',
        data: payload.fields,
    });
}

//删除展示图片
export async function del(payload) {
    return await request(baseUrl + '/api-ene/txczstp/del', {
        method: 'POST',
        data:jsToFormData(payload),
    });
}



//查询展示图片
export async function findAll() {
    return await request(baseUrl + '/api-ene/txczstp/findAll', {
        method: 'POST',
    });
}
export default {
    list,
    save,
    update,
    del,
    findAll,
};
