import React, { useEffect } from 'react';
import { Col, Form, Input, Modal, Row, Select } from 'antd';

const FormItem = Form.Item;
const { Option } = Select;
const {TextArea} = Input;




const UpdateForm = props => {

  const [form] = Form.useForm();
  const {
    onSubmit: handleUpdate,
    onCancel: handleUpdateModalVisible,
    updateModalVisible,
    values,
    jlxzcnasFlXx,
    xzclFlXx
  } = props;


  useEffect(async() => {
  }, []);


  const okHandle = async () => {
    const fieldsValue = await form.validateFields();
    form.resetFields();
    handleUpdate({...fieldsValue});
  };

  const validateMessages = {
    required: '${label} 这是必须的填写的!',
    types: {
      email: '${label} 这不是一个有效邮箱!',
      number: '${label} 这不是一个有效数字!',
    },
    number: {
      range: '${label} 必须是 ${min}cm 和 ${max}cm之间',
    },
  };



  return (
    <div>
      <Modal
        destroyOnClose
        title="查看计量校准收费标准表（已获CNAS资质项目）"
        visible={updateModalVisible}
        onCancel={() => handleUpdateModalVisible(false, values)}
        afterClose={() => handleUpdateModalVisible()}
        onOk={okHandle}
        width={"60%"}
        okText="确认"
        cancelText="取消"
        footer={null}
      >
        <Form
          form={form}
          initialValues={values}
          validateMessages={validateMessages} preserve={false}
        >

          <Row  gutter={16}>
            <Col  span={8}>
              <FormItem
                label="名&nbsp;&nbsp;&nbsp;称&nbsp;&nbsp;&nbsp;"
                name="mc"
              >
                <Input placeholder="请输入" disabled="disabled"/>
              </FormItem>
            </Col>
            <Col  span={8}>
              <FormItem
                label="单&nbsp;&nbsp;&nbsp;价&nbsp;&nbsp;&nbsp;"
                name="dj"
              >
                <Input  placeholder="请输入" disabled="disabled" />
              </FormItem>
            </Col>
            <Col  span={8}>
              <FormItem
                label="计量校准"
                name="xzflid"
              >
                <Select
                  allowClear={true}
                  showSearch
                  optionFilterProp="label"
                  placeholder="计量校准"
                  filterOption={(input, option) =>
                    option.children.toString().indexOf(input.toLowerCase()) >= 0
                  }
                  disabled="disabled"
                >
                  {
                    jlxzcnasFlXx.map(element =>
                      <Option key={element.id} value={element.id}> {element.mc}</Option>)
                  }
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col  span={8}>
              <FormItem
                label="校准参量"
                name="clid"
              >
                <Select
                  allowClear={true}
                  showSearch
                  optionFilterProp="label"
                  placeholder="校准参量"
                  filterOption={(input, option) =>
                    option.children.toString().indexOf(input.toLowerCase()) >= 0
                  }
                  disabled="disabled"
                >
                  {
                    xzclFlXx.map(element =>
                      <Option key={element.id} value={element.id}> {element.mc}</Option>)
                  }
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col  span={24}>
              <FormItem
                label="备&nbsp;&nbsp;&nbsp;注&nbsp;&nbsp;&nbsp;"
                name="bz"
              >
                <TextArea style={{ height: 80 }} placeholder="请输入" disabled="disabled" />
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default UpdateForm;
