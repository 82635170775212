import React, { useEffect, useRef, useState } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import CustomProTable from '../../components/CustomProTable';
import {Select,DatePicker,InputNumber,Row, Col } from 'antd';
import Ellipsis from '../../components/Ellipsis';
import api from '../../services/api';
import moment from 'moment';
import school from '../../services/school';

const { Option } = Select;
const { RangePicker } = DatePicker;

const TableList = () => {
  const[total,setTotal] =  useState([]);
  const[current,setCurrent] =  useState([]);
  const[pageSize,setPageSize] =  useState([]);
  const [sxx, setSxx] = useState([]);
  const [children, setChildren] = useState([]);
  const [tcategory, setTcategory] = useState([]);

  const actionRef = useRef();

  const  findAllProvince  = async ()=>{
    let res = await school.findAllProvince();
    setSxx(res.data);
  };

  const  getpermission  = async ()=>{
    let res = await api.selectpagOrganize();
    setChildren(res.data);
  };

  //获取项目类别
  const  getcategory  = async ()=>{
    let res = await api.getClassiSelectAll();
    setTcategory(res.data);
  };

  const columns = [
    {
      key: 'xh',
      title: '序号',
      dataIndex: 'xh',
      hideInSearch: true,
      render:(text, record, index) =>{
        //当前页数减1乘以每一页页数再加当前页序号+1
        return (current - 1) * (pageSize) + (index + 1);
      }
    },
    {
      key: 'ssmc',
      title: '区域',
      dataIndex: 'ssmc',
      renderFormItem: (item, { type, defaultRender, ...rest }, form) => {
        return (
          <Select
            placeholder="请选择区域"
            // onChange={handleSChange}
            allowClear={true}
            showSearch
            optionFilterProp="label"
            filterOption={(input, option) =>
              option.children.toString().indexOf(input.toLowerCase()) >= 0
            }
          >
            {
              sxx.map(element =>
                <Option key={element.id} value={element.id}> {element.mc}</Option>)
            }
          </Select>
        );
      },
    },
    {
      key: 'zzmc',
      title: '业主单位',
      dataIndex: 'zzmc',
      render: val =>  <Ellipsis length={9} tooltip>{val}</Ellipsis>,
      renderFormItem: (item, { type, defaultRender, ...rest }, form) => {
        return (
          <Select
            placeholder="请选择业主单位"
            allowClear={true}
            showSearch
            optionFilterProp="label"
            filterOption={(input, option) =>
              option.children.toString().indexOf(input.toLowerCase()) >= 0
            }
          >
            {
              children.map(element =>
                <Option key={element.id} value={element.id}> {element.mc}</Option>)
            }
          </Select>
        );
      },
    },
    {
      key: 'nf',
      title: '年份',
      dataIndex: 'nf',
      renderFormItem: (item, { type, defaultRender, ...rest }, form) => {
        return (
          <RangePicker picker="year"/>
        );
      },
    },
    {
      key: 'mc',
      title: '项目名称',
      dataIndex: 'mc',
      render: val =>  <Ellipsis length={30} tooltip>{val}</Ellipsis>,
    },
    {
      key: 'lbmc',
      title: '项目类别',
      dataIndex: 'lbmc',
      renderFormItem: (item, { type, defaultRender, ...rest }, form) => {
        return (
          <Select
            placeholder="请选择项目类别"
            allowClear={true}
            showSearch
            optionFilterProp="label"
            filterOption={(input, option) =>
              option.children.toString().indexOf(input.toLowerCase()) >= 0
            }
          >
            {
              tcategory.map(element =>
                <Option key={element.id} value={element.id}> {element.mc}</Option>)
            }
          </Select>
        );
      },
    },
    {
      key: 'htje',
      title: '合同金额',
      dataIndex: 'htje',
      hideInSearch: true,
    },
    {
      key: 'htjes1',
      title: '合同金额',
      dataIndex: 'htjes1',
      hideInTable: true,
      renderFormItem: (item, { type, defaultRender, ...rest }, form) => {

        return (
          <Row>
            <Col span={10}>
              <InputNumber style={{ width: '100%', }} />
            </Col>
            <Col span={4}>
              &nbsp;&nbsp;&nbsp;——
            </Col>
            <Col span={10}>
              <InputNumber style={{ width: '100%', }} />
            </Col>
          </Row>
        );
      },
    },
    {
      key: 'rqqj',
      title: '日期区间',
      dataIndex: 'rqqj',
      hideInTable: true,
      renderFormItem: (item, { type, defaultRender, ...rest }, form) => {
        return (
          <RangePicker placeholder={['签订时间','竣工时间']} />
        );
      },
    },
    {
      key: 'qdsj',
      title: '签订时间',
      dataIndex: 'qdsj',
      hideInSearch: true,
      render: val => <span>{val== "-" ? val: moment(val).format('YYYY-MM-DD')}</span>,
    },
    {
      key: 'jgsj',
      title: '竣工时间',
      dataIndex: 'jgsj',
      hideInSearch: true,
      render: val => <span>{val== "-" ? val: moment(val).format('YYYY-MM-DD')}</span>,
    },
  ];

  useEffect(async() => {
    await findAllProvince();
    await getpermission();
    await getcategory();
  }, []);

  return (
    <PageHeaderWrapper>
      <CustomProTable
        headerTitle={"查询列表（总共 "+total+" 条）"}
        actionRef={actionRef}
        search={true}
        options={true}
        rowKey="id"
        toolBarRender={(action, { selectedRows }) => []}
        request={async params => {
          const res =await api.getCenterProject(params);
          setTotal(res.total);
          setCurrent(res.current);
          setPageSize(res.pageSize);
          return res;
        }}
        columns={columns}
      />
    </PageHeaderWrapper>
  );
};

export default TableList;
