import React, { useEffect } from 'react';
import { Col, Form, Input, Modal, Row, Select } from 'antd';
import 'moment/locale/zh-cn';

const {Option} = Select;
const FormItem = Form.Item;
const {TextArea} = Input;


const CreateForm = props => {
    const [form] = Form.useForm();
    const {modalVisible, onSubmit: handleAdd, onCancel,newss} = props;

    useEffect(async() => {

    }, []);

    const okHandle = async () => {
        const fieldsValue = await form.validateFields();
        form.resetFields();
        handleAdd({...fieldsValue});
    };


    const validateMessages = {
        required: '${label} 这是必须的填写的!',
        types: {
            email: '${label} 这不是一个有效邮箱!',
            number: '${label} 这不是一个有效数字!',
        },
        number: {
            range: '${label} 必须是 ${min}cm 和 ${max}cm之间',
        },
    };


    return (
        <>
            <Modal
                destroyOnClose
                title="新建能源新闻"
                visible={modalVisible}
                onOk={okHandle}
                onCancel={() => onCancel()}
                width={"60%"}
                okText="确认"
                cancelText="取消"
            >
                <Form form={form} validateMessages={validateMessages} preserve={false} initialValues={{ bz:"", }}>
                    <Row  gutter={16}>
                        <Col  span={24}>
                            <FormItem
                                label="新闻标题"
                                name="newsid"
                                rules={[{ required: true, },]}
                            >
                                <Select
                                    mode="multiple"
                                    placeholder="请选择新闻标题"
                                    allowClear={true}
                                    showSearch
                                    optionFilterProp="label"
                                    filterOption={(input, option) =>
                                        option.children.toString().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        newss.map(element =>
                                            <Option key={element.id} value={element.id}> {element.title}</Option>)
                                    }
                                </Select>
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default CreateForm;
