import React, { useEffect } from 'react';
import { Col, Form, Input, Modal, Row, Select } from 'antd';
import 'moment/locale/zh-cn';

const {Option} = Select;
const FormItem = Form.Item;
const {TextArea} = Input;


const CreateForm = props => {
  const [form] = Form.useForm();
  const {modalVisible, onSubmit: handleAdd, onCancel,sfdwFlXx} = props;

  useEffect(async() => {

  }, []);


  const okHandle = async () => {
    const fieldsValue = await form.validateFields();
    form.resetFields();
    handleAdd({...fieldsValue});
  };


  const validateMessages = {
    required: '${label} 这是必须的填写的!',
    types: {
      email: '${label} 这不是一个有效邮箱!',
      number: '${label} 这不是一个有效数字!',
    },
    number: {
      range: '${label} 必须是 ${min}cm 和 ${max}cm之间',
    },
  };

  return (
    <>
      <Modal
        destroyOnClose
        title="新建配电房预防性试验收费标准"
        visible={modalVisible}
        onOk={okHandle}
        onCancel={() => onCancel()}
        width={"60%"}
        okText="确认"
        cancelText="取消"
      >
        <Form form={form} validateMessages={validateMessages} preserve={false} initialValues={{ bz:"", }}>
          <Row  gutter={16}>
            <Col  span={8}>
              <FormItem
                label="名称"
                name="mc"
              >
                <Input placeholder="请输入"/>
              </FormItem>
            </Col>
            <Col  span={8}>
              <FormItem
                label="电压等级"
                name="dydj"
              >
                <Input placeholder="请输入"/>
              </FormItem>
            </Col>
            <Col  span={8}>
              <FormItem
                label="单价"
                name="dj"
              >
                <Input  placeholder="请输入" />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col  span={8}>
              <FormItem
                label="单位名称"
                name="dwid"
              >
                <Select
                  allowClear={true}
                  showSearch
                  optionFilterProp="label"
                  placeholder="单位名称"
                  filterOption={(input, option) =>
                    option.children.toString().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    sfdwFlXx.map(element =>
                      <Option key={element.id} value={element.id}> {element.mc}</Option>)
                  }
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col  span={24}>
              <FormItem
                label="内容"
                name="nr"
              >
                <TextArea style={{ height: 80 }} placeholder="请输入"/>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col  span={24}>
              <FormItem
                label="备注"
                name="bz"
              >
                <TextArea style={{ height: 80 }} placeholder="请输入"/>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CreateForm;
