import React, { useEffect, useState } from 'react';
import {Col, Form, Input, message, Modal, Row, Select} from 'antd';
import UpdateModify from './UpdateModify';
import UploadFile from "./UploadFile";

const FormItem = Form.Item;
const {TextArea} = Input;
const { Option } = Select;

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}


const UpdateForm = props => {

    const [form] = Form.useForm();
    const {
        onSubmit: handleUpdate,
        onCancel: handleUpdateModalVisible,
        updateModalVisible,
        values,
        files,
    } = props;

    const [fwbxx, setFwbxx] = useState(values.js);
    const [fileList, setFileList] = useState(files);

    useEffect(async() => {
    }, []);

    const getFwb = async (val) => {
        setFwbxx(val);
    };
    const okHandle = async () => {
        const fieldsValue = await form.validateFields();
        form.resetFields();
        let tFjXxs = [];
        let urls = "";
        await fileList.forEach ( item => {

            if('response' in item){
                tFjXxs.push({
                    dz:item.response.url,
                    wjdx:item.response.size,
                    jmc:item.response.name,
                    xmc:item.response.id,
                    bz:"",
                });
                urls = urls + item.response.url;
            }else {
                tFjXxs.push({
                    dz:item.url,
                    wjdx:item.wjdx,
                    jmc:item.name,
                    xmc:item.xmc,
                    bz:"",
                });
                urls = urls + item.url;
            }
        });
        handleUpdate({
            ...fieldsValue,
            "id":values.id,
            "url":urls,
            "tFjXxs": tFjXxs,
            "content":fwbxx,
        });
    };

    const validateMessages = {
        required: '${label} 这是必须的填写的!',
        types: {
            email: '${label} 这不是一个有效邮箱!',
            number: '${label} 这不是一个有效数字!',
        },
        number: {
            range: '${label} 必须是 ${min}cm 和 ${max}cm之间',
        },
    };

    const getFileList = async (val) => {
        setFileList(val.fileList);
    };

    return (
        <div>
            <Modal
                destroyOnClose
                title="修改能源新闻"
                visible={updateModalVisible}
                onCancel={() => handleUpdateModalVisible(false, values)}
                afterClose={() => handleUpdateModalVisible()}
                onOk={okHandle}
                width={"60%"}
                okText="确认"
                cancelText="取消"
            >
                <Form
                    form={form}
                    initialValues={values}
                    validateMessages={validateMessages} preserve={false}
                >
                    <Row  gutter={16}>
                        <Col  span={12}>
                            <FormItem
                                label="新闻标题"
                                name="title"
                                rules={[{ required: true, },]}
                            >
                                <Input placeholder="请输入"/>
                            </FormItem>
                        </Col>
                        <Col  span={12}>
                            <FormItem
                                label="类型"
                                name="type"
                                rules={[{ required: true, },]}
                                defaultValue="1"
                            >

                                <Select
                                    allowClear
                                    value={"1"}
                                >
                                    <Option value="1">产品</Option>
                                    <Option value="2">服务</Option>
                                </Select>
                            </FormItem>
                        </Col>
                        <Col  span={12}>
                            <FormItem
                                label="编辑人"
                                name="editor"
                                rules={[{ required: true, },]}
                            >
                                <Input placeholder="请输入"/>
                            </FormItem>
                        </Col>
                        <Col  span={12}>
                            <FormItem
                                label="审核人"
                                name="auditor"
                                rules={[{ required: true, },]}
                            >
                                <Input placeholder="请输入"/>
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem
                                label="封面图片"
                            >
                                <UploadFile getFileList={getFileList} fileListInite={fileList}/>
                            </FormItem>
                        </Col>
                        <Col  span={24}>
                            <FormItem
                                label="新闻描述"
                                name="describes"
                            >
                                <TextArea style={{ height: 100 }} placeholder="请输入"/>
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <Form.Item label='新闻内容' >
                                <UpdateModify getFwb={getFwb}  editorContent={values.content}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    );
};

export default UpdateForm;
