import request, { jsToFormData } from '../utils/request';
import { baseUrl } from '../utils/config';

//查询普通工器具收费标准
export async function list(payload) {
    return await request(baseUrl + '/api-ene/tjczxjlxzcnassfxx/list', {
        method: 'POST',
        data: jsToFormData(payload),
    });
}

//保存普通工器具收费标准
export async function save(payload) {
    return await request(baseUrl + '/api-ene/tjczxjlxzcnassfxx/save', {
        method: 'POST',
        data: payload.fields,
    });

}

//更新普通工器具收费标准
export async function update(payload) {
    return await request(baseUrl + '/api-ene/tjczxjlxzcnassfxx/update', {
        method: 'POST',
        data: payload.fields,
    });
}

//删除普通工器具收费标准
export async function del(payload) {
    return await request(baseUrl + '/api-ene/tjczxjlxzcnassfxx/del', {
        method: 'POST',
        data:jsToFormData(payload),
    });
}


//计量校准分类
export async function findAllJlxzcnasFl() {
  return await request(baseUrl + '/api-ene/tjczxjlxzcnassfxx/findAllJlxzcnasFl', {
    method: 'POST',
  });
}

//校准参量分类
export async function findAllXzclFl() {
  return await request(baseUrl + '/api-ene/tjczxjlxzcnassfxx/findAllXzclFl', {
    method: 'POST',
  });
}


export default {
  list,
  save,
  update,
  del,
  findAllJlxzcnasFl,
  findAllXzclFl,

};
