import React, { useState } from 'react';
import ProTable from '@ant-design/pro-table';
import { Button, Tooltip } from 'antd';
import {
  UpOutlined ,
  DownOutlined ,
} from '@ant-design/icons';

export default function CustomProTable(props) {
  const [searchVisible, setSearchVisible] = useState(true);
  const [changeIcon, setChangeIcon] = useState(true);
  function handleSearchVisible() {
    setSearchVisible(!searchVisible);
    setTimeout(function () {
      setChangeIcon(!changeIcon);
    }, 50);

  }

  return (
    <ProTable
      {...props}
      className={!searchVisible && 'hide-search'}
      toolBarRender={(action, { selectedRows }) => [
        props.toolBarRender(action, { selectedRows }),
        <Tooltip  title={searchVisible ? '收起' : '展开'} onClick ={handleSearchVisible}  >
         {changeIcon ?  <UpOutlined  style={{marginLeft:12,fontSize:16}}/> : <DownOutlined style={{marginLeft:12,fontSize:16}}/>}
        </Tooltip>,

      ]}
    />
  );
}
