import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import api, {energyAuditSelect} from '../../services/api';
import {Row, Col, Button, Divider, List} from "antd";
import Ellipsis from '../../components/Ellipsis';
import map1 from '../../assets/material/About/map1.png';
import map2 from '../../assets/material/About/map2.png';
import map3 from '../../assets/material/About/map3.png';
import map4 from '../../assets/material/About/map4.png';
import map5 from '../../assets/material/About/map5.png';
import map6 from '../../assets/material/About/map6.png';
import map7 from '../../assets/material/About/map7.png';
import moment from "moment";


function About() {
    const history = useHistory();
    const [masterListData, setMasterListData] = useState();
    const [listData, setListData] = useState();

    useEffect(async() => {
        await energyAuditSelect();
    }, []);

    //获取能源新闻
    const  energyAuditSelect  = async ()=>{
        if (window.matchMedia("(max-width: 1600px)").matches){
            let res = await api.energyAuditSelect(1,5);
            await setMasterListData(res.data[0]);
            res.data.splice(0,1);
            await setListData(res.data);
        }else {
            let res = await api.energyAuditSelect(1,6);
            await setMasterListData(res.data[0]);
            res.data.splice(0,1);
            await setListData(res.data);
        }
    };

    //详细页面
    const detailed = (selectData) =>{
        //父页面传参
        history.replace({pathname:"/NewsDetail",state:selectData[0]});
    };

    return (
        <Row>
            <Col span={22} offset={2} style={{marginTop:79}}>
                <p style={{marginLeft:'75px', fontSize: '42px', fontFamily: 'FZXiaoBiaoSong-B05S', fontWeight: '400', color: '#00529C'}}>
                    低碳产业研究院
                </p>
                {/*<img src="https://servers.zt.tang-jet.com/api-f/statics/2022/03/31/75460ec19a86ffe63f459ae8cc65189f.jpeg" alt={'map2'} style={{width:'90%'}} />*/}
                <p style={{marginTop:-25,marginLeft:'77px', fontSize: '23px', fontFamily: 'Adobe Heiti Std', fontWeight: 'normal', color: '#292929'}}>
                    North Urban Green Low Carbon Industry Research Institute
                </p>
                <div style={{marginLeft:'77px', width:'89px',height:'9px',background:'#004B90'}}/>
            </Col>
            <Col  xl={{ span:12, offset:2 }} xxl={{ span:12, offset:2 }}>
                <p style={{marginLeft:75,marginTop:27}}>
                    <img src={map1} alt={'map1'} style={{width:'100%'}} />
                </p>
            </Col>
            <Col xl={8} xxl={8}>
                <p style={{marginLeft:23,marginTop:27, fontSize:19, color:'#595959', fontFamily:'Microsoft YaHei', fontWeight: 400}}>
                    发展城市绿色低碳产业， 是实现碳达峰和碳中和战略目标的关键核心，<br/>
                    建立一条具有吉林特色的城市绿色低碳产业发展主线，<br/>
                    形成技术创新生态和产业创新生态双轮驱动体系。
                </p>
                <Row gutter={2} style={{marginLeft:23}}>
                    <Col span={6} style={{textAlign:'center'}} >
                        {/*<img src={map2} alt={'map2'} style={{width:'90%',border:'2px dashed #A9A9A9'}} />*/}
                        <img src={map2} alt={'map2'} style={{width:'90%'}} />
                        <p style={{fontSize: 24, fontFamily:'Microsoft YaHei', fontWeight: 400, color:'#00529C'}}>引进</p>
                    </Col>
                    <Col span={6} style={{textAlign:'center'}} >
                        <img src={map3} alt={'map3'} style={{width:'90%'}} />
                        <p style={{fontSize: 24, fontFamily:'Microsoft YaHei', fontWeight: 400, color:'#00529C'}}>孵化</p>
                    </Col>
                    <Col span={6} style={{textAlign:'center'}} >
                        <img src={map4} alt={'map4'} style={{width:'90%'}} />
                        <p style={{fontSize: 24, fontFamily:'Microsoft YaHei', fontWeight: 400, color:'#00529C'}}>产业化</p>
                    </Col>
                    <Col span={6} style={{textAlign:'center'}} >
                        <img src={map5} alt={'map5'} style={{width:'90%'}} />
                        <p style={{fontSize: 24, fontFamily:'Microsoft YaHei', fontWeight: 400, color:'#00529C'}}>推广</p>
                    </Col>
                    <Col span={24}>
                        <Button type="primary" style={{marginTop:12,fontSize:27,backgroundColor: '#00529C',width:181.2,height:49.8}}>查看更多</Button>
                    </Col>
                </Row>
            </Col>

            <Col span={22} offset={2} style={{marginTop:79}}>
                <p style={{marginLeft:'75px', fontSize: '42px', fontFamily: 'FZXiaoBiaoSong-B05S', fontWeight: '400', color: '#00529C'}}>
                    新闻中心
                </p>
                <p style={{marginTop:-25,marginLeft:'77px', fontSize: '23px', fontFamily: 'Adobe Heiti Std', fontWeight: 'normal', color: '##292929'}}>
                    North Urban Green Low Carbon Industry Research Institute
                </p>
                <div style={{marginLeft:'77px', width:'89px',height:'9px',background:'#004B90'}}/>
            </Col>
            <Col xl={{ span:11, offset:2 }} xxl={{ span:11, offset:2 }}>

                <a onClick={() =>detailed([masterListData])}>
                    <p style={{marginLeft:75,marginTop:27}}>
                        <img src={masterListData != undefined ? masterListData.url : map6} alt={'map6'} style={{width:'93%'}} />
                    </p>
                    <Row style={{marginLeft:75,marginTop:25}}>
                        <Col lg={19} xl={18} xxl={19}>
                            <p style={{
                                fontSize: 32,
                                fontFamily:'Microsoft YaHei',
                                fontWeight:'bold',
                                color:'#333333'
                            }}>

                                <Ellipsis length={18} tooltip>
                                    {masterListData != undefined ? masterListData.title : "今日热门事件"}
                                </Ellipsis>
                            </p>
                        </Col>
                        <Col lg={4} xl={6} xxl={4}>
                            <p style={{
                                fontSize:19,
                                fontFamily:'Microsoft YaHei',
                                fontWeight: 'bold',
                                color:'#999999',
                                marginTop:20
                            }}>
                                {masterListData != undefined ? moment(masterListData.updatetime).format('YYYY-MM-DD') : "2021-01-01"}
                            </p>
                        </Col>
                        <Col span={22}>
                            <p style={{
                                fontSize: 19,
                                fontFamily: 'Microsoft YaHei',
                                fontWeight: 400,
                                color: '#333333',
                                marginTop:12
                            }}>
                                <Ellipsis length={120} tooltip>
                                    {masterListData != undefined ? masterListData.describes : ""}
                                </Ellipsis>
                            </p>
                        </Col>
                    </Row>
                </a>


            </Col>
            <Col xl={8} xxl={8}>
                <Row>
                    <Col span={20}>
                        <p style={{
                            fontSize: 32,
                            fontFamily: 'Microsoft YaHei',
                            fontWeight: 'bold',
                            color: '#333333'
                        }}>
                            新闻排行
                        </p>
                    </Col>
                    <Col span={4}>
                        <div style={{marginTop:8}}>
                            <a
                                href='/newsDynamic'
                                style={{
                                    fontSize: 19,
                                    fontFamily: 'Microsoft YaHei',
                                    fontWeight: 'bold',
                                    color: '#666666'
                                }}>查看更多</a></div>

                    </Col>
                    <Col span={24}>
                        <Divider style={{marginTop:-20}} />
                    </Col>
                    <Col span={24}>
                        <div style={{marginTop:-20}}>
                            <List
                                itemLayout="vertical"
                                size="large"
                                dataSource={listData}
                                renderItem={item => (
                                    <List.Item
                                        key={item.title}
                                    >
                                        <Row>
                                            <Col span={9}>
                                                <img
                                                    width="95%"
                                                    alt="map7"
                                                    src={item.url != null ? item.url : map7}
                                                />
                                            </Col>
                                            <Col span={15}>
                                                <a onClick={() =>detailed([item])}>
                                                    <p style={{
                                                        fontSize: 27,
                                                        fontFamily:'Microsoft YaHei',
                                                        fontWeight: 'bold',
                                                        color: '#333333'
                                                    }}>
                                                        {item.title}
                                                    </p>
                                                </a>
                                                <p style={{
                                                    fontSize: 19,
                                                    fontFamily:'Microsoft YaHei',
                                                    fontWeight: 400,
                                                    color: '#333333'
                                                }}>{item.description}</p>
                                            </Col>
                                        </Row>
                                    </List.Item>
                                )}
                            />
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default About;
