export function getPlainRoutes(routes) {
  const arr = [];
  routes.forEach((route) => {
    if (route.children) {
      arr.push(...getPlainRoutes(route.children));
    } else {
      arr.push(route);
    }
  });
  return arr;
}

export function getFirstRoute(routes) {
  let route = '';
  if (routes.length > 0) {
    if (routes[0].children) {
      route = getFirstRoute(routes[0].children);
    } else {
      route = routes[0].path;
    }
  }
  return route;
}

// /user/:id -> /users\/\w+/
// '/users/:id/school/:schoolId' -> /users\/\w+/school\/\w+/
export function getRouteRegexp(route){
  const pattern = route.replace(/:\w*/g, '\\w+');
  return new RegExp('^' + pattern + '$')
}