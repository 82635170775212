import React from 'react';
import './index.less';
import {Col, Row, Menu} from "antd";
import { useHistory } from 'react-router-dom';
import background from "../../assets/material/background.png";
import map1 from "../../assets/material/Build/map1.png";
import map2 from "../../assets/material/Build/map2.png";

function Detection() {
    const history = useHistory();

    //菜单栏点击
    const itemClick = (e) => {
        let k = parseInt(e.key);
        switch (k) {
            case 1://涉电检测
                history.replace('/detection/electricity');
                break;
            case 2://低碳服务
                history.replace('/detection/carbon-service');
                break;
            case 3://碳纤维
                history.replace('/detection/carbons');
                break;
            case 4://新能源入网
                history.replace('/detection/energy');
                break;
            case 5://建筑结构
                history.replace('/detection/build');
                break;
            case 6://职业健康
                history.replace('/detection/healthy');
                break;
            default:
                history.replace('/detection/electricity');
                break;
        }
    };

    return (
        <Row>
            <Col span={24}>
                <img src={background} alt={'background'} style={{width:'100%'}} />
            </Col>
            <Col span={24}>
                <Menu style={styles.menu} onClick={itemClick}>
                    <Menu.Item style={styles.menuItem} key={1}>涉电检测</Menu.Item>
                    <Menu.Item style={styles.menuItem} key={2}>低碳服务</Menu.Item>
                    <Menu.Item style={styles.menuItem} key={3}>碳纤维</Menu.Item>
                    <Menu.Item style={styles.menuItem} key={4}>新能源入网</Menu.Item>
                    <Menu.Item style={styles.menuItem} key={5}>建筑结构</Menu.Item>
                    <Menu.Item style={styles.menuItem} key={6}>职业健康</Menu.Item>
                </Menu>
            </Col>

            <Col span={18} offset={3}>
                <p style={{
                    marginTop:149,
                    fontSize: 21,
                    fontDamily: 'Microsoft YaHei',
                    fontWeight: 400,
                    color: '#333333'
                }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;吉林东研检测技术有限公司吉林东研检测技术有限公司是大学为依托，由吉林东杰科技开发有限公司与广州恒力检测股份有限公司共同出资组建,是综合性第三方安全检测及配、售电一体化技术服务机构。东研检测专注于企业的用电安全及能效管理。业务涵盖电力高低压设备检测及维护、设备托管及智能运维、电力故障抢修、配电房安装改造、能效管理、智能配电房监测系统、电力安全工器具及劳保用品预防性试验．环境检测、仪器仪表校准、售电等业务。东研检测与大学“钢筋混凝土早龄期抗裂及相变性能研究”、“现浇混凝土结构施工缝受力性能和施工方法研究”等国家自然科学基金和省、部级课题研究组织深度合作。
                </p>
            </Col>

            <Col span={22} offset={2} style={{marginTop:63}}>
                <p style={{marginLeft:'75px', fontSize: '42px', fontFamily: 'FZXiaoBiaoSong-B05S', fontWeight: '400', color: '#00529C'}}>
                    成果案例
                </p>
                <p style={{marginTop:-25,marginLeft:'77px', fontSize: '23px', fontFamily: 'Adobe Heiti Std', fontWeight: 'normal', color: '##292929'}}>
                    Results cases
                </p>
                <div style={{marginLeft:'77px', width:'89px',height:'9px',background:'#004B90'}}/>
            </Col>
            <Col span={18} offset={3}>
                <img src={map1} alt={'map1'} style={{width:'100%',marginTop:34}} />
            </Col>

            <Col span={22} offset={2} style={{marginTop:44}}>
                <p style={{marginLeft:'75px', fontSize: '42px', fontFamily: 'FZXiaoBiaoSong-B05S', fontWeight: '400', color: '#00529C'}}>
                    科研成果转换项目
                </p>
                <p style={{marginTop:-25,marginLeft:'77px', fontSize: '23px', fontFamily: 'Adobe Heiti Std', fontWeight: 'normal', color: '##292929'}}>
                    cientific Research Achievement conversion project
                </p>
                <div style={{marginLeft:'77px', width:'89px',height:'9px',background:'#004B90'}}/>
            </Col>
            <Col span={18} offset={3}>
                <img src={map2} alt={'map2'} style={{width:'100%',marginTop:43}} />
            </Col>

            <Col span={22} offset={2} style={{marginTop:63}}>
                <p style={{marginLeft:'75px', fontSize: '42px', fontFamily: 'FZXiaoBiaoSong-B05S', fontWeight: '400', color: '#00529C'}}>
                    应用转换
                </p>
                <p style={{marginTop:-25,marginLeft:'77px', fontSize: '23px', fontFamily: 'Adobe Heiti Std', fontWeight: 'normal', color: '##292929'}}>
                    Application conversion
                </p>
                <div style={{marginLeft:'77px', width:'89px',height:'9px',background:'#004B90'}}/>
            </Col>
            <Col span={18} offset={3}>
                <p style={{
                    marginTop:25,
                    fontSize: 21,
                    fontFamily: 'Microsoft YaHei',
                    fontWeight: 400,
                    color: '#333333'
                }}>
                    吉林省科学技术进步奖高压输电线路设计关键技术研究与工程应用鞠彦忠2018一等奖<br/>
                    吉林省科学技术进步奖高压输电线路工程铁塔损伤评估与加固技术研究刘春诚2017C等奖<br/>
                    吉林省科学技术进步奖复合加载模式下输电线路基础承载特性研究郝冬雪2017E等奖<br/>
                    吉林省科学技术进步奖电网设备灾变抢修施工全过程模拟及可靠性评估祝贺2015一等奖<br/>
                    吉林省科学技术进步奖国定化好氧反硝化菌高效处理废水的理论与应用研究乔楠2015C等奖<br/>
                    吉林省科学技术进步奖碳纤维复合芯导线的力学性能与关键技术应用研究刘春城2014 C等奖<br/>
                    吉林省科学技术进步奖输电线路抗冰灾研究与应用鞠彦忠2014一等奖<br/>
                    吉林省科学技术进步奖现浇混凝土结构施工缝受力性能和施工方法研究魏春明2014 E等奖<br/>
                    吉林省科学技术进步奖钢筋混凝士早龄期抗裂及相变性能研究侯景2013 E等奖<br/>
                    吉林省科学技术进步奖高压脉冲放电耦合溶气气浮除污染工艺杨世东2013 E等奖<br/>
                    吉林省科学技术进步奖极端气象条件下输变电结构体系减灾仿真及损伤评估祝贺2012E等奖<br/>
                    吉林省科学技术进步奖线路冰致灾变机理及输电塔健康监测关键技术研究与刘春城2012 c等奖<br/>
                    吉林省科学技术进步奖高耐久性高强活性粉末混凝土电杆的开发与应用鞠彦忠.2011一等奖<br/>
                    吉林省科学技术进步奖力墙抗侧移刚度优化设计理论及适宜高度的研究与应秦力2011一等奖<br/>
                    吉林省科学技术进步奖混凝土自锚式悬索桥的力学行为及关键技术问题研究刘春城2009 E等奖<br/>
                    国家科技部“集成自主探测器的分析型工业热像仪开发和应用”任务九--建筑领域的红外热像检测分析应用<br/>
                    国家自然科学基金U形RPC预制梁外壳装配整体式框架结构性能与设计研究鞫彦忠<br/>
                    国家自然科学基金新型大矢高气承式膜结构风致灾害机理及抗风设计方法研究陈昭庆<br/>
                    国家自然科学基金三维复杂海岸地形条件下的波浪非线性特征研究陈洪洲<br/>
                    国家自然科学基金基于能量一致积分方法的混合试验研究潘天林<br/>
                    国家自然科学基金基于分形几何理论的再生混凝土细观损伤及破坏机理研究商效璃<br/>
                    国家自然科学基金混杂纤维对高性能混凝土隧道管片火灾高温后性能影响及改善机理研究宁喜亮<br/>
                    国家自然科学基金基于A/O生物脱氦系统微生物群落结构与功能解析的N20逸散机制研究郭静波<br/>
                    国家自然科学基金核电厂钢板混凝士结构平面内受剪机理试验研究张有佳<br/>
                    国家自然科学基金魏春明高强钢筋高强砼空间节点抗震性能与设计方法研究<br/>
                    国家自然科学基金土工格棚在季节性冻土中的加筋机理及长期工作特性研究陈格<br/>
                    国家自然科学基金活性粉末混凝土梁柱节点抗震性能试验研究鞠彦忠<br/>
                    国家自然科学基金复合加载模式下输电线路掏挖基础地基破坏模式及承载特性研究郝冬雪<br/>
                    国家自然科学基金考虑测试及建模不确定性的桥梁损伤诊断数据融合推理方法研究侯立群<br/>
                    国家自然科学基金基于多尺度分析的高压输电铁塔状态评估基础理论和方法研究刘春城<br/>
                    国家自然科学基金非理想相变控温混凝土温控机理研究史巍<br/>
                    国家自然科学基金大跨越高压输电塔-线体系覆冰断线的冲击动力学理论与数值模拟研究刘春城<br/>
                    国家自然科学基金高强砼不同强度梁柱节点抗震性能研究魏春明<br/>
                    国家白然科学基金活性粉末混凝土(RPC)柱的抗震性能实验与设计研究鞠彦忠<br/><br/><br/><br/><br/>
                </p>
            </Col>
        </Row>
    );
}
const styles = {
    menuItem: {border: 0, fontSize: 45, color: '#FFFFFF',display: 'inline',fontFamily:'FZXiaoBiaoSong-B05S',fontWeight: '400'},
    menu: {background: 'transparent',marginTop:-160,textAlign:'center'},
};

export default Detection;
