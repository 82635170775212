import request, { jsToFormData } from '../utils/request';
import { baseUrl } from '../utils/config';

//金属检测及质量抽检收费标准
export async function list(payload) {
    return await request(baseUrl + '/api-ene/tjczxcdzjcsfxx/list', {
        method: 'POST',
        data: jsToFormData(payload),
    });
}

//保存金属检测及质量抽检收费标准
export async function save(payload) {
    return await request(baseUrl + '/api-ene/tjczxcdzjcsfxx/save', {
        method: 'POST',
        data: payload.fields,
    });

}

//更新金属检测及质量抽检收费标准
export async function update(payload) {
    return await request(baseUrl + '/api-ene/tjczxcdzjcsfxx/update', {
        method: 'POST',
        data: payload.fields,
    });
}

//删除金属检测及质量抽检收费标准
export async function del(payload) {
    return await request(baseUrl + '/api-ene/tjczxcdzjcsfxx/del', {
        method: 'POST',
        data:jsToFormData(payload),
    });
}


//检测对象分类
export async function findAllFl() {
  return await request(baseUrl + '/api-ene/tjczxcdzjcsfxx/findAllFl', {
    method: 'POST',
  });
}


export default {
  list,
  save,
  update,
  del,
  findAllFl,
};
