import React, { useEffect } from 'react';
import {Row, Col} from "antd";
import {colors} from "../../../assets/source/colors";
import {BankOutlined , PhoneOutlined, PrinterOutlined  } from '@ant-design/icons';
import './Footer.css'

const Footer = props => {

    useEffect(() => {
    }, []);

    return (
        <Row>
            <Col span={24}>
                <div className={"bottom-line"}>
                    <Row style={{paddingTop:38}}>
                        <Col span={8} offset={4}>
                            <div style={{paddingLeft:46}}>
                                <div style={{fontSize:22,color:'#ffffff'}}>我们的服务</div>
                                <Row gutter={24} style={{paddingTop:29}}>
                                    <Col span={6}>
                                        <div className={"bottomFont1"}>·&nbsp;&nbsp;规划设计</div>
                                        <div className={"bottomFont1"}>&nbsp;&nbsp;----------------</div>
                                    </Col>
                                    <Col span={6}>
                                        <div className={"bottomFonts1"}>·&nbsp;&nbsp;政策对接</div>
                                        <div className={"bottomFonts1"}>&nbsp;&nbsp;----------------</div>
                                    </Col>
                                    <Col span={6}>
                                        <div className={"bottomFonts1"}>·&nbsp;&nbsp;招商引咨</div>
                                        <div className={"bottomFonts1"}>&nbsp;&nbsp;----------------</div>
                                    </Col>
                                    <Col span={6}>
                                        <div className={"bottomFonts1"}>·&nbsp;&nbsp;产业示范</div>
                                        <div className={"bottomFonts1"}>&nbsp;&nbsp;----------------</div>
                                    </Col>
                                    <Col span={6}>
                                        <div className={"bottomFonts1"}>·&nbsp;&nbsp;运营服务</div>
                                        <div className={"bottomFonts1"}>&nbsp;&nbsp;----------------</div>
                                    </Col>
                                    <Col span={6}>
                                        <div className={"bottomFonts1"}>·&nbsp;&nbsp;成果转化</div>
                                        <div className={"bottomFonts1"}>&nbsp;&nbsp;----------------</div>
                                    </Col>
                                    <Col span={6}>
                                        <div className={"bottomFonts1"}>·&nbsp;&nbsp;平台开发</div>
                                        <div className={"bottomFonts1"}>&nbsp;&nbsp;----------------</div>
                                    </Col>
                                    <Col span={6}>
                                        <div className={"bottomFonts1"}>·&nbsp;&nbsp;产业链</div>
                                        <div className={"bottomFonts1"}>&nbsp;&nbsp;----------------</div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col span={6} offset={2}>
                            <div style={{paddingLeft:50}}>
                                <div style={{fontSize:22,color:'#ffffff'}}>联系我们</div>

                                <Row style={{paddingTop:29}}>
                                    <Col span={24}>
                                        <BankOutlined style={styles.bottomIcon}/>&nbsp;&nbsp;&nbsp;&nbsp;
                                        <span className={"bottomFonts1"}>吉林市船营区长春路153号东北电力大学国家科技大厦8层</span>
                                    </Col>
                                    <Col span={24}>
                                        <PhoneOutlined style={styles.bottomIcon}/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <span className={"bottomFonts1"}>0432-62291000</span>
                                    </Col>
                                    <Col span={24}>
                                        <PrinterOutlined  style={styles.bottomIcon}/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <span className={"bottomFonts1"}>0432-62292000</span>
                                    </Col>
                                    <Col span={24} style={{paddingTop:28}}>
                                       <div className={"bottomFont1"}>——————————————————————————</div>
                                    </Col>
                                    <Col span={24} style={{paddingTop:22}}>

                                        <div className={"bottomFont2"}>Copyright ©  东杰科技开发有限公司 2018-2021</div>
                                        <span className={"bottomFont2"}>
                                            <a
                                                style={{color:"#FFF"}}
                                                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=22021102000171"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                吉公网安备22021102000171号
                                            </a>
                                        </span>&nbsp;&nbsp;&nbsp;
                                        <span className={"bottomFont2"}>吉ICP备14002262号-8</span>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>

            </Col>
        </Row>
    );
};
const styles = {
    bottomIcon: {fontSize: 18, color: colors.white, paddingTop: 15},
    img: {background: colors.white, height: 120, width: 120, marginBottom: 10},
    imgBody: {marginTop: 20},

}

export default Footer;
