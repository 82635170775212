import React, { useState } from "react";
import logo from "../../../assets/material/logo.png";
import styles from "./Header.module.css";
import routes from "../../../config/routes";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";

function HeaderReconstruct() {
  const [currentIndex, setCurrentIndex] = useState(null);
  const pathname = window.location.pathname;
  function Menu() {
    return (
      <>
        {routes.map(({ name, path, redirect }, index) => {
          const className = pathname.includes(path) ? "active" : "";
          return (
            <Link
              key={path}
              to={redirect || path}
              className={styles[className]}
              onMouseEnter={() => setCurrentIndex(index)}
            >
              {name}
            </Link>
          );
        })}
      </>
    );
  }

  function SubMenu() {
    if (currentIndex && routes[currentIndex]) {
      const subMenu = routes[currentIndex].children;
      if (subMenu) {
        return (
          <div
            className={styles.subMenu}
            onMouseLeave={() => setCurrentIndex(null)}
          >
            {subMenu.map(({ name, path }) => {
              const className = pathname.includes(path) ? "active" : "";
              return (
                <Link key={path} to={path} className={styles[className]}>
                  {name}
                </Link>
              );
            })}
          </div>
        );
      }
    }
  }
  return (
    <Row className={styles.container} justify="middle">
      <Col offset={3} span={6}>
        <a href='/'><img className={styles.logo} src={logo} alt="logo" /></a>
      </Col>
      <Col className={styles.nav} span={15}>
        {Menu()}
      </Col>
      {SubMenu()}
    </Row>
  );
}

export default HeaderReconstruct;
