import React, {useEffect, useState} from 'react';
import './index.less';
import moment from "moment";
import {Col, Row, Card, Breadcrumb, Divider, List } from "antd";
import { EnvironmentOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import background from "../../assets/material/NewsDetail/background.png";
import Ellipsis from "../../components/Ellipsis";
import api from "../../services/api";

function Coopera() {
    const history = useHistory();
    const content =history.location.state;//子页面接参
    const[listData,setListData] =  useState([]);

    useEffect(async() => {
        if (content === undefined){
            const res = await api.selectNetworkNews(100);
            setListData(res.data);
        }
    }, []);

    //详细页面
    const detailed = (selectData) =>{
        //父页面传参
        history.replace({pathname:"/NewsDetail",state:selectData[0]});
    };

    return (
        <Row>
            <Col span={24}>
                <img src={background} alt={'background'} style={{width:'100%'}} />
            </Col>
            {content === undefined ? (
                <Col span={18} offset={3}>
                    <Card bordered={false} style={{backgroundColor:'#ffffff',marginTop:-103}}>
                        <p style={{
                            textAlign:'center',
                            fontSize: 32,
                            fontFamily: 'FZXiaoBiaoSong-B05S',
                            fontWeight: 400,
                            color:'#000000'
                        }}>
                            新闻资讯
                        </p>
                        <p style={{
                            marginTop:-18,
                            textAlign:'center',
                            fontSize: 16,
                            fontFamily: 'FZXiaoBiaoSong-B05S',
                            fontWeight: 400,
                            color:'#B5B5B5'
                        }}>
                            News and information
                        </p>
                        <Row>
                            <Col xl={{ span: 10, offset: 14 }} xxl={{ span: 7, offset: 17 }} style={{marginTop:62}}>
                                <Breadcrumb>
                                    <Breadcrumb.Item>
                                        <EnvironmentOutlined style={{fontSize: 19}} />
                                        <span style={{
                                            fontSize: 19,
                                            fontFamily: 'Microsoft YaHei',
                                            fontWeight: 400,
                                            color: '#000000'
                                        }}>
                                        您的位置：首页
                                    </span>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item
                                        href="/newsDynamic"
                                        style={{
                                            fontSize: 19,
                                            fontFamily: 'Microsoft YaHei',
                                            fontWeight: 400,
                                            color: '#00529C'
                                        }}>
                                        新闻动态
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                        </Row>
                        <Divider style={{marginTop:21}} />
                        <Card  style={{backgroundColor:'#ffffff',marginTop:32}}>
                            <List
                                itemLayout="vertical"
                                size="large"
                                pagination={{
                                    onChange: page => {
                                        console.log(page);
                                    },
                                    pageSize: 20,
                                }}
                                dataSource={listData}
                                renderItem={item => (
                                    <List.Item>
                                        <Row>
                                            <Col span={4}>
                                                <div style={{backgroundColor:'#b8c6e0',width:68,height:68,textAlign:'center'}}>
                                                    <p style={{
                                                        fontSize: 18,
                                                        fontFamily: 'zihun59hao-chuangcuhei',
                                                        fontWeight: 400,
                                                        color: '#FFFFFF'
                                                    }}>{moment(item.updatetime).format('YYYY')}</p>
                                                    <p style={{
                                                        marginTop:-14,
                                                        fontSize: 18,
                                                        fontFamily: 'zihun59hao-chuangcuhei',
                                                        fontWeight: 400,
                                                        color: '#FFFFFF'
                                                    }}>{moment(item.updatetime).format('MM-DD')}</p>
                                                </div>
                                            </Col>
                                            <Col span={20}>
                                                <Row>
                                                    <Col span={24}>
                                                        <a onClick={() =>detailed([item])}>
                                                            <p style={{
                                                                marginTop:-15,
                                                                fontSize: 21,
                                                                fontFamily: 'zihun59hao-chuangcuhei',
                                                                fontWeight: 400,
                                                                color: '#555555'
                                                            }}>
                                                                {item.title}
                                                            </p>
                                                        </a>
                                                    </Col>
                                                    <Col span={24}>
                                                        <p style={{
                                                            marginTop:-10,
                                                            fontSize: 19,
                                                            fontFamily: 'zihun59hao-chuangcuhei',
                                                            fontWeight: 400,
                                                            color: '#999999'
                                                        }}>
                                                            <Ellipsis length={49} tooltip>{item.describes}</Ellipsis>
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </List.Item>
                                )}
                            />
                            <br/><br/>
                        </Card>
                    </Card>
                </Col>
            ) : (
                <Col span={18} offset={3}>
                    <Card bordered={false} style={{backgroundColor:'#ffffff',marginTop:-103}}>
                        <p style={{
                            textAlign:'center',
                            fontSize: 32,
                            fontFamily: 'FZXiaoBiaoSong-B05S',
                            fontWeight: 400,
                            color:'#000000'
                        }}>
                            产品服务
                        </p>
                        <p style={{
                            marginTop:-18,
                            textAlign:'center',
                            fontSize: 16,
                            fontFamily: 'FZXiaoBiaoSong-B05S',
                            fontWeight: 400,
                            color:'#B5B5B5'
                        }}>
                            News and information
                        </p>
                        <Row>
                            <Col xl={{ span: 10, offset: 14 }} xxl={{ span: 7, offset: 17 }} style={{marginTop:62}}>
                                <Breadcrumb>
                                    <Breadcrumb.Item>
                                        <EnvironmentOutlined style={{fontSize: 19}} />
                                        <span style={{
                                            fontSize: 19,
                                            fontFamily: 'Microsoft YaHei',
                                            fontWeight: 400,
                                            color: '#000000'
                                        }}>
                                        您的位置：首页
                                    </span>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item style={{
                                        fontSize: 19,
                                        fontFamily: 'Microsoft YaHei',
                                        fontWeight: 400,
                                        color: '#000000'
                                    }}>
                                        新闻资讯
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item
                                        href="/newsDynamic"
                                        style={{
                                            fontSize: 19,
                                            fontFamily: 'Microsoft YaHei',
                                            fontWeight: 400,
                                            color: '#00529C'
                                        }}>
                                        行业资讯
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </Col>
                        </Row>
                        <Divider style={{marginTop:21}} />
                        <Card  style={{backgroundColor:'#ffffff',marginTop:32}}>
                            <p style={{
                                marginTop:52,
                                textAlign:'center',
                                fontSize: 35,
                                fontFamily:'Microsoft YaHei',
                                fontWeight:'bold',
                                color:'#000000'
                            }}>
                                {content.title}
                            </p>


                            <Row style={{marginTop:52}}>
                                <Col span={14}/>
                                <Col span={3}>
                                    <p style={{
                                        textAlign:'center',
                                        fontSize: 19,
                                        fontFamily:'Microsoft YaHei',
                                        fontWeight:'bold',
                                        color:'#999999'
                                    }}>
                                        编辑人： {content.editor}
                                    </p>
                                </Col>
                                <Col span={3}>
                                    <p style={{
                                        textAlign:'center',
                                        fontSize: 19,
                                        fontFamily:'Microsoft YaHei',
                                        fontWeight:'bold',
                                        color:'#999999'
                                    }}>
                                        审核人： {content.auditor}
                                    </p>
                                </Col>
                                <Col span={4}>
                                    <p style={{
                                        textAlign:'center',
                                        fontSize: 19,
                                        fontFamily:'Microsoft YaHei',
                                        fontWeight:'bold',
                                        color:'#999999'
                                    }}>
                                        发布时间： {moment(content.updatetime).format('YYYY-MM-DD')}
                                    </p>
                                </Col>
                            </Row>





                            <div style={{marginTop:75}} dangerouslySetInnerHTML={{__html:content.content}}/>
                            <br/><br/><br/>
                        </Card>
                    </Card>
                </Col>
            )}
        </Row>
    );
}

export default Coopera;
