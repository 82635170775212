import React, { useEffect } from 'react';
import { Col, Form, Input, Modal, Row, Select } from 'antd';

const FormItem = Form.Item;
const { Option } = Select;
const {TextArea} = Input;




const UpdateForm = props => {

  const [form] = Form.useForm();
  const {
    onSubmit: handleUpdate,
    onCancel: handleUpdateModalVisible,
    updateModalVisible,
    values,
  } = props;


  useEffect(async() => {
  }, []);


  const okHandle = async () => {
    const fieldsValue = await form.validateFields();
    form.resetFields();
    handleUpdate({...fieldsValue});
  };

  const validateMessages = {
    required: '${label} 这是必须的填写的!',
    types: {
      email: '${label} 这不是一个有效邮箱!',
      number: '${label} 这不是一个有效数字!',
    },
    number: {
      range: '${label} 必须是 ${min}cm 和 ${max}cm之间',
    },
  };



  return (
    <div>
      <Modal
        destroyOnClose
        title="预览产品展示图片"
        visible={updateModalVisible}
        onCancel={() => handleUpdateModalVisible(false, values)}
        afterClose={() => handleUpdateModalVisible()}
        onOk={okHandle}
        width={"60%"}
        footer={false}
      >
        <Form
          form={form}
          initialValues={values}
          validateMessages={validateMessages} preserve={false}
        >

          <Row  gutter={16}>
            <Col  span={24}>
                <div>
                    <h3><img alt="example" loading style={{ width: '100%'}} alt="scroll1" src={values.rurl}  /></h3>
                </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default UpdateForm;
