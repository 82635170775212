import React, { useEffect } from 'react';
import { Col, Form, Input, Modal, Row, Select } from 'antd';

const FormItem = Form.Item;
const { Option } = Select;
const {TextArea} = Input;




const UpdateForm = props => {

  const [form] = Form.useForm();
  const {
    onSubmit: handleUpdate,
    onCancel: handleUpdateModalVisible,
    updateModalVisible,
    values,
    sfdwFlXx,
    sszqFlXx,
    ptgjFlXx
  } = props;


  useEffect(async() => {
  }, []);


  const okHandle = async () => {
    const fieldsValue = await form.validateFields();
    form.resetFields();
    handleUpdate({...fieldsValue});
  };

  const validateMessages = {
    required: '${label} 这是必须的填写的!',
    types: {
      email: '${label} 这不是一个有效邮箱!',
      number: '${label} 这不是一个有效数字!',
    },
    number: {
      range: '${label} 必须是 ${min}cm 和 ${max}cm之间',
    },
  };



  return (
    <div>
      <Modal
        destroyOnClose
        title="查看普通工具收费标准"
        visible={updateModalVisible}
        onCancel={() => handleUpdateModalVisible(false, values)}
        afterClose={() => handleUpdateModalVisible()}
        onOk={okHandle}
        width={"60%"}
        okText="确认"
        cancelText="取消"
        footer={false}
      >
        <Form
          form={form}
          initialValues={values}
          validateMessages={validateMessages} preserve={false}
        >


          <Row  gutter={16}>
            <Col  span={8}>
              <FormItem
                label="名称"
                name="mc"
              >
                <Input placeholder="请输入" disabled={"disabled"}/>
              </FormItem>
            </Col>
            <Col  span={8}>
              <FormItem
                label="规格型号"
                name="ggxh"
              >
                <Input placeholder="请输入" disabled={"disabled"}/>
              </FormItem>
            </Col>
            <Col  span={8}>
              <FormItem
                label="试验项目"
                name="ssxm"
              >
                <Input placeholder="请输入" disabled={"disabled"}/>
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col  span={8}>
              <FormItem
                label="单价"
                name="dj"
              >
                <Input  placeholder="请输入" disabled={"disabled"}/>
              </FormItem>
            </Col>
            <Col  span={8}>
              <FormItem
                label="试验周期"
                name="sszqid"
              >
                <Select
                  disabled={"disabled"}
                  allowClear={true}
                  showSearch
                  optionFilterProp="label"
                  placeholder="试验周期"
                  filterOption={(input, option) =>
                    option.children.toString().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    sszqFlXx.map(element =>
                      <Option key={element.id} value={element.id}> {element.mc}</Option>)
                  }
                </Select>
              </FormItem>
            </Col>
            <Col  span={8}>
              <FormItem
                label="单位名称"
                name="dwid"
              >
                <Select
                  disabled={"disabled"}
                  allowClear={true}
                  showSearch
                  optionFilterProp="label"
                  placeholder="单位名称"
                  filterOption={(input, option) =>
                    option.children.toString().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    sfdwFlXx.map(element =>
                      <Option key={element.id} value={element.id}> {element.mc}</Option>)
                  }
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col  span={8}>
              <FormItem
                label="工器具分类"
                name="flid"
              >
                <Select
                  disabled={"disabled"}
                  allowClear={true}
                  showSearch
                  optionFilterProp="label"
                  placeholder="工器具分类"
                  filterOption={(input, option) =>
                    option.children.toString().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    ptgjFlXx.map(element =>
                      <Option key={element.id} value={element.id}> {element.mc}</Option>)
                  }
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col  span={24}>
              <FormItem
                label="备注"
                name="bz"
              >
                <TextArea style={{ height: 80 }} placeholder="请输入" disabled={"disabled"}/>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default UpdateForm;
