import request, { jsToFormData } from '../utils/request';
import { baseUrl } from '../utils/config';

//查询普通工器具收费标准
export async function list(payload) {
    return await request(baseUrl + '/api-ene/tjczxdlzdbjfgzsfxx/list', {
        method: 'POST',
        data: jsToFormData(payload),
    });
}

//保存普通工器具收费标准
export async function save(payload) {
    return await request(baseUrl + '/api-ene/tjczxdlzdbjfgzsfxx/save', {
        method: 'POST',
        data: payload.fields,
    });

}

//更新普通工器具收费标准
export async function update(payload) {
    return await request(baseUrl + '/api-ene/tjczxdlzdbjfgzsfxx/update', {
        method: 'POST',
        data: payload.fields,
    });
}

//删除普通工器具收费标准
export async function del(payload) {
    return await request(baseUrl + '/api-ene/tjczxdlzdbjfgzsfxx/del', {
        method: 'POST',
        data:jsToFormData(payload),
    });
}

//查询单位分类
export async function findAllSfdwFl() {
  return await request(baseUrl + '/api-ene/tjczxdlzdbjfgzsfxx/findAllSfdwFl', {
    method: 'POST',
  });
}

//电缆震荡波试验局放故障查找分类
export async function findAllJfgzFl() {
  return await request(baseUrl + '/api-ene/tjczxdlzdbjfgzsfxx/findAllJfgzFl', {
    method: 'POST',
  });
}

export default {
  list,
  save,
  update,
  del,
  findAllSfdwFl,
  findAllJfgzFl,

};
