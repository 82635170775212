import React from 'react';
import './index.less';
import {Col, Row} from "antd";
import background from "../../assets/material/background.png";
import introduce1 from "../../assets/material/Coopera/introduce1.png";

function Coopera() {

    return (
        <Row>
            <Col span={24}>
                <img src={background} alt={'background'} style={{width:'100%'}} />
            </Col>
            <Col span={24}>
                <p style={{
                    marginTop:-160,
                    textAlign:'center',
                    fontSize: 45,
                    fontFamily: 'FZXiaoBiaoSong-B05S',
                    fontWeight: 400,
                    color:'#FFFFFF'
                }}>
                    合作伙伴
                </p>
            </Col>

            <Col span={18} offset={3}>
                <img src={introduce1} alt={'introduce1'} style={{width:'100%',pointerEvents: 'none',marginTop:173,marginBottom:120}} />
            </Col>
        </Row>
    );
}

export default Coopera;
