import React, { useEffect, useState } from "react";
import { Layout, Drawer, Menu, Card } from 'antd';
import routes from "../../config/routes";
// import Headers from "./components/Header";
import Footers from "./components/Footer";
import Sidebar from '../../components/Sidebar';
import { GlobalHeader } from '../../components/GlobalHeader';
import { getPlainRoutes } from "../../utils/utils";
import { Switch, Route, useHistory } from "react-router-dom";
import HeaderReconstruct from "./components/HeaderReconstruct";
import background from "../../assets/material/About/background.png";
import { MenuTabs as MenuTab } from '../../utils/menu-tabs';
import './index.css';

const { Header, Sider, Content } = Layout;

const Home = props => {
    const history = useHistory();
    const pathname = window.location.pathname;
    const flatRoutes = getPlainRoutes(routes);
    const [accordingLayout, setAccordingLayout] = useState(true);
    const localTabs = MenuTab.getTabs();
    const [tabs, setTabs] = useState(localTabs);
    const [currentTabKey, setCurrentTabKey] = useState(pathname);

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (pathname === "/"){
            history.replace("/home");
        }
        if (window.location.href.indexOf('/back-stage/') !== -1){
            if (sessionStorage.getItem('token')!== 'null'){
                setAccordingLayout(false);
            }else {
                setAccordingLayout(true);
                sessionStorage.setItem('token', null);
                localStorage.setItem('Authorization',null);
                sessionStorage.setItem('code',null); // code
                sessionStorage.setItem('account',null); // 登录账号
                sessionStorage.setItem('username',null); // 账号姓名
                sessionStorage.setItem('sex',null); // 性别
                sessionStorage.setItem('phone',null); // 电话
                sessionStorage.setItem('headimgurl',null); // 获取头像
                history.replace("/home");
            }
        }
    });
    const isHomePage = pathname.includes("home");

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    const sidebarProps = {
        tabs,
        setTabs,
        currentTabKey,
        setCurrentTabKey,
    };

    return (
        <div>
            {accordingLayout ? (
                <div>
                    {isHomePage ? (
                        <div>
                            <img src={background} alt={"background"} style={{ width: "100%" }} />
                            <div style={{ position: "absolute", top: 0, width: "100%" }}>
                                <HeaderReconstruct />
                            </div>
                        </div>
                    ) : (
                        <HeaderReconstruct />
                    )}

                    {flatRoutes.map(({ path, component }, index) => {
                        return <Route path={path} key={index} component={component} exact />;
                    })}

                    <Footers />
                </div>
            ) : (
                <Layout>
                    <Drawer
                        placement="left"
                        closable={false}
                        onClose={onClose}
                        visible={visible}
                        width={200}
                        bodyStyle={{ padding: 0 }}
                    />

                    <Sider className="sidebar fixed-sidebar">
                        <Sidebar  {...sidebarProps} />
                    </Sider>

                    <Layout className="site-layout">
                        <Header className="header">
                            <GlobalHeader showDrawer={showDrawer} />
                        </Header>
                        <Content className="content">
                            <Switch>
                                <Card bordered={false} style={{ width:'100%'}}>
                                    {flatRoutes.map(({ path, component }, index) => {
                                        return <Route path={path} key={index} component={component} exact />;
                                    })}
                                </Card>
                            </Switch>
                        </Content>
                    </Layout>
                </Layout>
            )}
        </div>
    );
};

export default Home;
