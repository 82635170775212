import React from 'react';
import './index.less';
import {Col, Row, Menu} from "antd";
import { useHistory } from 'react-router-dom';
import background from "../../assets/material/background.png";
import map1 from '../../assets/material/LowCarbons/map1.png';
import map2 from '../../assets/material/LowCarbons/map2.png';

function LowCarbons() {
    const history = useHistory();

    //菜单栏点击
    const itemClick = (e) => {
        let k = parseInt(e.key);
        switch (k) {
            case 1://涉电检测
                history.replace('/detection/electricity');
                break;
            case 2://低碳服务
                history.replace('/detection/carbon-service');
                break;
            case 3://碳纤维
                history.replace('/detection/carbons');
                break;
            case 4://新能源入网
                history.replace('/detection/energy');
                break;
            case 5://建筑结构
                history.replace('/detection/build');
                break;
            case 6://职业健康
                history.replace('/detection/healthy');
                break;
            default:
                history.replace('/detection/electricity');
                break;
        }
    };

    return (
        <Row>
            <Col span={24}>
                <img src={background} alt={'background'} style={{width:'100%'}} />
            </Col>
            <Col span={24}>
                <Menu style={styles.menu} onClick={itemClick}>
                    <Menu.Item style={styles.menuItem} key={1}>涉电检测</Menu.Item>
                    <Menu.Item style={styles.menuItem} key={2}>低碳服务</Menu.Item>
                    <Menu.Item style={styles.menuItem} key={3}>碳纤维</Menu.Item>
                    <Menu.Item style={styles.menuItem} key={4}>新能源入网</Menu.Item>
                    <Menu.Item style={styles.menuItem} key={5}>建筑结构</Menu.Item>
                    <Menu.Item style={styles.menuItem} key={6}>职业健康</Menu.Item>
                </Menu>
            </Col>

            <Col span={18} offset={3}>
                <p style={{
                    marginTop:149,
                    fontSize: 21,
                    fontDamily: 'Microsoft YaHei',
                    fontWeight: 400,
                    color: '#333333'
                }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;吉林东研检测技术有限公司是以大学为依托，由吉林东杰科技开发有限公司与广州恒力检测股份有限公司共同出资组建,是综合性第三方安全检测及配、售电一体化技术服务机构。东研检测专注于企业的用电安全及能效管理。业务涵盖电力高低压设备检测及维护、设备托管及智能运维、电力故障抢修、配电房安装改造、能效管理、智能配电房监测系统、电力安全工器具及劳保用品预防性试验、环境检测、仪器仪表校准、售电等业务。
                    <br/><br/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;东研检测与大学相关学院及中国船级社CCS等知名企业深度合作，从清洁发展机制项目审定核证和企业节能审核开始，致力于逐渐发展成在能源使用和节约、温室气体减排，以及绿色发展领域内提供审核、技术服务、培训和科学研究等服务的综合性服务机构。
                </p>
            </Col>

            <Col span={22} offset={2} style={{marginTop:63}}>
                <p style={{marginLeft:'75px', fontSize: '42px', fontFamily: 'FZXiaoBiaoSong-B05S', fontWeight: '400', color: '#00529C'}}>
                    应用案例
                </p>
                <p style={{marginTop:-25,marginLeft:'77px', fontSize: '23px', fontFamily: 'Adobe Heiti Std', fontWeight: 'normal', color: '##292929'}}>
                    Application case
                </p>
                <div style={{marginLeft:'77px', width:'89px',height:'9px',background:'#004B90'}}/>
            </Col>
            <Col span={18} offset={3}>
                <Row gutter={[32,32]} style={{marginTop:37}}>
                    <Col span={13}>
                        <img src={map1} alt={'map1'} style={{width:'100%'}} />
                    </Col>
                    <Col span={11}>
                        <p style={{
                            marginTop:51,
                            fontSize: 21,
                            fontFamily: 'Microsoft YaHei',
                            fontWeight: 400,
                            color: '#333333'
                        }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;作为国内率先开展能源审计的机构，CCSC已在广东、广西、海南、新疆、北京、河北、贵州、山西、安徽、山东等多个省市开展了工业及非工业单位能源审计工作。
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;在工业领域，累计为接近200家企业提供了能源审计服务，项目涉及发电、钢铁、建材、石化、交通运输、设备制造、冶金等行业领域﹔在非工业领域，累计为400余家用能单位提供了能源审计服务。
                        </p>
                    </Col>
                    <Col span={11}>
                        <p style={{
                            marginTop:51,
                            fontSize: 21,
                            fontFamily: 'Microsoft YaHei',
                            fontWeight: 400,
                            color: '#333333'
                        }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;截止到2020年底，企业共承担了18个省、自治区、直辖市共300多个重点用能单位的节能诊断工作。覆盖的行业包括机械、轻工、纺织、钢铁、建材、化工、有色金属、食品、造纸等高耗能行业。参与的省份数量和实施的项目数量均位居全国首位，报告质量获得企业及各地工信主管部门的一致好评。
                        </p>

                    </Col>
                    <Col span={13}>
                        <img src={map2} alt={'map2'} style={{width:'100%'}} />
                    </Col>
                </Row>
            </Col>

            <Col span={22} offset={2} style={{marginTop:63}}>
                <p style={{marginLeft:'75px', fontSize: '42px', fontFamily: 'FZXiaoBiaoSong-B05S', fontWeight: '400', color: '#00529C'}}>
                    科研成果
                </p>
                <p style={{marginTop:-25,marginLeft:'77px', fontSize: '23px', fontFamily: 'Adobe Heiti Std', fontWeight: 'normal', color: '##292929'}}>
                    Scientific achievements
                </p>
                <div style={{marginLeft:'77px', width:'89px',height:'9px',background:'#004B90'}}/>
            </Col>
            <Col span={18} offset={3}>
                <p style={{
                    marginTop:40,
                    fontSize: 24,
                    fontFamily: 'Microsoft YaHei',
                    fontWeight: 400,
                    color: '#333333'
                }}>
                    能源动力学院
                </p>
                <p style={{
                    marginTop:25,
                    fontSize: 21,
                    fontFamily: 'Microsoft YaHei',
                    fontWeight: 400,
                    color: '#333333'
                }}>
                    细菌纤维素复合纳米吸附材料的制备及其应用研究吉林省科技进步奖&nbsp;&nbsp;&nbsp;&nbsp;鲁敏<br/>
                    纤维素基复合纳米水处理材料的制备及其应用研究吉林省电力科学科技进步奖&nbsp;&nbsp;&nbsp;&nbsp;鲁敏<br/>
                    基于3D鸟巢结构Sb203/RGO复合材料的高性能锂离子电池研究吉林省自然科学学术成果奖&nbsp;&nbsp;&nbsp;&nbsp;周静<br/>
                    基于过渡金属取代的Keggin型多酸d电子结构调控反应活性吉林省科学技术奖（自然科学奖）&nbsp;&nbsp;&nbsp;&nbsp;刘春<br/>
                    光铝、铁金属玻璃非晶形成能力的热力学解析吉林省科学技术奖（自然科学奖）&nbsp;&nbsp;&nbsp;&nbsp;王嵬<br/>
                    电力系统生产脱硝及纳米水处理材料的制备及应用研究吉林省电力科学技术进步奖&nbsp;&nbsp;&nbsp;&nbsp;杨春华<br/>
                    农业废弃生物质基碳材料的制备及应用吉林省科技进步奖&nbsp;&nbsp;&nbsp;&nbsp;张晓君<br/>
                    水溶性壳聚糖衍生物类造纸增强剂的作用机理吉林省科学技术奖(自然科学奖）&nbsp;&nbsp;&nbsp;&nbsp;陈子成<br/>
                </p>
                <p style={{
                    marginTop:43,
                    fontSize: 24,
                    fontFamily: 'Microsoft YaHei',
                    fontWeight: 400,
                    color: '#333333'
                }}>
                    化学工程学院
                </p>
                <p style={{
                    marginTop:25,
                    fontSize: 21,
                    fontFamily: 'Microsoft YaHei',
                    fontWeight: 400,
                    color: '#333333'
                }}>
                    热能利用系统节能重大需求协同创新中心(立项培育)省教育厅、财政厅&nbsp;&nbsp;&nbsp;&nbsp;周云龙<br/>
                    吉林省油页岩综合利用科技创新中心省科技厅&nbsp;&nbsp;&nbsp;&nbsp;王擎<br/>
                    吉林省火电机组节能减排科技创新中心省科技厅&nbsp;&nbsp;&nbsp;&nbsp;张艾萍<br/>
                    吉林省油页岩综合利用工程研究中心省发改委&nbsp;&nbsp;&nbsp;&nbsp;王擎<br/>
                    吉林省节能与测控技术工程实验室省发改委&nbsp;&nbsp;&nbsp;&nbsp;徐志明<br/>
                    吉林省油页岩综合利用工程研究中心省教育厅&nbsp;&nbsp;&nbsp;&nbsp;王擎<br/>
                    吉林省油页岩综合开发利用高端科技创新平台省教育厅&nbsp;&nbsp;&nbsp;&nbsp;王擎<br/>
                    新能源产业吉林省校企联合技术创新实验室省教育厅&nbsp;&nbsp;&nbsp;&nbsp;曲宏伟<br/>

                </p>
            </Col>

            <Col span={22} offset={2} style={{marginTop:63}}>
                <p style={{marginLeft:'75px', fontSize: '42px', fontFamily: 'FZXiaoBiaoSong-B05S', fontWeight: '400', color: '#00529C'}}>
                    应用转换
                </p>
                <p style={{marginTop:-25,marginLeft:'77px', fontSize: '23px', fontFamily: 'Adobe Heiti Std', fontWeight: 'normal', color: '##292929'}}>
                    Application conversion
                </p>
                <div style={{marginLeft:'77px', width:'89px',height:'9px',background:'#004B90'}}/>
            </Col>
            <Col span={18} offset={3}>
                <p style={{
                    marginTop:40,
                    fontSize: 21,
                    fontFamily: 'Microsoft YaHei',
                    fontWeight: 400,
                    color: '#333333'
                }}>
                    汽轮机叶顶间隙泄漏流的不稳定特性及其诱导汽流激振研究国家自然科学基金委员会&nbsp;&nbsp;&nbsp;&nbsp;曹丽华<br/>
                    基于数字图像处理技术气液两相流型智能识别及其演化规律国家自然科学基金委员会&nbsp;&nbsp;&nbsp;&nbsp;周云龙<br/>
                    基于水质、换热面特性和运行工况的冷却水污垢特性研究国家自然科学基金委员会&nbsp;&nbsp;&nbsp;&nbsp;徐志明<br/>
                    非圆截面小通道两相流时频域非线性耦合动力学特性研究国家自然科学基金委员会&nbsp;&nbsp;&nbsp;&nbsp;李洪伟<br/>
                    电场作用下多组分微纳液滴界面输运特性及强化传热机理国家自然科学基金委员会&nbsp;&nbsp;&nbsp;&nbsp;陈奇成<br/>
                    奇异核核反应中集体流性质研究国家自然科学基金委员会&nbsp;&nbsp;&nbsp;&nbsp;颜廷志<br/>
                    基于流化床气固两相流型图像的多参数检测方法国家自然科学基金委员会&nbsp;&nbsp;&nbsp;&nbsp;周云龙<br/>
                    汽轮机叶顶间隙泄漏流的涡系结构和流动特性研究国家自然科学基金委员会&nbsp;&nbsp;&nbsp;&nbsp;曹丽华<br/>
                    管束间气液两相绕流特性及流型演变机理研究国家自然科学基金委员会&nbsp;&nbsp;&nbsp;&nbsp;洪文鹏<br/>
                    基于排汽湿度在线监测的机组经济性分析系统青年科研基金项目&nbsp;&nbsp;&nbsp;&nbsp;王升龙<br/>
                    双区加热法在线监测汽轮机排汽湿度青年科研基金项目&nbsp;&nbsp;&nbsp;&nbsp;王升龙<br/>
                    燃煤飞灰可吸入颗粒物聚团流化机理研究青年科研基金项目&nbsp;&nbsp;&nbsp;&nbsp;郑建祥<br/>
                    基于节能减排的燃煤热电联产机组之间热负荷、电负荷在线优化调度方法研究重点项目&nbsp;&nbsp;&nbsp;&nbsp;李勇<br/>
                    冷却水污垢特性监测系统研发平台建设项目重点项目&nbsp;&nbsp;&nbsp;&nbsp;徐志明<br/>
                    减阻纳米流体的管内流动和换热特性研究重点项目&nbsp;&nbsp;&nbsp;&nbsp;孙斌<br/>
                    管束气液两相绕流流型转变特性研究自然科学基金&nbsp;&nbsp;&nbsp;&nbsp;洪文鹏<br/>
                    油页岩半焦与生物质混烧关键技术研究青年科研基金项目&nbsp;&nbsp;&nbsp;&nbsp;刘洪鹏<br/>
                    非定常尾迹对涡轮动叶气膜冷却影响的研究自然科学基&nbsp;&nbsp;&nbsp;&nbsp;金张玲<br/>
                    吉林省火电机组节能减排科技创新中心重点项目&nbsp;&nbsp;&nbsp;&nbsp;李勇<br/>
                    吉林省火电机组节能减排科技创新中心重点项目&nbsp;&nbsp;&nbsp;&nbsp;李勇<br/>
                    能源有效利用与热力设备安全节能技术重点项目王擎<br/>
                    500~1000t/d油页岩气体热载体干馏炉研发重点项目&nbsp;&nbsp;&nbsp;&nbsp;柏静儒<br/>
                    风电、火电企业协作调峰机制的研究重点项目&nbsp;&nbsp;&nbsp;&nbsp;金建国<br/>
                    基于RMT理论的Gauss边界动力系统中低频振动分析重点项目&nbsp;&nbsp;&nbsp;&nbsp;肖斌<br/>
                    汽轮机凝汽器冷却水节能型二次滤网技术及装置一般项目&nbsp;&nbsp;&nbsp;&nbsp;曹丽华<br/>
                    薄弱电网电源意外解列带厂用电安全性关键技术研究重点项目&nbsp;&nbsp;&nbsp;&nbsp;周云龙<br/>

                </p>
            </Col>
        </Row>
    );
}

const styles = {
    menuItem: {border: 0, fontSize: 45, color: '#FFFFFF',display: 'inline',fontFamily:'FZXiaoBiaoSong-B05S',fontWeight: '400'},
    menu: {background: 'transparent',marginTop:-160,textAlign:'center'},
}

export default LowCarbons;
