import React from 'react';
import { Col, Form, layout, message, Row } from 'antd';
import E from 'wangeditor';
import { downloads } from '../../../utils/config';

class UpdateModify extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      editorContent:this.props.editorContent,
      newsData:[],
    };
  }

  t
  componentDidMount() {
    const elemMenu = this.refs.editorElemMenu;
    const elemBody = this.refs.editorElemBody;
    const editor = new E(elemMenu,elemBody)
    // 使用 onchange 函数监听内容的变化，并实时更新到 state 中

    // 上传图片到服务器
    editor.customConfig.uploadFileName = 'file'; // 设置文件上传的参数名称
    editor.customConfig.onchange = html => {
      console.log(editor.txt.html())
      this.setState({
        // editorContent: editor.txt.text()
        editorContent: editor.txt.html()
      })
      this.getFwb(editor.txt.html());
    }
    //   editor.customConfig.uploadImgServer = 'http://192.168.120.202:7080/portal-upload/uploadFile'; // 设置上传文件的服务器路径
    editor.customConfig.uploadImgServer = downloads;
    editor.customConfig.uploadImgHeaders = {
      Authorization: localStorage.getItem('Authorization'),
    }
    editor.customConfig.uploadImgMaxSize = 3 * 1024 * 1024; // 将图片大小限制为 3M

    editor.customConfig.uploadImgHooks = {
      before: function (xhr, editor, files) {
        // 图片上传之前触发
        // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象，files 是选择的图片文件
        // 如果返回的结果是 {prevent: true, msg: 'xxxx'} 则表示用户放弃上传
        // return {
        //     prevent: true,
        //     msg: '放弃上传'
        // }
      },
      success: function (xhr, editor, result) {
        // 图片上传并返回结果，图片插入成功之后触发
        // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象，result 是服务器端返回的结果
      },
      fail: function (xhr, editor, result) {
        console.log('fail result', result)

        // 图片上传并返回结果，但图片插入错误时触发
        // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象，result 是服务器端返回的结果
      },
      error: function (xhr, editor) {
        //       console.log('error', xhr)
        // 图片上传出错时触发
        // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象
      },
      timeout: function (xhr, editor) {
        // 图片上传超时时触发
        // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象
      },
      // 如果服务器端返回的不是 {errno:0, data: [...]} 这种格式，可使用该配置
      // （但是，服务器端返回的必须是一个 JSON 格式字符串！！！否则会报错）
      customInsert: function (insertImg, result, editor) {
        // 图片上传并返回结果，自定义插入图片的事件（而不是编辑器自动插入图片！！！）
        // insertImg 是插入图片的函数，editor 是编辑器对象，result 是服务器端返回的结果
        // 举例：假如上传图片成功后，服务器端返回的是 {url:'....'} 这种格式，即可这样插入图片：
        insertImg(result.url);
      }
    }

    editor.customConfig.menus = [
      'head',  		// 标题
      'bold',  		// 粗体
      'fontSize',     // 字号
      'fontName',  	// 字体
      'italic',  		// 斜体
      'underline',  	// 下划线
      'strikeThrough',  // 删除线
      'foreColor',  // 文字颜色
      'backColor',  // 背景颜色
      'link',  // 插入链接
      'list',  // 列表
      'justify',  // 对齐方式
      'quote',  // 引用
      'emoticon',  // 表情
      'image',  // 插入图片
      'table',  // 表格
      'video',  // 插入视频
      'code',  // 插入代码
      'undo',  // 撤销
      'redo'  // 重复
    ]
    editor.customConfig.uploadImgShowBase64 = true
    editor.create()
    editor.txt.text(this.state.editorContent);
    window.addEventListener('resize', this.resizeFooterToolbar, { passive: true });
  };

  //将富文本传到父表单中
  getFwb = (val)=>{
    this.props.getFwb(val);
  }

  render() {

    return (
      <div className="text-area" >
        <div ref="editorElemMenu"
             style={{backgroundColor:'#f1f1f1',border:"1px solid #ccc"}}
             className="editorElem-menu">
        </div>
        <div
          style={{
            padding:"0 10px",
            height:300,
            border:"1px solid #ccc",
            borderTop:"none"
          }}
          ref="editorElemBody" className="editorElem-body">
        </div>
      </div>
    );
  }
}

export default UpdateModify;
