import React, {useEffect} from 'react';
import api from '../../services/api';
import {useHistory} from 'react-router-dom'
import {setAuthority} from "../../utils/authority";
import PasswordForm from './components/PasswordForm';
// import { getFirstRoute } from '../../utils/utils';
// import routes from '../../config/routes';
import './index.css';

const Home = props => {
  const history = useHistory();

    useEffect(() => {
        sessionStorage.setItem('token', null);
        localStorage.setItem('Authorization',null);
        sessionStorage.setItem('code',null); // code
        sessionStorage.setItem('account',null); // 登录账号
        sessionStorage.setItem('username',null); // 账号姓名
        sessionStorage.setItem('sex',null); // 性别
        sessionStorage.setItem('phone',null); // 电话
        sessionStorage.setItem('headimgurl',null); // 获取头像
    });

  //登陆验证
  async function login(values) {
      const response = await api.login(values);
      if (response.code == '1') {
      const { token_type, access_token } = response.data;
      //保存Authorization
      localStorage.setItem('Authorization', token_type + ' ' + access_token);
      sessionStorage.setItem('token', access_token);
      //获取当前登录用户信息
      const responses = await api.getCurrentUser();
      sessionStorage.setItem('code', responses.data.id); // code
      sessionStorage.setItem('account', responses.data.username); // 登录账号
      sessionStorage.setItem('username', responses.data.nickname); // 账号姓名
      sessionStorage.setItem('sex', responses.data.sex); // 性别
      sessionStorage.setItem('phone', responses.data.phone); // 电话
      sessionStorage.setItem('headimgurl', responses.data.headImgUrl); // 获取头像
      setAuthority('guest');
      history.replace('/back-stage/news');
    }
  }

  return (
    <div className="login-bg">
      <div className="login-container">
        <div className="login-main">
          <div className="title">
            <h1>能源互联网管理</h1>
            <p>Energy Internet Management Platform</p>
          </div>
            <PasswordForm login={login}/>
        </div>
      </div>
    </div>
  )
};

export default Home;
