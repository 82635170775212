import React, { useEffect, useState } from 'react';
import { Col, Form, Input, Modal, Row, Select } from 'antd';
import UpdateModify from '../components/UpdateModify';

const FormItem = Form.Item;
const { Option } = Select;
const {TextArea} = Input;




const UpdateForm = props => {


  const [form] = Form.useForm();
  const {
    onSubmit: handleUpdate,
    onCancel: handleUpdateModalVisible,
    updateModalVisible,
    values,
    jcFlXx,
    lbFlXx,
  } = props;

  const [fwbxx, setFwbxx] = useState(values.sm);
  const [fwbxxXzfw, setFwbxxXzfw] = useState(values.xzfw);

  useEffect(async() => {
  }, []);


  const okHandle = async () => {
    const fieldsValue = await form.validateFields();
    form.resetFields();
    handleUpdate({...fieldsValue,sm:fwbxx,xzfw:fwbxxXzfw});
  };

  const validateMessages = {
    required: '${label} 这是必须的填写的!',
    types: {
      email: '${label} 这不是一个有效邮箱!',
      number: '${label} 这不是一个有效数字!',
    },
    number: {
      range: '${label} 必须是 ${min}cm 和 ${max}cm之间',
    },
  };


  const getFwb = async (val) => {
    setFwbxx(val);
  };

  const getFwbXzfw = async (val) => {
    setFwbxxXzfw(val);
  };
  return (
    <div>
      <Modal
        destroyOnClose
        title="修改恒力检测计量认证项目"
        visible={updateModalVisible}
        onCancel={() => handleUpdateModalVisible(false, values)}
        afterClose={() => handleUpdateModalVisible()}
        onOk={okHandle}
        width={"60%"}
        okText="确认"
        cancelText="取消"
      >
        <Form
          form={form}
          initialValues={values}
          validateMessages={validateMessages} preserve={false}
        >

          <FormItem
            hidden={true}
            name="id"
          >
            <Input />
          </FormItem>
          <Row  gutter={16}>
            <Col  span={8}>
              <FormItem
                label="授权签字人"
                name="sqqzr"
              >
                <Input placeholder="请输入"/>
              </FormItem>
            </Col>
            <Col  span={8}>
              <FormItem
                label="项目/参数"
                name="xmcs"
              >
                <Input placeholder="请输入"/>
              </FormItem>
            </Col>
            <Col  span={8}>
              <FormItem
                label="依据的标准"
                name="yjbz"

              >
                <Input placeholder="请输入"/>
              </FormItem>
            </Col>
          </Row>
          <Row  gutter={16}>
            <Col  span={8}>
              <FormItem
                label="检测对象"
                name="jcdxid"

              >
                <Select
                  allowClear={true}
                  showSearch
                  optionFilterProp="label"
                  filterOption={(input, option) =>
                    option.children.toString().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    jcFlXx.map(element =>
                      <Option key={element.id} value={element.id}> {element.mc}</Option>)
                  }
                </Select>
              </FormItem>
            </Col>

            <Col  span={8}>
              <FormItem
                label="类别分类"
                name="lbid"

              >
                <Select
                  allowClear={true}
                  showSearch
                  optionFilterProp="label"
                  filterOption={(input, option) =>
                    option.children.toString().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    lbFlXx.map(element =>
                      <Option key={element.id} value={element.id}> {element.mc}</Option>)
                  }
                </Select>
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label='限制范围' >
                <UpdateModify getFwb={getFwbXzfw}  editorContent={values.sm}/>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label='说&nbsp;&nbsp;&nbsp;明&nbsp;&nbsp;&nbsp;' >
                <UpdateModify getFwb={getFwb}  editorContent={values.xzfw}/>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col  span={24}>
              <FormItem
                label="备&nbsp;&nbsp;&nbsp;注&nbsp;&nbsp;&nbsp;"
                name="bz"
              >
                <TextArea style={{ height: 80 }} placeholder="请输入"/>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default UpdateForm;
