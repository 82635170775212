import React, {useState} from 'react';
import './index.css';
import {Row, Col, Card, Anchor} from "antd";
import background from '../../assets/material/background.png';
import map1 from '../../assets/material/Technology/map1.png';
import map2 from '../../assets/material/Technology/map2.png';
import map3 from '../../assets/material/Technology/map3.png';
import map4 from '../../assets/material/Technology/map4.png';
import map5 from '../../assets/material/Technology/map5.png';
import map6 from '../../assets/material/Technology/map6.png';
import map7 from '../../assets/material/Technology/map7.png';
import map8A from '../../assets/material/Technology/map8A.png';
import map8B from '../../assets/material/Technology/map8B.png';
import map9A from '../../assets/material/Technology/map9A.png';
import map9B from '../../assets/material/Technology/map9B.png';
import map10A from '../../assets/material/Technology/map10A.png';
import map10B from '../../assets/material/Technology/map10B.png';
import map11A from '../../assets/material/Technology/map11A.png';
import map11B from '../../assets/material/Technology/map11B.png';
import map12 from '../../assets/material/Technology/map12.png';
import map13 from '../../assets/material/Technology/map13.png';
import map14 from '../../assets/material/Technology/map14.png';
import map15 from '../../assets/material/Technology/map15.png';
import map16 from '../../assets/material/Technology/map16.png';
import map17 from '../../assets/material/Technology/map17.png';
import map18 from '../../assets/material/Technology/map18.png';

function Technology() {
    const [coreProduct1, setCoreProduct1] = useState(false);
    const [coreProduct2, setCoreProduct2] = useState(true);
    const [coreProduct3, setCoreProduct3] = useState(true);
    const [coreProduct4, setCoreProduct4] = useState(true);

    const openCoreProduct1 = async flag => {
        await setCoreProduct1(flag);
        await setCoreProduct2(true);
        await setCoreProduct3(true);
        await setCoreProduct4(true);
    };

    const openCoreProduct2 = async flag => {
        console.log('------------------',flag);
        await setCoreProduct1(true);
        await setCoreProduct2(flag);
        await setCoreProduct3(true);
        await setCoreProduct4(true);
    };

    const openCoreProduct3 = async flag => {
        await setCoreProduct1(true);
        await setCoreProduct2(true);
        await setCoreProduct3(flag);
        await setCoreProduct4(true);
    };

    const openCoreProduct4 = async flag => {
        await setCoreProduct1(true);
        await setCoreProduct2(true);
        await setCoreProduct3(true);
        await setCoreProduct4(flag);
    };

    return (
        <Row>
            <Col span={24}>
                <img src={background} alt={'background'} style={{width:'100%'}} />
            </Col>
            <Col span={24}>
                <Anchor
                    affix={false}
                    style={{
                        marginTop:-160,
                        textAlign:'center',
                        backgroundColor: 'transparent'
                    }}
                >
                    <a href="#北京反向飞地">
                        <span style={{fontSize: 40, fontFamily: 'FZXiaoBiaoSong-B05S', fontWeight: 400, color:'#FFFFFF'}}>北京反向飞地</span>
                    </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a href="#创新中心">
                        <span style={{fontSize: 40, fontFamily: 'FZXiaoBiaoSong-B05S', fontWeight: 400, color:'#FFFFFF'}}>创新中心</span>
                    </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a href="#孵化器">
                        <span style={{fontSize: 40, fontFamily: 'FZXiaoBiaoSong-B05S', fontWeight: 400, color:'#FFFFFF'}}>孵化器</span>
                    </a>
                </Anchor>
            </Col>

            <Col span={5} offset={10}>
                <div style={{
                    marginTop:85,
                    textAlign:'center',
                    background: 'linear-gradient(to left, #043add, #004B90) left bottom no-repeat,linear-gradient(to bottom, #004B90, #004B90) left bottom no-repeat,linear-gradient(to left, #004B90, #004B90) right top no-repeat,linear-gradient(to left, #004B90, #004B90) right top no-repeat',
                    backgroundSize: '0.25rem 2rem, 2rem 0.25rem'
                }} id='北京反向飞地'>
                    <p className={'titles'}>
                        北京反向飞地
                    </p>
                    <p className={'titles-English'}>
                        Beijing anti-enclave
                    </p>
                </div>
            </Col>
            <Col span={18} offset={3}>
                <Row>
                    <Col span={10}>
                        <img src={map1} alt={'map1'} style={{marginTop:48,width:'92%'}} />
                    </Col>
                    <Col span={14}>
                        <p style={{
                            marginTop:77,
                            fontSize: 21,
                            fontFamily: 'Microsoft YaHei',
                            fontWeight: 400,
                            color: '#333333'
                        }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            我们在北京与中关村软件园合作的反向飞地，帮助吉林市引进和孵化绿色低碳领域的人才、技术、成果，对接北京与绿色低碳有关的政府机关、科研院所、咨询机构、行业龙头和高科技企业，让吉林市能够最大化利用好国家政策、科研资源、高新技术、低成本资金和先进产品。
                            <br/><br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            建立了一个比较完善的吉林市绿色低碳产业从引进到孵化延伸至产业端的窗口、桥梁和平台，接下来就是利用好这个技术创新的平台和载体，持续不断的为吉林市引进优质资源、开展广泛合作、推进全方位的创新。
                        </p>
                    </Col>
                </Row>
                <Row gutter={28}>
                    <Col span={9}>
                        <img src={map2} alt={'map2'} style={{width:'100%',marginTop:17}} />
                        <img src={map3} alt={'map3'} style={{width:'100%',marginTop:17}} />
                    </Col>
                    <Col span={9}>
                        <img src={map4} alt={'map4'} style={{width:'96%',marginTop:16}} />
                    </Col>
                    <Col span={6} style={{marginLeft:-20}}>
                        <img src={map5} alt={'map5'} style={{width:'99%',marginTop:16}} />
                        <img src={map6} alt={'map6'} style={{width:'99%',marginTop:14}} />
                        <img src={map7} alt={'map7'} style={{width:'99%',marginTop:14}} />
                    </Col>
                </Row>
            </Col>

            <Col span={4} offset={10}>
                <div style={{
                    marginTop:72,
                    textAlign:'center',
                    background: 'linear-gradient(to left, #043add, #004B90) left bottom no-repeat,linear-gradient(to bottom, #004B90, #004B90) left bottom no-repeat,linear-gradient(to left, #004B90, #004B90) right top no-repeat,linear-gradient(to left, #004B90, #004B90) right top no-repeat',
                    backgroundSize: '0.25rem 2rem, 2rem 0.25rem'
                }} id='创新中心'>
                    <p className={'titles'}>
                        创新中心
                    </p>
                    <p className={'titles-English'}>
                        Innovation Center
                    </p>
                </div>
            </Col>
            <Col span={18} offset={3}>
                <Row gutter={16}>
                    <Col span={24}>
                        <p style={{
                            marginTop:40,
                            fontSize: 21,
                            fontFamily: 'Microsoft YaHei',
                            fontWeight: 400,
                            color: '#333333',
                            textAlign:'center'
                        }}>
                            我们已经成立各类创新中心，<br/>
                            包括能源替代、综合能源、节能提效和能源服务四大类 22 个创新中心，<br/>
                            这些创新中心都是学校科研团队、域外科研机构和企业联合成立的应用型创新中心。
                        </p>
                    </Col>



                    {coreProduct1 == true?(
                            <Col span={6}>
                                <Card
                                    hoverable
                                    style={{marginTop:52,width: '100%',height: '93%' }}
                                    onClick={async () => openCoreProduct1(false)}
                                >
                                    <Row gutter={16}>
                                        <Col span={24} style={{textAlign:'center'}}>
                                            <img src={map8A} alt={'map9'} style={{width:'115px',height:'133px',marginTop:10}} />
                                        </Col>
                                        <Col span={24} style={{textAlign:'center'}}>
                                            <p style={{
                                                marginTop:48,
                                                fontSize: 24,
                                                fontFamily: 'Microsoft YaHei',
                                                fontWeight: 400,
                                            }}>
                                                能源替代
                                            </p>
                                            <p style={{
                                                marginTop:-14,
                                                fontSize: 12,
                                                fontFamily: 'Microsoft YaHei',
                                                fontWeight: 400
                                            }}>
                                                Energy&nbsp;&nbsp;substitution
                                            </p>
                                        </Col>
                                        <Col span={12} offset={8}>
                                            <div style={{marginTop:12,width:90,height:2,backgroundColor:'#017BCE'}}/>
                                        </Col>
                                        <Col span={24}>
                                            <p style={{
                                                marginTop:41,
                                                marginBottom:15,
                                                fontSize: 19,
                                                fontFamily: 'Microsoft YaHei',
                                                fontWeight: 400,
                                                color: '#999999'
                                            }}>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;光伏应用创新中心<br/>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;储能创新中心氢能创新中心<br/>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;生物质循环利用创新中心<br/>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;固态储热创新中心<br/>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;新能源农业机械创新中心<br/>
                                            </p>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        ):
                        <Col span={6}>
                            <Card
                                hoverable
                                style={{marginTop:52,backgroundColor:'#017bce', width: '100%',height: '93%' }}
                            >
                                <Row gutter={16}>
                                    <Col span={24} style={{textAlign:'center'}}>
                                        <img src={map8B} alt={'map8'} style={{width:'115px',height:'133px',marginTop:10}} />
                                    </Col>
                                    <Col span={24} style={{textAlign:'center'}}>
                                        <p style={{
                                            marginTop:48,
                                            fontSize: 24,
                                            fontFamily: 'Microsoft YaHei',
                                            fontWeight: 400,
                                            color: '#FFFFFF'
                                        }}>
                                            能源替代
                                        </p>
                                        <p style={{
                                            marginTop:-14,
                                            fontSize: 12,
                                            fontFamily: 'Microsoft YaHei',
                                            fontWeight: 400,
                                            color: '#ffffff'
                                        }}>
                                            Energy&nbsp;&nbsp;substitution
                                        </p>
                                    </Col>
                                    <Col span={12} offset={8}>
                                        <div style={{marginTop:12,width:90,height:2,backgroundColor:'#ffffff'}}/>
                                    </Col>
                                    <Col span={24}>
                                        <p style={{
                                            marginTop:41,
                                            marginBottom:22,
                                            fontSize: 19,
                                            fontFamily: 'Microsoft YaHei',
                                            fontWeight: 400,
                                            color: '#ffffff'
                                        }}>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;光伏应用创新中心<br/>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;储能创新中心氢能创新中心<br/>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;生物质循环利用创新中心<br/>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;固态储热创新中心<br/>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;新能源农业机械创新中心<br/>
                                        </p>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    }

                    {coreProduct2 == true?(
                            <Col span={6}>
                                <Card
                                    hoverable
                                    style={{marginTop:52,width: '100%',height: '93%' }}
                                    onClick={async () => openCoreProduct2(false)}
                                >
                                    <Row gutter={16}>
                                        <Col span={24} style={{textAlign:'center'}}>
                                            <img src={map9A} alt={'map9'} style={{width:'115px',height:'133px',marginTop:10}} />
                                        </Col>
                                        <Col span={24} style={{textAlign:'center'}}>
                                            <p style={{
                                                marginTop:48,
                                                fontSize: 24,
                                                fontFamily: 'Microsoft YaHei',
                                                fontWeight: 400,
                                            }}>
                                                综合能源
                                            </p>
                                            <p style={{
                                                marginTop:-14,
                                                fontSize: 12,
                                                fontFamily: 'Microsoft YaHei',
                                                fontWeight: 400
                                            }}>
                                                Integrated&nbsp;&nbsp;Energy
                                            </p>
                                        </Col>
                                        <Col span={12} offset={8}>
                                            <div style={{marginTop:12,width:90,height:2,backgroundColor:'#017BCE'}}/>
                                        </Col>
                                        <Col span={24}>
                                            <p style={{
                                                marginTop:41,
                                                marginBottom:15,
                                                fontSize: 19,
                                                fontFamily: 'Microsoft YaHei',
                                                fontWeight: 400,
                                                color: '#999999'
                                            }}>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;物联网创新中心<br/>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;能源感知创新中心<br/>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;能源信息高铁5G应用创新中心<br/>
                                            </p>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        ):
                        <Col span={6}>
                            <Card
                                hoverable
                                style={{marginTop:52,backgroundColor:'#017bce', width: '100%',height: '93%' }}
                            >
                                <Row gutter={16}>
                                    <Col span={24} style={{textAlign:'center'}}>
                                        <img src={map9B} alt={'map8'} style={{width:'115px',height:'133px',marginTop:10}} />
                                    </Col>
                                    <Col span={24} style={{textAlign:'center'}}>
                                        <p style={{
                                            marginTop:48,
                                            fontSize: 24,
                                            fontFamily: 'Microsoft YaHei',
                                            fontWeight: 400,
                                            color: '#FFFFFF'
                                        }}>
                                            综合能源
                                        </p>
                                        <p style={{
                                            marginTop:-14,
                                            fontSize: 12,
                                            fontFamily: 'Microsoft YaHei',
                                            fontWeight: 400,
                                            color: '#ffffff'
                                        }}>
                                            Integrated&nbsp;&nbsp;Energy
                                        </p>
                                    </Col>
                                    <Col span={12} offset={8}>
                                        <div style={{marginTop:12,width:90,height:2,backgroundColor:'#ffffff'}}/>
                                    </Col>
                                    <Col span={24}>
                                        <p style={{
                                            marginTop:41,
                                            marginBottom:22,
                                            fontSize: 19,
                                            fontFamily: 'Microsoft YaHei',
                                            fontWeight: 400,
                                            color: '#ffffff'
                                        }}>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;物联网创新中心<br/>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;能源感知创新中心<br/>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;能源信息高铁5G应用创新中心<br/>
                                        </p>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    }

                    {coreProduct3 == true?(
                            <Col span={6}>
                                <Card
                                    hoverable
                                    style={{marginTop:52,width: '100%',height: '93%' }}
                                    onClick={async () => openCoreProduct3(false)}
                                >
                                    <Row gutter={16}>
                                        <Col span={24} style={{textAlign:'center'}}>
                                            <img src={map10A} alt={'map9'} style={{width:'115px',height:'133px',marginTop:10}} />
                                        </Col>
                                        <Col span={24} style={{textAlign:'center'}}>
                                            <p style={{
                                                marginTop:48,
                                                fontSize: 24,
                                                fontFamily: 'Microsoft YaHei',
                                                fontWeight: 400,
                                            }}>
                                                节能提效
                                            </p>
                                            <p style={{
                                                marginTop:-14,
                                                fontSize: 12,
                                                fontFamily: 'Microsoft YaHei',
                                                fontWeight: 400
                                            }}>
                                                Energy&nbsp;&nbsp;Saving
                                            </p>
                                        </Col>
                                        <Col span={12} offset={8}>
                                            <div style={{marginTop:12,width:90,height:2,backgroundColor:'#017BCE'}}/>
                                        </Col>
                                        <Col span={24}>
                                            <p style={{
                                                marginTop:41,
                                                marginBottom:15,
                                                fontSize: 19,
                                                fontFamily: 'Microsoft YaHei',
                                                fontWeight: 400,
                                                color: '#999999'
                                            }}>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;技术协同创新中心<br/>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;建筑节能创新中心<br/>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;清洁供暖创新中心<br/>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;海水淡化创新中心<br/>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;农村环境治理创新中心<br/>
                                            </p>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        ):
                        <Col span={6}>
                            <Card
                                hoverable
                                style={{marginTop:52,backgroundColor:'#017bce', width: '100%',height: '93%' }}
                            >
                                <Row gutter={16}>
                                    <Col span={24} style={{textAlign:'center'}}>
                                        <img src={map10B} alt={'map8'} style={{width:'115px',height:'133px',marginTop:10}} />
                                    </Col>
                                    <Col span={24} style={{textAlign:'center'}}>
                                        <p style={{
                                            marginTop:48,
                                            fontSize: 24,
                                            fontFamily: 'Microsoft YaHei',
                                            fontWeight: 400,
                                            color: '#FFFFFF'
                                        }}>
                                            节能提效
                                        </p>
                                        <p style={{
                                            marginTop:-14,
                                            fontSize: 12,
                                            fontFamily: 'Microsoft YaHei',
                                            fontWeight: 400,
                                            color: '#ffffff'
                                        }}>
                                            Energy&nbsp;&nbsp;Services
                                        </p>
                                    </Col>
                                    <Col span={12} offset={8}>
                                        <div style={{marginTop:12,width:90,height:2,backgroundColor:'#ffffff'}}/>
                                    </Col>
                                    <Col span={24}>
                                        <p style={{
                                            marginTop:41,
                                            marginBottom:22,
                                            fontSize: 19,
                                            fontFamily: 'Microsoft YaHei',
                                            fontWeight: 400,
                                            color: '#ffffff'
                                        }}>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;技术协同创新中心<br/>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;建筑节能创新中心<br/>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;清洁供暖创新中心<br/>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;海水淡化创新中心<br/>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;农村环境治理创新中心<br/>
                                        </p>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    }

                    {coreProduct4 == true?(
                            <Col span={6}>
                                <Card
                                    hoverable
                                    style={{marginTop:52,width: '100%',height: '93%' }}
                                    onClick={async () => openCoreProduct4(false)}
                                >
                                    <Row gutter={16}>
                                        <Col span={24} style={{textAlign:'center'}}>
                                            <img src={map11A} alt={'map9'} style={{width:'115px',height:'133px',marginTop:10}} />
                                        </Col>
                                        <Col span={24} style={{textAlign:'center'}}>
                                            <p style={{
                                                marginTop:48,
                                                fontSize: 24,
                                                fontFamily: 'Microsoft YaHei',
                                                fontWeight: 400,
                                            }}>
                                                能源服务
                                            </p>
                                            <p style={{
                                                marginTop:-14,
                                                fontSize: 12,
                                                fontFamily: 'Microsoft YaHei',
                                                fontWeight: 400
                                            }}>
                                                Energy&nbsp;&nbsp;Services
                                            </p>
                                        </Col>
                                        <Col span={12} offset={8}>
                                            <div style={{marginTop:12,width:90,height:2,backgroundColor:'#017BCE'}}/>
                                        </Col>
                                        <Col span={24}>
                                            <p style={{
                                                marginTop:41,
                                                marginBottom:15,
                                                fontSize: 19,
                                                fontFamily: 'Microsoft YaHei',
                                                fontWeight: 400,
                                                color: '#999999'
                                            }}>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;技术协同创新中心<br/>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;建筑节能创新中心<br/>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;清洁供暖创新中心<br/>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;海水淡化创新中心<br/>
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;农村环境治理创新中心<br/>
                                            </p>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        ):
                        <Col span={6}>
                            <Card
                                hoverable
                                style={{marginTop:52,backgroundColor:'#017bce', width: '100%',height: '93%' }}
                            >
                                <Row gutter={16}>
                                    <Col span={24} style={{textAlign:'center'}}>
                                        <img src={map11B} alt={'map8'} style={{width:'115px',height:'133px',marginTop:10}} />
                                    </Col>
                                    <Col span={24} style={{textAlign:'center'}}>
                                        <p style={{
                                            marginTop:48,
                                            fontSize: 24,
                                            fontFamily: 'Microsoft YaHei',
                                            fontWeight: 400,
                                            color: '#FFFFFF'
                                        }}>
                                            能源服务
                                        </p>
                                        <p style={{
                                            marginTop:-14,
                                            fontSize: 12,
                                            fontFamily: 'Microsoft YaHei',
                                            fontWeight: 400,
                                            color: '#ffffff'
                                        }}>
                                            Energy&nbsp;&nbsp;Saving
                                        </p>
                                    </Col>
                                    <Col span={12} offset={8}>
                                        <div style={{marginTop:12,width:90,height:2,backgroundColor:'#ffffff'}}/>
                                    </Col>
                                    <Col span={24}>
                                        <p style={{
                                            marginTop:41,
                                            marginBottom:22,
                                            fontSize: 19,
                                            fontFamily: 'Microsoft YaHei',
                                            fontWeight: 400,
                                            color: '#ffffff'
                                        }}>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;技术协同创新中心<br/>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;建筑节能创新中心<br/>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;清洁供暖创新中心<br/>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;海水淡化创新中心<br/>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;农村环境治理创新中心<br/>
                                        </p>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    }

                </Row>
            </Col>

            <Col span={4} offset={10}>
                <div style={{
                    marginTop:115,
                    textAlign:'center',
                    background: 'linear-gradient(to left, #043add, #004B90) left bottom no-repeat,linear-gradient(to bottom, #004B90, #004B90) left bottom no-repeat,linear-gradient(to left, #004B90, #004B90) right top no-repeat,linear-gradient(to left, #004B90, #004B90) right top no-repeat',
                    backgroundSize: '0.25rem 2rem, 2rem 0.25rem'
                }} id='孵化器'>
                    <p className={'titles'}>
                        孵&nbsp;&nbsp;化&nbsp;&nbsp;器
                    </p>
                    <p className={'titles-English'}>
                        Incubators
                    </p>
                </div>
            </Col>
            <Col span={18} offset={3}>
                <Row gutter={[16, 8]}>
                    <Col span={24}>
                        <p style={{
                            marginTop:40,
                            fontSize: 21,
                            fontFamily: 'Microsoft YaHei',
                            fontWeight: 400,
                            color: '#333333',
                            textAlign:'center'
                        }}>
                            大学绿色低碳产业孵化器，面积 4 万平米，<br/>
                            1 至 3 层是学校的各类重点实验室，<br/>
                            4 至 8 层是对外合作的各类创新中心、孵化器及配套服务，<br/>
                            9 至 11 层是学校老师和大学生创业孵化器。
                        </p>
                    </Col>
                    <Col span={15} style={{marginTop:42}}>
                        <img src={map12} alt={'map12'} style={{width:'100%',height:'97.5%'}} />
                    </Col>
                    <Col span={9} style={{marginTop:42}}>
                        <Row gutter={[0, 16]}>
                            <Col span={24}>
                                <img src={map13} alt={'map13'} style={{width:'100%'}} />
                            </Col>
                            <Col span={24}>
                                <img src={map14} alt={'map14'} style={{width:'100%'}} />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={6}>
                        <img src={map15} alt={'map15'} style={{width:'100%'}} />
                    </Col>
                    <Col span={6}>
                        <img src={map16} alt={'map16'} style={{width:'100%'}} />
                    </Col>
                    <Col span={6}>
                        <img src={map17} alt={'map17'} style={{width:'100%'}} />
                    </Col>
                    <Col span={6}>
                        <img src={map18} alt={'map18'} style={{width:'100%'}} />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default Technology;
