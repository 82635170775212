import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import {Button, Divider, Dropdown, Menu, message} from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';
import CreateForm from './components/CreateForm';
import UpdateForm from './components/UpdateForm';
import api, {deleteNews, getNewss, selectProductServiceNews, setNews} from '../../services/api';
import moment from 'moment';
import CustomProTable from '../../components/CustomProTable';
import Ellipsis from '../../components/Ellipsis';
/**
 * 添加节点
 * @param fields
 */
const handleAdd = async fields => {
    const hide = message.loading('正在新建');
    try {
        await api.setProductServiceNews({fields});
        hide();
        message.success('新建成功');
        return true;
    } catch (error) {
        hide();
        message.error('新建失败请重试！');
        return false;
    }
};

/**
 * 更新节点
 * @param fields
 */
const handleUpdate = async fields => {
    const hide = message.loading('正在修改');
    try {
        await api.getProductServiceNewss({fields});
        hide();
        message.success('修改成功');
        return true;
    } catch (error) {
        hide();
        message.error('修改失败请重试！');
        return false;
    }
};

/**
 *  删除节点
 * @param selectedRows
 */
const handleRemove = async selectedRows => {
    const hide = message.loading('正在删除');
    if (!selectedRows) return true;

    try {
        await api.deleteProductServiceNews({
            id: selectedRows.map(row => row.id),
        });
        hide();
        message.success('删除成功，即将刷新');
        return true;
    } catch (error) {
        hide();
        message.error('删除失败，请重试');
        return false;
    }
};

const BackNews = () => {
    const [createModalVisible, handleModalVisible] = useState(false);
    const [updateModalVisible, handleUpdateModalVisible] = useState(false);
    const [stepFormValues, setStepFormValues] = useState({});
    const[total,setTotal] =  useState([]);
    const[current,setCurrent] =  useState([]);
    const[pageSize,setPageSize] =  useState([]);
    const [filelist, setFileList] = useState([]);
    const actionRef = useRef();
    const columns = [
        {
            key: 'title',
            title: '新闻标题',
            dataIndex: 'title',
            render: val =>  <Ellipsis length={20} tooltip>{val}</Ellipsis>,
        },
        {
            key: 'type',
            title: '类型',
            dataIndex: 'type',
            hideInSearch: true,
            render: (_, record) => (
                <>
                    <span>{record.type == "1"? "产品":"服务"}</span>
                </>
            ),
        },
        {
            key: 'describes',
            title: '新闻描述',
            dataIndex: 'describes',
            hideInSearch: true,
            render: val =>  <Ellipsis length={35} tooltip>{val}</Ellipsis>,
        },
        {
            key: 'createtime',
            title: '创建时间',
            dataIndex: 'createtime',
            hideInSearch: true,
            render: val => <span>{moment(val).format('YYYY-MM-DD HH:mm:ss')}</span>,
        },
        {
            key: 'updatetime',
            title: '更新时间',
            dataIndex: 'updatetime',
            hideInSearch: true,
            render: val => <span>{moment(val).format('YYYY-MM-DD HH:mm:ss')}</span>,
        },
        {
            title: '操作',
            valueType: 'option',
            render: (_, record) => (
                <>
                    {/*<a onClick={async () => {handleUpdateModalVisible(true);getLabelId(record);}}>修改</a>*/}
                    <a
                        onClick={async () => {
                            let res = [];
                            record.tfjXxs.forEach(item => {
                                res.push({
                                    uid: item.id,
                                    name: item.jmc,
                                    status: 'done',
                                    url: item.dz,
                                    xmc:item.xmc,
                                    wjdx:item.wjdx,
                                });
                            });

                            setFileList(res);
                            handleUpdateModalVisible(true);
                            setStepFormValues(record);
                        }}
                    >
                        修改
                    </a>
                </>
            ),
        },
    ];

    useEffect(async() => {

    }, []);

    return (
        <PageHeaderWrapper>
            <CustomProTable
                headerTitle={"查询列表（总共 "+total+" 条）"}
                actionRef={actionRef}
                search={true}
                options={true}
                rowKey="id"
                toolBarRender={(action, { selectedRows }) => [
                    <Button icon={<PlusOutlined />} type="primary" onClick={() => handleModalVisible(true)}>
                        新建
                    </Button>,
                    selectedRows && selectedRows.length > 0 && (
                        <Dropdown
                            overlay={
                                <Menu
                                    onClick={async e => {
                                        if (e.key === 'remove') {
                                            await handleRemove(selectedRows);
                                            action.reload();
                                        }
                                    }}
                                    selectedKeys={[]}
                                >
                                    <Menu.Item key="remove">批量删除</Menu.Item>
                                </Menu>
                            }
                        >
                            <Button>
                                批量操作 <DownOutlined />
                            </Button>
                        </Dropdown>
                    ),
                ]}
                request={async params => {
                    const res = await api.selectProductServiceNews(params);
                    setTotal(res.total);
                    setCurrent(res.current);
                    setPageSize(res.pageSize);
                    return res;
                }}
                rowSelection={{}}
                columns={columns}
            />
            <CreateForm
                onSubmit={async value => {
                    const success = await handleAdd(value);

                    if (success) {
                        handleModalVisible(false);

                        if (actionRef.current) {
                            actionRef.current.reload();
                        }
                    }
                }}
                onCancel={() => handleModalVisible(false)}
                modalVisible={createModalVisible}
            />
            {stepFormValues && Object.keys(stepFormValues).length ? (
                <UpdateForm
                    onSubmit={async value => {
                        const success = await handleUpdate(value);

                        if (success) {
                            handleModalVisible(false);
                            setStepFormValues({});

                            if (actionRef.current) {
                                actionRef.current.reload();
                            }
                        }
                    }}
                    onCancel={() => {
                        handleUpdateModalVisible(false);
                        setStepFormValues({});
                    }}
                    updateModalVisible={updateModalVisible}
                    values={stepFormValues}
                    files={filelist}
                />
            ) : null}
        </PageHeaderWrapper>
    );
};

export default BackNews;
