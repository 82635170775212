import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Dropdown, Menu, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { PageHeaderWrapper } from '@ant-design/pro-layout';
import CreateForm from './components/CreateForm';
import UpdateForm from './components/UpdateForm';
import ViewForm from './components/ViewForm';

import api from '../../services/DistributionTestStandard';
import moment from 'moment';
import CustomProTable from '../../components/CustomProTable';
import Ellipsis from '../../components/Ellipsis';

/**
 * 添加节点
 * @param fields
 */
const handleAdd = async fields => {
  const hide = message.loading('正在新建');
  try {
    await api.save({fields});
    hide();
    message.success('新建成功');
    return true;
  } catch (error) {
    hide();
    message.error('新建失败请重试！');
    return false;
  }
};

/**
 * 更新节点
 * @param fields
 */
const handleUpdate = async fields => {
  const hide = message.loading('正在修改');
  try {
    await api.update({fields});
    hide();
    message.success('修改成功');
    return true;
  } catch (error) {
    hide();
    message.error('修改失败请重试！');
    return false;
  }
};

/**
 *  删除节点
 * @param selectedRows
 */
const handleRemove = async selectedRows => {
  const hide = message.loading('正在删除');
  if (!selectedRows) return true;

  try {
    await api.del({
      ids: selectedRows.map(row => row.id),
    });

    hide();
    message.success('删除成功，即将刷新');
    return true;
  } catch (error) {
    hide();
    message.error('删除失败，请重试');
    return false;
  }
};

const TableList = () => {
  const [createModalVisible, handleModalVisible] = useState(false);
  const [updateModalVisible, handleUpdateModalVisible] = useState(false);
  const [viewStepFormValues, handViewStepFormValues] = useState(false);

  const [stepFormValues, setStepFormValues] = useState({});
  const [ViewStepFormValues, setViewStepFormValues] = useState({});

  const [sfdwFl, setSfdwFl] = useState([]);//收费单位分类

  const[total,setTotal] =  useState([]);
  const actionRef = useRef();
  const columns = [
    {
      key: 'mc',
      title: '名称',
      dataIndex: 'mc',
      render: val =>  <Ellipsis length={20} tooltip>{val}</Ellipsis>,
    },
    {
      key: 'dydj',
      title: '电压等级',
      dataIndex: 'dydj',
      hideInSearch: true,
    },
    {
      key: 'sfdwflmc',
      title: '单位分类',
      dataIndex: 'sfdwflmc',
      hideInSearch: true,
    },
    {
      key: 'dj',
      title: '单价',
      dataIndex: 'dj',
      hideInSearch: true,
    },
    {
      key: 'xgsj',
      title: '更新时间',
      dataIndex: 'xgsj',
      hideInSearch: true,
      render: val => <span>{moment(val).format('YYYY-MM-DD HH:mm:ss')}</span>,
    },
    {
      title: '操作',
      valueType: 'option',
      render: (_, record) => (
        <>
          <a onClick={async () => {
            setStepFormValues(record);
            handleUpdateModalVisible(true);
          }}>修改</a>
          <Divider type="vertical" />
          <a onClick={async () => {
            setViewStepFormValues(record);
            handViewStepFormValues(true);
          }}>查看</a>
        </>
      ),
    },
  ];

  useEffect(async() => {
   await  findAllSfdwFl();
  }, []);


  //实验周期分类表
  const  findAllSfdwFl  = async ()=>{
    let res = await api.findAllSfdwFl();
    setSfdwFl(res.data);
  };



  return (
    <PageHeaderWrapper>
      <CustomProTable
        headerTitle={"查询列表（总共 "+total+" 条）"}
        actionRef={actionRef}
        search={{labelWidth: 'auto',}}
        options={true}
        rowKey="id"
        toolBarRender={(action, { selectedRows }) => [
          <Button icon={<PlusOutlined />} type="primary" onClick={() => handleModalVisible(true)}>
            新建
          </Button>,
          selectedRows && selectedRows.length > 0 && (
            <Dropdown
              overlay={
                <Menu
                  onClick={async e => {
                    if (e.key === 'remove') {
                      await handleRemove(selectedRows);
                      action.reload();
                    }
                  }}
                  selectedKeys={[]}
                >
                  <Menu.Item key="remove">批量删除</Menu.Item>
                </Menu>
              }
            >
              <Button>
                批量操作 <DownOutlined />
              </Button>
            </Dropdown>
          ),
        ]}
        request={async params => {
          const res = await api.list(params);
          setTotal(res.total);
          return res;
        }}
        rowSelection={{}}
        columns={columns}
      />
      <CreateForm
        onSubmit={async value => {
          const success = await handleAdd(value);

          if (success) {
            handleModalVisible(false);

            if (actionRef.current) {
              actionRef.current.reload();
            }
          }
        }}
        onCancel={() => handleModalVisible(false)}
        modalVisible={createModalVisible}
        sfdwFlXx={sfdwFl}
      />
      {stepFormValues && Object.keys(stepFormValues).length ? (
        <UpdateForm
          onSubmit={async value => {
            const success = await handleUpdate(value);

            if (success) {
              handleModalVisible(false);
              setStepFormValues({});

              if (actionRef.current) {
                actionRef.current.reload();
              }
            }
          }}
          onCancel={() => {
            handleUpdateModalVisible(false);
            setStepFormValues({});
          }}
          updateModalVisible={updateModalVisible}
          values={stepFormValues}
          sfdwFlXx={sfdwFl}

        />
      ) : null}
      {ViewStepFormValues && Object.keys(ViewStepFormValues).length ? (
        <ViewForm
          onSubmit={async value => {
            const success = await handleUpdate(value);

            if (success) {
              handleModalVisible(false);
              setStepFormValues({});

              if (actionRef.current) {
                actionRef.current.reload();
              }
            }
          }}
          onCancel={() => {
            handViewStepFormValues(false);
            setStepFormValues({});
          }}
          updateModalVisible={viewStepFormValues}
          values={ViewStepFormValues}
          sfdwFlXx={sfdwFl}

        />
      ) : null}
    </PageHeaderWrapper>
  );
};

export default TableList;
