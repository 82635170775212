import React, { useEffect, useState } from 'react';
import { Col, Form, Input, Modal, Row, Select, } from 'antd';
import Modify from "./CreateModify";
import UploadFile from './UploadFile';
import 'moment/locale/zh-cn';

const FormItem = Form.Item;
const {TextArea} = Input;
const { Option } = Select;

const CreateForm = props => {
    const [form] = Form.useForm();
    const {modalVisible, onSubmit: handleAdd, onCancel} = props;
    const [fwbxx, setFwbxx] = useState();

    const [fileList, setFileList] = useState([]);

    useEffect(async() => {

    }, []);


    const getFwb = async (val) => {
        setFwbxx(val);
    };

    const okHandle = async () => {
        const fieldsValue = await form.validateFields();
        form.resetFields();
        let tFjXxs = [];
        let urls = "";
        fileList.forEach(item => {
            tFjXxs.push({
                dz:item.response.url,
                wjdx:item.response.size,
                jmc:item.response.name,
                xmc:item.response.id,
                bz:"",
            })
            urls = urls + item.response.url;
        });
        handleAdd({...fieldsValue ,url:urls,content:fwbxx, tFjXxs : tFjXxs, yxbj:'1'});
    };


    const validateMessages = {
        required: '${label} 这是必须的填写的!',
        types: {
            email: '${label} 这不是一个有效邮箱!',
            number: '${label} 这不是一个有效数字!',
        },
        number: {
            range: '${label} 必须是 ${min}cm 和 ${max}cm之间',
        },
    };

    const getFileList = async (val) => {
        setFileList(val.fileList);
    };

    return (
        <>
            <Modal
                destroyOnClose
                title="新建能源新闻"
                visible={modalVisible}
                onOk={okHandle}
                onCancel={() => onCancel()}
                width={"60%"}
                okText="确认"
                cancelText="取消"
            >
                <Form form={form} validateMessages={validateMessages} preserve={false} initialValues={{ bz:"", }}>
                    <Row  gutter={16}>
                        <Col  span={12}>
                            <FormItem
                                label="新闻标题"
                                name="title"
                                rules={[{ required: true, },]}
                            >
                                <Input placeholder="请输入"/>
                            </FormItem>
                        </Col>
                        <Col  span={12}>
                            <FormItem
                                label="类型"
                                name="type"
                                rules={[{ required: true, },]}
                                defaultValue="1"
                            >

                                <Select
                                    allowClear
                                    value={"1"}
                                >
                                    <Option value="1">产品</Option>
                                    <Option value="2">服务</Option>
                                </Select>
                            </FormItem>
                        </Col>
                        <Col  span={12}>
                            <FormItem
                                label="编辑人"
                                name="editor"
                                rules={[{ required: true, },]}
                            >
                                <Input placeholder="请输入"/>
                            </FormItem>
                        </Col>
                        <Col  span={12}>
                            <FormItem
                                label="审核人"
                                name="auditor"
                                rules={[{ required: true, },]}
                            >
                                <Input placeholder="请输入"/>
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem
                                label="封面图片"
                            >
                                <UploadFile getFileList={getFileList}/>
                            </FormItem>
                        </Col>
                        <Col  span={24}>
                            <FormItem
                                label="新闻描述"
                                name="describes"
                            >
                                <TextArea style={{ height: 100 }} placeholder="请输入"/>
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <Form.Item label='新闻内容' >
                                <Modify getFwb={getFwb}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
    );
};

export default CreateForm;
